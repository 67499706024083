import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCRUDApi } from '../../../hooks/useCRUDApi';
import { PerceptionModel, TPerceptionModel } from '../../../pages/perception/utils/PerceptionModel';
import { PerceptionTypeListItem } from '../../../pages/perception/utils/types';
import { CommentModel, mapComments } from '../../../shared/CommentModel';
import { useStateObject } from '../../../hooks/useStateObject';
import { SourceModel } from '../../../pages/source/utils/sourceModel';

export const useEditPerceptionState = () => {
    const [editPerceptionModalOpen, setEditPerceptionModalOpen] = useState(false);
    const [selectedPerception, setSelectedPerception] = useState<PerceptionModel>();

    // Handlers
    const handlers = useMemo(
        () => ({
            handleOpenEditPerception: (row: PerceptionModel) => {
                setEditPerceptionModalOpen(true);
                setSelectedPerception(row);
            },
            handleCloseEditPerception: () => setEditPerceptionModalOpen(false),
        }),
        [setEditPerceptionModalOpen]
    );

    return {
        editPerceptionModalOpen,
        selectedPerception,
        setEditPerceptionModalOpen,
        ...handlers,
    };
};

export const usePerception = (perception: PerceptionModel = new PerceptionModel({})) => {
    const [comment, setComment] = useState('');
    const [nameInvalid, setNameInvalid] = useState(false);
    const [descInvalid, setDescInvalid] = useState<boolean>(false);
    const [sourceInvalid, setSourceInvalid] = useState(false);
    const [previousComments, setPreviousComments] = useState<CommentModel[]>([]);
    const { callApi, error, loading } = useCRUDApi('/api/v1');
    const [selectedPerception, setSelectedPerception] = useStateObject<TPerceptionModel>({ ...perception, source: '' });
    const lastPerceptionId = useRef('');

    const fetchPreviousComments = useCallback(
        async (id: string) => {
            const resp = await callApi('/comments/targets/' + id, 'get');
            const comments = mapComments(resp.comments);
            setPreviousComments(comments);
            lastPerceptionId.current = id;
            return comments;
        },
        [callApi, setPreviousComments]
    );

    useEffect(() => {
        if (selectedPerception.id !== lastPerceptionId.current) {
            fetchPreviousComments(selectedPerception.id);
        }
    }, [selectedPerception, fetchPreviousComments]);

    const handlers = useMemo(
        () => ({
            updateName: (e: React.FormEvent<HTMLInputElement>) => {
                setNameInvalid(false);
                setSelectedPerception({
                    name: e.currentTarget.value,
                });
            },
            updateDesc: (e: React.FormEvent<HTMLInputElement>) => {
                setDescInvalid(false);
                setSelectedPerception({
                    description: e.currentTarget.value,
                });
            },
            updateType: (e: { selectedItem: PerceptionTypeListItem }) => {
                setSelectedPerception({
                    type: e.selectedItem.id,
                });
            },
            updateSource: (e: { selectedItem: SourceModel }) => {
                if (e.selectedItem) {
                    setSourceInvalid(false);
                }
                setSelectedPerception({
                    source: e.selectedItem?.name ?? '',
                });
            },
            updateComment: (e: React.FormEvent<HTMLInputElement>) => {
                setComment(e.currentTarget.value);
            },
        }),
        [setSelectedPerception]
    );

    return {
        callApi,
        comment,
        error,
        handlers,
        loading,
        nameInvalid,
        descInvalid,
        sourceInvalid,
        previousComments,
        selectedPerception,
        setNameInvalid,
        setDescInvalid,
        setSourceInvalid,
    } as const;
};
