export const enum Mode {
    Perception = 'perception',
    CalcScenario = 'calcScenario',
    Calc = 'calc',
}

export interface SelectionDialogProps {
    open: boolean;
    onClose: (arg: boolean) => void;
    mode: Mode;
    data: Record<string, any>[];
    onSubmit?: ((arg: string) => void) | null;
}
