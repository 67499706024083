import { Modal, TextArea, TextInput, ComboBox, Button } from '@carbon/react';

import { CalculusInputsHeader } from './CalculusInputsHeader';
import { Behavior, CalculusData, CalculusInputsProps, Entity, Objective } from './types';
import { useCalculusInputsState } from './utils/useCalculusInputsState';
import { useCarbonModalOpened, useCounter, useToggle } from '../../hooks';
import { SelectCalcWrapper } from '../selectionDialog/SelectCalcWrapper';
import { Scenario } from '../../pages/scenario/utils/types';
import { useEntities } from '../entities/EntitiesHooks';
import { useObjectives } from '../objectives/ObjectivesHooks';
import { useBehaviors } from '../behaviors/BehaviorHooks';
import { useScenarios } from '../../pages/scenario/utils/useFetchScenarios';
import { useAtom } from 'jotai';

import './CalculusInputs.scss';
import { GatewayUserAccess, LicenseGlobal, WarningHex, Wikis } from '@carbon/icons-react';
import { AdminModal } from '../admin/AdminModal';
import { useAdminModalState } from '../admin/utils/useAdminModalState';
import { ScenarioModal } from '../Scenario/ScenarioModal';
import { useCRUDApi } from '../../hooks/useCRUDApi';
import { calculiAtom } from '../../pages/calculus/DecisionCalculus/utils/useDecicisionCalculusHooks';
import { mapCalculi } from '../calculusInputs/utils/CalculusModel';

export const CalculusInputs = ({ calculus, headerText, open, prefillButton, submitText, onClose, onSubmit, editSummaryOnly = false }: CalculusInputsProps) => {
    const {
        selectedCalculus,
        scenarioModalOpen,
        handleCalculusNameChange,
        handleCalculusSummaryChange,
        handleEntityChange,
        handleObjectiveChange,
        handleScenarioChange,
        handleBehaviorChange,
        handleCreateNewScenario,
        setSelectedCalculus,
        findInvalidInputs,
        setCalculusToEdit,
        setScenarioModalOpen,
        setPrefillCalculus,
        invalidInputs,
    } = useCalculusInputsState({});

    const { entities, loading: entitiesLoading } = useEntities();
    const { objectives, loading: objectivesLoading, createObjective } = useObjectives();
    const { behaviors, loading: behaviorsLoading, createBehavior } = useBehaviors();
    const { scenarios, loading: scenariosLoading } = useScenarios();
    const [, setCalculi] = useAtom(calculiAtom);
    const { callApi } = useCRUDApi('/api/v1');

    const { adminModalOpen, adminModalType, openAdminModal, setAdminModalOpen } = useAdminModalState();

    // Needed to make sure textarea re renders character count on insert
    const { count, increment } = useCounter(0);

    const [openPrefill, toggleOpenPrefill] = useToggle(false);

    const mapCalculusDataToState = (calculusData: CalculusData) => {
        return {
            id: calculusData.id,
            name: calculusData.name,
            summary: calculusData.summary,
            createdBy: calculusData.createdBy,
            entity: entities.find((entity) => entity.name === calculusData.entity) || null,
            objective: objectives.find((obj) => obj.name === calculusData.objective) || null,
            behavior: behaviors.find((behavior) => behavior.name === calculusData.behavior) || null,
            scenario: scenarios.find((scenario) => scenario.name === calculusData.scenario) || null,
        } as const;
    };

    useCarbonModalOpened(open && !entitiesLoading && !objectivesLoading && !behaviorsLoading && !scenariosLoading, () => {
        if (calculus) {
            setCalculusToEdit(calculus.name);
            setSelectedCalculus(mapCalculusDataToState(calculus));
            increment();
        } else {
            setCalculusToEdit('');
            setPrefillCalculus('');
        }
    });

    const { name, summary, entity, objective, scenario, behavior, isValid } = selectedCalculus;

    const handleSubmit = async () => {
        if (isValid && behavior && objective && scenario) {
            const calculusPrefix = selectedCalculus.id.slice(0, 5);

            const behaviorPromise =
                behavior.name !== calculus?.behavior
                    ? createBehavior({
                          description: behavior.description ?? '',
                          name: `${calculusPrefix} - ${behavior.name}`,
                      })
                    : { behaviorId: behavior.id };
            const objectivePromise =
                objective.name !== calculus?.objective
                    ? createObjective({
                          description: objective.description ?? '',
                          name: `${calculusPrefix} - ${objective.name}`,
                      })
                    : { objectiveId: objective.id };
            const [newBehavior, newObjective] = await Promise.all([behaviorPromise, objectivePromise]);
            const calculusApiBody = {
                name: selectedCalculus.name,
                summary: selectedCalculus.summary,
                createdBy: selectedCalculus.createdBy,
                entity: entity?.id ?? '',
                objective: newObjective.objectiveId ?? '',
                scenario: scenario.id ?? '',
                behavior: newBehavior.behaviorId ?? '',
            };
            onSubmit?.(calculusApiBody);
            onClose();
        } else {
            findInvalidInputs();
        }
    };

    const onDelete = async () => {
        const resp = await callApi('/calculi', 'get');
        const calculiRows = mapCalculi(resp.rows);
        if (calculiRows) {
            setCalculi(calculiRows);
        }
    };

    if (openPrefill) {
        return (
            <SelectCalcWrapper
                open={openPrefill}
                onClose={toggleOpenPrefill}
                onSubmit={(calc) => {
                    if (calc) {
                        setSelectedCalculus(mapCalculusDataToState(calc));
                        setPrefillCalculus(calc.id);
                    }
                    toggleOpenPrefill(false);
                }}
            />
        );
    }

    if (adminModalOpen) {
        return <AdminModal open={adminModalOpen} modalType={adminModalType} setOpen={setAdminModalOpen} onDelete={onDelete} />;
    }
    if (scenarioModalOpen) {
        return <ScenarioModal open={scenarioModalOpen} onSubmit={handleCreateNewScenario} onClose={() => setScenarioModalOpen(false)} />;
    }

    if (editSummaryOnly) {
        return (
            <Modal
                size="lg"
                open={open}
                onRequestClose={() => {
                    onClose();
                }}
                onRequestSubmit={handleSubmit}
                modalHeading={<CalculusInputsHeader headerText={headerText + ' Summary'} prefillButton={prefillButton} handlePrefill={toggleOpenPrefill} />}
                primaryButtonText={submitText}
                secondaryButtonText="Cancel"
            >
                <div className="container pb-5">
                    <div className="row py-3">
                        <div className="col-12">
                            <TextArea
                                id="calulus-summary"
                                key={`calulus-summary-${count}`}
                                className="calculus-summary"
                                labelText="Calculus Summary"
                                maxCount={1000}
                                enableCounter
                                value={summary ?? ''}
                                onChange={handleCalculusSummaryChange}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    return (
        <div className="calculus-inputs">
            <Modal
                size="lg"
                open={open}
                onRequestClose={() => {
                    onClose();
                }}
                onRequestSubmit={handleSubmit}
                modalHeading={<CalculusInputsHeader headerText={headerText} prefillButton={prefillButton} handlePrefill={toggleOpenPrefill} />}
                primaryButtonText={submitText}
                secondaryButtonText="Cancel"
            >
                <div className="container pb-5">
                    <div className="row py-3">
                        <div className="col-12">
                            <TextInput
                                id="calculus-name"
                                labelText="Decision Calculus Name"
                                value={name ?? ''}
                                onChange={handleCalculusNameChange}
                                invalid={invalidInputs.name}
                                invalidText={name === '' ? 'Enter a calculus name.' : 'Calculus already exists, enter a different name.'}
                            />
                        </div>
                    </div>
                    <div className="row py-3">
                        <div className="col-12">
                            <TextArea
                                id="calulus-summary"
                                key={`calulus-summary-${count}`}
                                className="calculus-summary"
                                labelText="Calculus Summary"
                                maxCount={1000}
                                enableCounter
                                value={summary ?? ''}
                                onChange={handleCalculusSummaryChange}
                            />
                        </div>
                    </div>
                    <div className="row py-3">
                        <div className="col-4">
                            <ComboBox
                                id="entity"
                                titleText={
                                    <div className="d-flex align-items-center">
                                        <p>Entity</p>
                                        <Button
                                            kind="ghost"
                                            size="sm"
                                            hasIconOnly
                                            renderIcon={WarningHex}
                                            iconDescription={'Manage Entities'}
                                            onClick={() => openAdminModal('entity')}
                                        />
                                    </div>
                                }
                                label="Entity Options"
                                selectedItem={entity}
                                items={entities}
                                itemToString={(item: Entity) => (item ? item.name : '')}
                                onChange={handleEntityChange}
                                invalidText="Entity is required"
                                invalid={invalidInputs.entity}
                            />
                        </div>
                        <div className="col-4">
                            <ComboBox
                                id="objective"
                                titleText={
                                    <div className="d-flex align-items-center">
                                        <p>Objective</p>
                                        <Button
                                            kind="ghost"
                                            size="sm"
                                            hasIconOnly
                                            renderIcon={LicenseGlobal}
                                            iconDescription={'Manage Objectives'}
                                            onClick={() => openAdminModal('objective')}
                                        />
                                    </div>
                                }
                                label="Objective Options"
                                selectedItem={objective}
                                items={objectives}
                                itemToString={(item: Objective) => (item ? item.name : '')}
                                onChange={handleObjectiveChange}
                                invalidText="Objective is required"
                                invalid={invalidInputs.objective}
                            />
                        </div>
                    </div>
                    <div className="row py-3">
                        <div className="col-4">
                            <ComboBox
                                id="scenario"
                                titleText={
                                    <div className="d-flex align-items-center">
                                        <p>Scenario</p>
                                        <Button
                                            kind="ghost"
                                            size="sm"
                                            hasIconOnly
                                            renderIcon={Wikis}
                                            iconDescription={'Create Scenario'}
                                            onClick={() => setScenarioModalOpen(true)}
                                        />
                                    </div>
                                }
                                label="Scenario Options"
                                direction="top"
                                selectedItem={scenario}
                                items={scenarios}
                                itemToString={(item: Scenario) => (item ? item.name : '')}
                                onChange={handleScenarioChange}
                                invalidText="Scenario is required"
                                invalid={invalidInputs.scenario}
                            />
                        </div>
                        <div className="col-4">
                            <ComboBox
                                id="behavior"
                                titleText={
                                    <div className="d-flex align-items-center">
                                        <p>Behavior</p>
                                        <Button
                                            kind="ghost"
                                            size="sm"
                                            hasIconOnly
                                            renderIcon={GatewayUserAccess}
                                            iconDescription={'Manage Behaviors'}
                                            onClick={() => openAdminModal('behavior')}
                                        />
                                    </div>
                                }
                                label="Behavior Options"
                                direction="top"
                                selectedItem={behavior}
                                items={behaviors}
                                itemToString={(item: Behavior) => (item ? item.name : '')}
                                onChange={handleBehaviorChange}
                                invalidText="Behavior is required"
                                invalid={invalidInputs.behavior}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

// const handleSubmit = async () => {
//     if (selectedCalculus.isValid && selectedCalculus.behavior && selectedCalculus.objective && selectedCalculus.scenario) {
//         const calculusSuffix = selectedCalculus.name.slice(0, 10);
//         const objectiveId = selectedCalculus.objective.id ?? '';
//         const behaviorId = selectedCalculus.behavior.id ?? '';
//         const scenarioId = selectedCalculus.scenario.id ?? '';

//         const scenarioPromise =
//             scenarioId !== calculus?.scenarioId
//                 ? createNewScenario({
//                       ...selectedCalculus.scenario,
//                       name: `${selectedCalculus.scenario.name} - ${calculusSuffix}`,
//                   })
//                 : { scenarioId };
//         const behaviorPromise =
//             behaviorId !== calculus?.behaviorId
//                 ? createBehavior({
//                       description: selectedCalculus.behavior.description ?? '',
//                       name: `${selectedCalculus.behavior.name} - ${calculusSuffix}`,
//                   })
//                 : { behaviorId };
//         const objectivePromise =
//             objectiveId !== calculus?.objectiveId
//                 ? createObjective({
//                       description: selectedCalculus.objective.description ?? '',
//                       name: `${selectedCalculus.objective.name} - ${calculusSuffix}`,
//                   })
//                 : { objectiveId };
//         const [newScenario, newBehavior, newObjective] = await Promise.all([scenarioPromise, behaviorPromise, objectivePromise]);
//         const calculusApiBody = {
//             // id: selectedCalculus.id,
//             name: selectedCalculus.name,
//             summary: selectedCalculus.summary,
//             createdBy: selectedCalculus.createdBy,
//             entity: selectedCalculus.entity?.id ?? '',
//             objective: newObjective.objectiveId ?? '',
//             scenario: newScenario.scenarioId ?? '',
//             behavior: newBehavior.behaviorId ?? '',
//         };
//         onSubmit?.(calculusApiBody);
//         onClose();
//     } else {
//         findInvalidInputs();
//     }
// };
