import { PageList } from '../../../components/pageList';
import './DecisionCalculus.scss';
import { Button, Modal } from '@carbon/react';
import { DocumentDownload, Report } from '@carbon/icons-react';
import { useDecisionCalculus } from './utils/useDecicisionCalculusHooks';
import { PageListViewLink } from '../../../components/pageList/PageList';
import { useNavigate } from 'react-router';
import { Header, IBreadcrumItem } from '../../../components/Header/Header';
import { CalculusInputs } from '../../../modals/calculusInputs/CalculusInputs';
import { useDecisionCalculusState } from './utils/useDecicisionCalculusState';
import { CalculusData, CalculusInputData } from '../../../modals/calculusInputs/types';
import { CompareDecisionCalculi } from '../../../modals/compare/CompareDecisionCalculi';
import { exportUtility } from '../../../utils/export';
import { compareNames } from '../../../helpers/dataTableSort';
import { useCalculusElementApi } from '../../../modals/edit/EditCalculusElement/utils/editCalculusElementHooks';
import { mapPerceptionData } from '../CalculusDecisionDetails/utils/mapPerceptionData';

const TABLE_HEADERS = [
    { header: 'Calculus Name', key: 'name' },
    { header: 'Scenario', key: 'scenario' },
    { header: 'Entity', key: 'entity' },
    { header: 'Objective', key: 'objective' },
    { header: 'Last Modified', key: 'updated' },
    { header: 'Prepared by', key: 'createdBy' },
];

const VIEW_LINK: PageListViewLink = {
    baseUrl: '/calculi',
    columns: ['name'],
};

const BREADCRUMBS: IBreadcrumItem[] = [
    {
        label: 'Decision Caculus',
    },
];

const EXPORT_COLUMNS = [
    { field: 'id', title: 'Id' },
    { field: 'name', title: 'Name' },
    { field: 'summary', title: 'Summary' },
    { field: 'scenario', title: 'Scenario' },
    { field: 'objective', title: 'Objective' },
    { field: 'createdBy', title: 'Created By' },
    // { field: 'created', title: 'Created' },
    { field: 'updated', title: 'Updated' },
    { field: 'entity', title: 'Entity' },
];

type DataTableRow = { id: string; isSelected: boolean; cells: any[]; [key: string]: any };

export const DecisionCalculus = () => {
    const navigate = useNavigate();
    const { loading, calculi, prefillCalculus, createNewCalculus, updateCalculus, handlePostDeleteRows, fetchCalculiDetails } = useDecisionCalculus();
    const {
        newCalculusInputsOpen,
        editCalculusInputsOpen,
        compareCalculiOpen,
        calculusToEdit,
        calculiSelections,
        rowsToDelete,
        setNewCalculusInputsOpen,
        setEditCalculusInputsOpen,
        setCompareCalculiOpen,
        setCalculusToEdit,
        setCalculiSelections,
        handleSelectRowsToDelete,
        handleCancelDeleteRows,
    } = useDecisionCalculusState();
    const { createPerceptionConnection } = useCalculusElementApi();

    const onEdit = (id: string) => {
        setEditCalculusInputsOpen(true);
        setCalculusToEdit(id);
        setCompareCalculiOpen(false);
    };

    const onView = (id: string) => {
        navigate(`/calculi/${id}`);
    };

    const onMainAction = () => {
        setNewCalculusInputsOpen(true);
    };

    const handleCompareCalculi = (selections: DataTableRow[]) => {
        setCompareCalculiOpen(true);
        setCalculiSelections(selections.map((selection) => selection.id));
    };

    const handleCreateNewCalculus = (calculus: CalculusInputData) => {
        createNewCalculus(calculus).then((res) => handlePerceptionConnection(res));
        setNewCalculusInputsOpen(false);
    };

    const handleUpdateCalculus = (calculus: CalculusInputData) => {
        updateCalculus(calculusToEdit, calculus);
        setEditCalculusInputsOpen(false);
    };

    const onExport = () => {
        exportUtility({ data: calculi, fileName: 'decision-calculi', keys: EXPORT_COLUMNS });
    };

    const handlePerceptionConnection = async (newCalculusId: string) => {
        if (prefillCalculus === '') {
            return;
        }
        const data = await fetchCalculiDetails(prefillCalculus);
        const { perceptionConnections } = mapPerceptionData(data.perceptions);
        Object.keys(perceptionConnections).forEach((perception) => {
            createPerceptionConnection(newCalculusId, perception);
        });
    };

    return (
        <div className="decision-calculus">
            <Header title="Decision Calculi List" breadcrumbs={BREADCRUMBS}>
                <Button kind="tertiary" size="md" renderIcon={DocumentDownload} disabled={calculi.length === 0} onClick={onExport}>
                    Export Data
                </Button>
            </Header>
            <div className="p-4">
                <PageList
                    viewIcon={<Report />}
                    onEdit={onEdit}
                    onView={onView}
                    handleCompareCalculi={handleCompareCalculi}
                    onDeleteRows={handleSelectRowsToDelete}
                    viewLink={VIEW_LINK}
                    loading={loading}
                    rows={calculi}
                    headers={TABLE_HEADERS}
                    mainActionLabel="New Calculus"
                    onMainAction={onMainAction}
                    filter={{
                        supported: true,
                        filterBy: [
                            { key: 'entity', name: 'Entity' },
                            { key: 'createdBy', name: 'Prepared By' },
                            { key: 'scenario', name: 'Scenario' },
                        ],
                    }}
                    customCompare={{ createdBy: compareNames }}
                />
                {newCalculusInputsOpen && (
                    <CalculusInputs
                        open={newCalculusInputsOpen}
                        headerText={'New Calculus'}
                        submitText={'Create Calculus'}
                        prefillButton
                        onClose={() => setNewCalculusInputsOpen(false)}
                        onSubmit={handleCreateNewCalculus}
                    />
                )}
                {editCalculusInputsOpen && (
                    <CalculusInputs
                        calculus={calculi.find((calculus: CalculusData) => calculus.id === calculusToEdit)}
                        open={editCalculusInputsOpen}
                        headerText={'Edit Calculus'}
                        submitText={'Update Calculus'}
                        onClose={() => setEditCalculusInputsOpen(false)}
                        onSubmit={handleUpdateCalculus}
                    />
                )}
                {compareCalculiOpen && (
                    <CompareDecisionCalculi
                        open={compareCalculiOpen}
                        onClose={() => setCompareCalculiOpen(false)}
                        calculiSelections={calculiSelections}
                        onEdit={onEdit}
                    />
                )}
                {rowsToDelete.length > 0 ? (
                    <Modal
                        open
                        danger
                        modalHeading={`Are you sure you want to delete ${rowsToDelete.length} row${rowsToDelete.length > 1 ? 's' : ''}?`}
                        modalLabel={`Delete Calul${rowsToDelete.length > 1 ? 'i' : 'us'}`}
                        primaryButtonText="Delete"
                        secondaryButtonText="Cancel"
                        onRequestClose={handleCancelDeleteRows}
                        onRequestSubmit={() => {
                            console.log('deleting rows');
                            handlePostDeleteRows(rowsToDelete);
                            handleCancelDeleteRows();
                        }}
                    />
                ) : null}
            </div>
        </div>
    );
};
