import { useCallback, useEffect, useState } from 'react';
import { useCRUDApi } from '../../../hooks/useCRUDApi';
import { mapPerceptions, PerceptionModel } from './PerceptionModel';

export const usePerceptionData = () => {
    const { callApi, loading, error } = useCRUDApi('/api/v1');
    const [perceptions, setPerceptions] = useState<PerceptionModel[]>([]);

    const fetchPerceptions = useCallback(async () => {
        const resp = await callApi('/perceptions', 'get');
        const data = mapPerceptions(resp.rows);
        setPerceptions(data);
        return data;
    }, [callApi]);

    useEffect(() => {
        fetchPerceptions();
    }, [fetchPerceptions]);

    return {
        loading,
        perceptions,
        fetchPerceptions,
        callApi,
        error,
    } as const;
};
