import { useCallback, useEffect, useState } from 'react';
import { atom, useAtom, useSetAtom } from 'jotai';

import { useCRUDApi } from '../../../../hooks/useCRUDApi';
import { CalculusData, CalculusInputData } from '../../../../modals/calculusInputs/types';
import { mapCalculi } from '../../../../modals/calculusInputs/utils/CalculusModel';

export const calculiDataAtom = atom<CalculusData[]>([]);
export const calculiAtom = atom<CalculusData[]>([]);
export const prefillCalculusAtom = atom<string>('');

export const useDecisionCalculus = () => {
    const { callApi, loading } = useCRUDApi('/api/v1');
    const [calculi, setCalculi] = useAtom(calculiAtom);
    const [prefillCalculus, setPrefillCalculus] = useAtom(prefillCalculusAtom);
    const setCalculiData = useSetAtom(calculiDataAtom);

    const fetchCalculi = useCallback(async () => {
        const data = await callApi('/calculi', 'get');

        const calculiRows = mapCalculi(data.rows);
        if (calculiRows) {
            setCalculi(calculiRows);
            setCalculiData(calculiRows);
        }
    }, [callApi, setCalculi, setCalculiData]);

    useEffect(() => {
        fetchCalculi();
    }, [fetchCalculi]);

    const fetchCalculiDetails = async (id: string) => {
        if (id) {
            const data = await callApi(`/calculi/${id}`, 'get');
            return data;
        }
    };

    const createNewCalculus = async (input: CalculusInputData) => {
        const { name, summary, objective, behavior, entity, scenario } = input;
        try {
            const res = await callApi('/calculi', 'post', {
                name: name,
                summary: summary,
                objective_id: objective,
                behavior_id: behavior,
                scenario_id: scenario,
                entity_id: entity,
            });
            if (res) {
                fetchCalculi();
                return res.calculus_id;
            }
        } catch (error) {
            console.error(error);
        }
    };

    const updateCalculus = async (id: string, input: CalculusInputData) => {
        const { name, summary, objective, behavior, entity, scenario } = input;

        try {
            const res = await callApi(`/calculi/${id}`, 'put', {
                name,
                summary,
                objective_id: objective,
                behavior_id: behavior,
                scenario_id: scenario,
                entity_id: entity,
            });
            if (res) {
                fetchCalculi();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const deleteCalculus = useCallback(
        async (id: string) => {
            try {
                const res = await callApi(`/calculi/${id}`, 'delete');
                if (res) {
                    fetchCalculi();
                }
            } catch (error) {
                console.error(error);
            }
        },
        [callApi, fetchCalculi]
    );

    const handlers = {
        handlePostDeleteRows(rows: { id: string }[]) {
            const normalizedData: { [id: string]: CalculusData } = calculi.reduce(
                (obj, row) => ({
                    ...obj,
                    [row.id]: row,
                }),
                {}
            );
            rows.forEach((row) => {
                deleteCalculus(row.id);
            });
            setCalculi(Object.values(normalizedData));
        },
    };

    return {
        loading,
        calculi,
        prefillCalculus,
        setCalculi,
        createNewCalculus,
        updateCalculus,
        fetchCalculi,
        fetchCalculiDetails,
        setPrefillCalculus,
        ...handlers,
    } as const;
};
