import { atom, useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useCRUDApi } from '../../hooks/useCRUDApi';
import { mapObjectives, ObjectiveModel } from './ObjectiveModel';

export const loadingObjectivesAtom = atom(false);
export const objectivesDataAtom = atom<ObjectiveModel[]>([]);

export const useObjectives = () => {
    const { error, loading, callApi } = useCRUDApi('/api/v1');
    const [objectives, setObjectives] = useAtom(objectivesDataAtom);
    const [selectedObjective, setSelectedObjective] = useState<ObjectiveModel>();

    const createObjective = useCallback(
        async (newObjective: Partial<ObjectiveModel>) => {
            try {
                const resp = await callApi('/objectives', 'post', newObjective);
                if (resp && resp.objective_id) {
                    return {
                        isOk: true,
                        error: null,
                        objectiveId: resp.objective_id as string,
                    } as const;
                }
                throw new Error('Could not create objective');
            } catch (error) {
                return {
                    isOk: false,
                    objectiveId: null,
                    error,
                } as const;
            }
        },
        [callApi]
    );

    const fetchObjectives = useCallback(async () => {
        const resp = await callApi('/objectives', 'get');
        const data = mapObjectives(resp.rows);
        setObjectives(data);
        return data;
    }, [callApi, setObjectives]);

    useEffect(() => {
        fetchObjectives();
    }, [fetchObjectives]);

    return {
        selectedObjective,
        createObjective,
        objectives,
        error,
        loading,
        fetchObjectives,
        setSelectedObjective,
        setObjectives,
        callApi,
    };
};
