import { useState } from 'react';
import { useAtom } from 'jotai';
import { InlineNotification, ComposedModal, ModalBody, ModalHeader, Modal } from '@carbon/react';

import BehaviorsModal, { deleteBehaviorIdAtom } from '../behaviors/BehaviorsModal';
import EntitiesModal, { deleteEntityIdAtom } from '../entities/EntitiesModal';
import ObjectivesModal, { deleteObjectiveIdAtom } from '../objectives/ObjectivesModal';
import { AdminModalProps, DELETE_MODAL_CONFIG, MODAL_CONFIG } from './utils/types';

import './AdminModal.scss';
import { useCarbonModalOpened } from '../../hooks';
import { deleteRow } from './utils/adminApi';

export const AdminModal = (props: AdminModalProps) => {
    const { open, modalType, setOpen } = props;
    const [errorNotification, setErrorNotification] = useState<{ title: string; text: string } | null>(null);
    const [deleteEntityId, setDeleteEntityId] = useAtom(deleteEntityIdAtom);
    const [deleteBehaviorId, setDeleteBehaviorId] = useAtom(deleteBehaviorIdAtom);
    const [deleteObjectiveId, setDeleteObjectiveId] = useAtom(deleteObjectiveIdAtom);
    
    useCarbonModalOpened(!open, () => {
        setTimeout(() => {
            setErrorNotification(null);
        }, 500);
    });

    if (deleteEntityId || deleteBehaviorId || deleteObjectiveId) {
        let deleteModalProps = {};
        if (deleteEntityId) {
            deleteModalProps = {
                ...DELETE_MODAL_CONFIG.entity,
                onRequestSubmit: async () => {
                    const { isOk, notification } = await deleteRow('entities', deleteEntityId);
                    if (!isOk) {
                        setErrorNotification(notification);
                    }   
                    if (props.onDelete) {
                        props.onDelete()
                    }
                    setDeleteEntityId(null);
                },
            };
        } else if (deleteBehaviorId) {
            deleteModalProps = {
                ...DELETE_MODAL_CONFIG.behavior,
                onRequestSubmit: async () => {
                    const { isOk, notification } = await deleteRow('behaviors', deleteBehaviorId);
                    if (!isOk) {
                        setErrorNotification(notification);
                    }
                    setDeleteBehaviorId(null);
                },
            };
        } else if (deleteObjectiveId) {
            deleteModalProps = {
                ...DELETE_MODAL_CONFIG.objective,
                onRequestSubmit: async () => {
                    const { isOk, notification } = await deleteRow('objectives', deleteObjectiveId);
                    if (!isOk) {
                        setErrorNotification(notification);
                    }
                    setDeleteObjectiveId(null);
                },
            };
        }
        return (
            <Modal
                open
                danger
                primaryButtonText="Delete"
                secondaryButtonText="Cancel"
                onRequestClose={() => {
                    setDeleteBehaviorId(null);
                    setDeleteObjectiveId(null);
                    setDeleteEntityId(null);
                }}
                {...deleteModalProps}
            />
        );
    }

    return (
        <div className="admin-modal">
            <ComposedModal size="lg" open={open} onClose={() => setOpen(false)}>
                <ModalHeader>
                    <h3>{MODAL_CONFIG[modalType || 'entity']}</h3>
                </ModalHeader>
                <ModalBody>
                    {errorNotification ? <InlineNotification title={errorNotification?.title} subtitle={errorNotification.text} /> : null}
                    {modalType === 'entity' && <EntitiesModal />}
                    {modalType === 'objective' && <ObjectivesModal />}
                    {modalType === 'behavior' && <BehaviorsModal />}
                </ModalBody>
            </ComposedModal>
        </div>
    );
};
