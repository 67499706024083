import React from 'react';
import {
    TableContainer,
    TableToolbar,
    TableBatchActions,
    TableBatchAction,
    DataTable,
    DataTableSkeleton,
    Table,
    TableHead,
    TableHeader,
    TableToolbarContent,
    TableToolbarSearch,
    TableRow,
    TableBody,
    TableCell,
    Button,
    Pagination,
    TableSelectRow,
    TableSelectAll,
    OverflowMenu,
    OverflowMenuItem,
} from '@carbon/react';
import { TrashCan, Edit, Replicate, Add, Compare } from '@carbon/icons-react';
import { useFetchPageList } from './pageListHooks';
import { useCurrentPath, useFilteredData, usePagination } from '../../hooks';
import { QuickFilter, QuickFilterItem } from '../quick-filter/QuickFilter';
import './PageList.scss';
import { HoverTableItem } from '../hoverTableItem/HoverTableItem';

interface TableHeaders {
    key: string;
    header: string;
}

export interface PageListViewLink {
    baseUrl: string;
    columns: string[];
}

type DataTableRow = { id: string; isSelected: boolean; cells: any[]; [key: string]: any };
interface PageListProps {
    rows: any[];
    loading?: boolean;
    hasOverflowMenu?: boolean;
    headers: TableHeaders[];
    editIcon?: JSX.Element;
    viewIcon?: JSX.Element;
    detailIcon?: JSX.Element;
    viewLink?: PageListViewLink;
    mainActionLabel?: string;
    emptyPlaceholder?: string;
    filter: {
        supported: boolean;
        filterBy: { key: string; name: string; [key: string]: any }[];
    };
    onEdit?: (id: string) => void;
    onView?: (id: string) => void;
    onClone?: (id: string) => void;
    onDeleteRows?: (rows: DataTableRow[]) => void;
    onMainAction?: () => void;
    handleCompareCalculi?: (selections: DataTableRow[]) => void;
    customCompare?: { [headerKey: TableHeaders['key']]: (a: any, b: any, locale: string) => number };
}

export const PageList = (props: PageListProps) => {
    const {
        filter,
        loading,
        rows,
        headers,
        editIcon,
        viewIcon,
        detailIcon,
        mainActionLabel,
        viewLink,
        hasOverflowMenu,
        emptyPlaceholder,
        onEdit,
        onView,
        onClone,
        onMainAction,
        onDeleteRows,
        handleCompareCalculi,
        customCompare = {},
    } = props;
    const { filterOptions }: any = useFetchPageList({ ...filter, rows });
    // ^ TODO - return filter set based on passed in filterBy prop
    const { filters, setFilters, filteredData } = useFilteredData(rows);
    const { listItem } = useCurrentPath();
    const { page, pageSize, rowPositions, handlePageChange, jumpToFirstPage } = usePagination();

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilters({ ...filters, all: e.target.value.trim() });
        jumpToFirstPage();
    };

    const onQuickFilter = (key: string, item: QuickFilterItem) => {
        setFilters({ ...filters, [key]: item?.text || '' });
        jumpToFirstPage();
    };

    const filtersElem = filter.filterBy.map((filter, i) => {
        const filterKey = filter.key;
        const items: QuickFilterItem[] = [];
        //iterate thru set
        filterOptions[filterKey].forEach((option: any, i: any): any => {
            option && option !== '' && items.push({ id: i, text: option });
        });
        return <QuickFilter key={i} filterKey={filterKey} label={filter.name} items={items} onChange={onQuickFilter}></QuickFilter>;
    });
    if (loading) return <DataTableSkeleton headers={headers} />;

    return (
        <>
            <DataTable
                rows={filteredData}
                headers={headers}
                isSortable
                stickyHeader
                sortRow={(a: any, b: any, options: any) => {
                    if (customCompare[options.key]) {
                        if (options.sortDirection === 'ASC') {
                            return customCompare[options.key](a, b, options.locale);
                        }
                        return customCompare[options.key](b, a, options.locale);
                    }
                    if (options.sortDirection === 'ASC') {
                        return options.compare(a, b, options.locale);
                    }
                    return options.compare(b, a, options.locale);
                }}
            >
                {({
                    rows,
                    headers,
                    getTableProps,
                    getHeaderProps,
                    getRowProps,
                    getToolbarProps,
                    getBatchActionProps,
                    selectedRows,
                    getSelectionProps,
                }: any) => (
                    <TableContainer className="page-list">
                        <TableToolbar {...getToolbarProps()}>
                            <TableBatchActions {...getBatchActionProps()}>
                                <TableBatchAction
                                    renderIcon={TrashCan}
                                    onClick={() => {
                                        onDeleteRows?.(selectedRows as DataTableRow[]);
                                    }}
                                >
                                    Delete
                                </TableBatchAction>
                                {selectedRows.length === 1 && (
                                    <>
                                        {onClone ? (
                                            <TableBatchAction renderIcon={Replicate} onClick={() => console.log('clicked')}>
                                                Clone
                                            </TableBatchAction>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                )}
                                {selectedRows.length > 1 && handleCompareCalculi && (
                                    <TableBatchAction
                                        renderIcon={Compare}
                                        onClick={() => {
                                            handleCompareCalculi?.(selectedRows as DataTableRow[]);
                                        }}
                                    >
                                        Compare
                                    </TableBatchAction>
                                )}
                            </TableBatchActions>
                            <TableToolbarContent>
                                {filtersElem}
                                <TableToolbarSearch onChange={onSearch} persistent />
                                <Button onClick={onMainAction} renderIcon={Add}>
                                    {mainActionLabel}
                                </Button>
                            </TableToolbarContent>
                        </TableToolbar>
                        <Table {...getTableProps()}>
                            <TableHead>
                                <TableRow>
                                    <TableSelectAll {...getSelectionProps()} />
                                    {headers.map((header: { key: string; header: string }) => {
                                        return (
                                            <TableHeader key={header.key} {...getHeaderProps({ header })} className={'cell-' + header.key}>
                                                {header.header}
                                            </TableHeader>
                                        );
                                    })}
                                    {editIcon || viewIcon || detailIcon ? <TableHeader className="icon-cell">&nbsp;</TableHeader> : null}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.length === 0 && emptyPlaceholder ? (
                                    <TableRow key="empty-row">
                                        <TableCell className="empty-row">{emptyPlaceholder}</TableCell>
                                    </TableRow>
                                ) : null}
                                {rows.slice(rowPositions.start, rowPositions.end).map((row: { cells: any[]; id: string }, i: number) => {
                                    const rowId = row.id;
                                    return (
                                        <TableRow key={i} {...getRowProps({ row })}>
                                            <TableSelectRow {...getSelectionProps({ row })} />
                                            {row.cells.map((cell) => {
                                                let cellElem = null;
                                                if (viewLink?.columns.includes(cell.info.header)) {
                                                    cellElem = <HoverTableItem value={cell.value} onClick={() => onView?.(rowId)} />;
                                                } else if (cell.info.header === 'user_name') {
                                                    const row = filteredData.find((row) => row.id === rowId);
                                                    cellElem = <span>{row.user.name}</span>;
                                                } else {
                                                    cellElem = (
                                                        <div className="hyphen-cell pb-2" title={cell.value}>
                                                            {cell.value}
                                                        </div>
                                                    );
                                                }
                                                return (
                                                    <TableCell key={cell.id} className={`cell-${cell.info.header}`}>
                                                        {cellElem}
                                                    </TableCell>
                                                );
                                            })}
                                            {editIcon || viewIcon ? (
                                                <TableCell className="icon-cell">
                                                    {viewIcon ? (
                                                        <Button
                                                            onClick={() => onView?.(rowId)}
                                                            size="md"
                                                            kind="ghost"
                                                            hasIconOnly
                                                            renderIcon={() => viewIcon}
                                                            iconDescription="Details"
                                                            tooltipAlignment="start"
                                                            tooltipPosition={i === 0 ? 'bottom' : 'top'}
                                                        ></Button>
                                                    ) : null}
                                                    {editIcon ? (
                                                        <Button
                                                            onClick={() => onEdit?.(rowId)}
                                                            size="md"
                                                            kind="ghost"
                                                            hasIconOnly
                                                            renderIcon={() => editIcon}
                                                            iconDescription="Edit"
                                                            tooltipAlignment="start"
                                                            tooltipPosition={i === 0 ? 'bottom' : 'top'}
                                                        ></Button>
                                                    ) : null}
                                                </TableCell>
                                            ) : null}
                                            {detailIcon && hasOverflowMenu && (
                                                <TableCell className="icon-cell d-flex align-items-center">
                                                    <Button
                                                        kind="ghost"
                                                        hasIconOnly
                                                        disabled
                                                        renderIcon={() => detailIcon}
                                                        iconDescription="Details"
                                                        onClick={() => onView?.(row.id)}
                                                    >
                                                        Details
                                                    </Button>
                                                    <OverflowMenu flipped>
                                                        <OverflowMenuItem itemText="Edit" onClick={() => onEdit?.(rowId)}></OverflowMenuItem>
                                                    </OverflowMenu>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </DataTable>
            <Pagination
                id="page-list-pagination"
                page={page}
                pageSize={pageSize}
                pageSizes={[25, 50, 100, 150]}
                totalItems={filteredData?.length}
                onChange={handlePageChange}
            />
        </>
    );
};
