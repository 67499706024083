import { atom, useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useStateObject } from '../../../hooks/useStateObject';
import { Scenario } from '../../../pages/scenario/utils/types';
import { useScenarios } from '../../../pages/scenario/utils/useFetchScenarios';
import { ScenarioInputData } from '../types';

/**
 * Scenario Modal State hook that returns handlers for scenario modal
 */

export const scenarioInputAtom = atom<ScenarioInputData>({
    name: '',
    objectiveSummary: '',
    adversaryDecision: '',
    description: '',
});

export enum invalidNameEnum {
    none = 0,
    emptyName,
    duplicate,
}

export const useScenarioModalState = (initialState: Scenario | undefined) => {
    const [state, setState] = useStateObject({
        name: '',
        objectiveSummary: '',
        adversaryDecision: '',
        description: '',
        ...initialState,
    });
    const [newScenario, setNewScenario] = useAtom(scenarioInputAtom);
    const [nameInvalid, setNameInvalid] = useState(invalidNameEnum.none);
    const { scenarioDetails, loading, fetchScenarioDetails } = useScenarios();

    const handlers = {
        handleScenarioNameChange(e: React.ChangeEvent<HTMLInputElement>) {
            setState({ name: e.target.value ?? '' });
            setNewScenario({ ...newScenario, name: e.target.value });
            setNameInvalid(invalidNameEnum.none);
        },
        handleStrategicSummaryChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
            setState({ objectiveSummary: e.target.value ?? '' });
            setNewScenario({ ...newScenario, objectiveSummary: e.target.value });
        },
        handleAdversaryDecisionChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
            setState({ adversaryDecision: e.target.value ?? '' });
            setNewScenario({ ...newScenario, adversaryDecision: e.target.value });
        },
        handleScenarioDescriptionChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
            setState({ description: e.target.value ?? '' });
            setNewScenario({ ...newScenario, description: e.target.value });
        },
        insertScenarioState(update: ScenarioInputData) {
            setState({ ...update });
            setNewScenario({ ...update });
        },
        initNewScenario() {
            setNewScenario({
                name: '',
                objectiveSummary: '',
                adversaryDecision: '',
                description: '',
            });
        },
    };

    const initialStateId = initialState?.id ?? null;

    useEffect(() => {
        if (initialStateId) {
            fetchScenarioDetails(initialStateId);
        }
    }, [initialStateId, fetchScenarioDetails]);

    return {
        ...state,
        loading,
        newScenario,
        scenarioDetails,
        nameInvalid,
        ...handlers,
        setNameInvalid,
    } as const;
};
