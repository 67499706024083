export type ModalConfig = {
    entity: string;
    objective: string;
    behavior: string;
};

export const MODAL_CONFIG: ModalConfig = {
    entity: 'Manage Entities',
    objective: 'Manage Objectives',
    behavior: 'Manage Behaviors',
};

export const DELETE_MODAL_CONFIG = {
    behavior: {
        modalHeading: 'Are you sure you want to delete behavior?',
        modalLabel: 'Delete Behavior',
    },
    entity: {
        modalHeading: 'Are you sure you want to delete entity?',
        modalLabel: 'Delete Entity',
    },
    objective: {
        modalHeading: 'Are you sure you want to delete objective?',
        modalLabel: 'Delete Objective',
    },
} as const;

export interface AdminModalProps {
    open: boolean;
    modalType?: keyof ModalConfig;
    setOpen: (state: boolean) => void;
    onDelete?: () => void;
}
