import React, { useState } from 'react';

export type HoverTooltipProps = {
    className?: string;
    tooltipContent: null | string | JSX.Element;
    children: JSX.Element | JSX.Element[];
};
export const HoverTooltip = ({ className = '', children, tooltipContent = null }: HoverTooltipProps) => {
    const [show, setShow] = useState(false);

    const handleMouseEnter = () => setShow(true);
    const handleMouseLeave = () => setShow(false);

    return (
        <div className={`hover-tooltip ${className}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} tabIndex={0} role="button">
            <span>{children}</span>
            <div className={show ? 'hover-tooltip-visible' : 'hover-tooltip-hidden'}>
                <span className="hover-tooltip-content pt-2 pb-2 ps-3 pe-3">{tooltipContent}</span>
                <span className="hover-tooltip-caret"></span>
            </div>
        </div>
    );
};
