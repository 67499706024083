import { Reducer, useReducer } from 'react';

const reducer = <T extends object>(state: T & { isValid?: boolean }, newState: Partial<T>, validator: ((state: T) => boolean) | null): T => {
    const combinedState = { ...state, ...newState };
    if (validator) {
        return {
            ...combinedState,
            isValid: validator(combinedState),
        };
    }
    return { ...combinedState };
};

/**
 *   Returns a setState hook that can be used as an object
 *   If supplying a validation function the hook will append an `isValid` flag to the state object
 *
 *   e.g const [state, setState] => useStateObject({ some: 'complexState' });
 *
 *   setState({ some: 'updatedState' })
 */
export function useStateObject<T extends object>(initialState: T, validator: ((state: T) => boolean) | null = null) {
    return useReducer<Reducer<T & { isValid?: boolean }, Partial<T>>>((state, newState) => reducer(state, newState, validator), initialState);
}
