import { Link } from 'react-router-dom';
import './Home.scss';

const line = { display: 'inline-block' };
export const Home = () => {
    return (
        <div className="home-container">
            <img src="/assets/logos/guardian_logo_144x144.png" alt="Guardian Large Logo" />
            <h2 className="mt-3">Welcome to Guardian</h2>
            <h1 className="ms-3 me-3 text-center">
                <span className="d-inline-block">USSTRATCOM J57</span>
                <span className="d-inline-block">&nbsp;Deterrence Analysis and Outreach -&nbsp;</span>
                <span className="d-inline-block">Decision Calculus Application</span>
            </h1>
            <h2>How can I help you today?</h2>
            <div className="tiles-container">
                <HomeTile link="/calculi" label="Decision Calculi" icon="fa-memo-pad"></HomeTile>
                <HomeTile link="/perceptions" label="Perceptions" icon="fa-eye"></HomeTile>
                <HomeTile link="/sources" label="Sources" icon="fa-location-crosshairs"></HomeTile>
                <HomeTile link="/reports" label="Reports" icon="fa-file-invoice"></HomeTile>
            </div>
            <div className="admin-tools">
                <Link to={'/admin'} className="cds--btn cds--btn--tertiary">
                    Admin Tools
                </Link>
            </div>
        </div>
    );
};

type HomeTileProps = {
    link: string;
    icon: string;
    label: string;
};

export const HomeTile = (props: HomeTileProps) => {
    return (
        <Link to={props.link} className="tile-item">
            <i className={`fa-light ${props.icon}`}></i>
            <div className="mt-3 fw-bold text-wrap text-center">{props.label}</div>
        </Link>
    );
};
