import React from 'react'; 
import {FileUploader} from '@carbon/react'

export const FileUploaderUtil = ( props ) => {
    
    const fileUploaderProps = { 
        accept: ['.jpg', '.png'], 
        buttonLabel: 'Add file',
        filenameStatus: 'edit',
        iconDescription: 'Clear file',
        labelDescription: 'Only .jpg and .png files are supported.',
        labelTitle: 'Upload files',
        name: "file",
        size: "md", 
        className:'m-5',
        ...props
    };

    return <FileUploader {...fileUploaderProps} />
};