import { useState } from 'react';
import { DocumentDownload, Edit, ZoomIn } from '@carbon/icons-react';
import { ComboBox, Button, Modal } from '@carbon/react';
import { useNavigate } from 'react-router';

import { Header, IBreadcrumItem } from '../../../components/Header/Header';
import { PerceptionModal } from '../../../modals/perception/PerceptionModal';
import { PageList } from '../../../components/pageList';
import { PageListViewLink } from '../../../components/pageList/PageList';

import { usePerceptionList, usePerceptionState } from '../utils/perceptionListHooks';
import { exportUtility } from '../../../utils/export';
import { compareNames } from '../../../helpers/dataTableSort';
import { DropdownEvent } from '../utils/types';
import { EntityModel } from '../../../modals/entities/EntityModel';
import { useUserRole } from '../../../hooks';

import './PerceptionList.scss';

const BREADCRUMBS: IBreadcrumItem[] = [
    {
        label: 'Perceptions',
    },
];

const TABLE_HEADERS = [
    { header: 'Perception Name', key: 'name' },
    { header: 'Type', key: 'typeLabel' },
    { header: 'Last Modified', key: 'updated' },
    { header: 'Prepared By', key: 'createdBy' },
];

const VIEW_LINK: PageListViewLink = {
    baseUrl: '/perceptions',
    columns: ['name'],
};

export const PerceptionList = () => {
    const navigate = useNavigate();
    const { loading, callApi, entities, selectedEntity, fetchPerceptions, setSelectedEntity, filteredPerceptions, fetchFilteredPerceptions } =
        usePerceptionList();
    const { newPerceptionModalOpen, editPerceptionModalOpen, perceptionToEdit, setNewPerceptionModalOpen, setEditPerceptionModalOpen, setPerceptionToEdit } =
        usePerceptionState();
    const [deleteId, setDeleteId] = useState<null | string>(null);
    useUserRole();

    const onEdit = (id: string) => {
        setPerceptionToEdit(filteredPerceptions.find((p) => p.id === id));
        setEditPerceptionModalOpen(true);
    };

    const onDelete = async () => {
        await callApi('/perceptions/' + deleteId, 'DELETE');
        fetchFilteredPerceptions();
        setDeleteId(null);
    };

    const onOpenDeleteModal = (rows: any[]) => {
        setDeleteId(rows[0]?.id ?? null);
    };

    const onView = (id: string) => {
        navigate(`/perceptions/${id}`);
    };

    const onClose = (source: string | undefined) => {
        if (source === 'new' || source === 'edit') {
            fetchFilteredPerceptions();
        }
        setEditPerceptionModalOpen(false);
        setNewPerceptionModalOpen(false);
    };

    const onMainAction = () => {
        setNewPerceptionModalOpen(true);
    };

    const onExport = () => {
        exportUtility({ data: filteredPerceptions, fileName: 'perceptions', keys: filteredPerceptions[0].getExportColumns() });
    };

    return (
        <div className="perception-list-container d-flex flex-column h-100">
            <Header title="Perceptions List" breadcrumbs={BREADCRUMBS}>
                <Button kind="tertiary" size="md" renderIcon={DocumentDownload} disabled={filteredPerceptions.length === 0} onClick={onExport}>
                    Export Data
                </Button>
            </Header>
            <div className="flex-grow-1 p-4">
                <div className="col-4 mb-3">
                    <ComboBox
                        titleText="Entity"
                        id="entity-dropdown"
                        placeholder="Select an entity to display list of perceptions"
                        items={entities}
                        selectedItem={selectedEntity}
                        itemToString={(item: EntityModel) => (item ? item.name : '')}
                        onChange={(e: DropdownEvent<EntityModel>) => setSelectedEntity(e.selectedItem)}
                    />
                </div>
                {selectedEntity && (
                    <PageList
                        editIcon={<Edit />}
                        viewIcon={<ZoomIn />}
                        onEdit={onEdit}
                        onView={onView}
                        onDeleteRows={onOpenDeleteModal}
                        viewLink={VIEW_LINK}
                        loading={loading}
                        rows={filteredPerceptions}
                        headers={TABLE_HEADERS}
                        mainActionLabel="New Perception"
                        emptyPlaceholder="No Perceptions"
                        onMainAction={onMainAction}
                        filter={{
                            supported: true,
                            filterBy: [
                                { key: 'type', name: 'Type' },
                                { key: 'createdBy', name: 'Prepared By' },
                            ],
                        }}
                        customCompare={{ createdBy: compareNames }}
                    />
                )}
            </div>

            {selectedEntity && (
                <>
                    {newPerceptionModalOpen && (
                        <PerceptionModal open={newPerceptionModalOpen} onSubmit={fetchFilteredPerceptions} onClose={onClose} entity={selectedEntity} />
                    )}

                    {editPerceptionModalOpen && (
                        <PerceptionModal
                            open={editPerceptionModalOpen}
                            onSubmit={fetchFilteredPerceptions}
                            onClose={onClose}
                            perception={perceptionToEdit}
                            entity={selectedEntity}
                        />
                    )}
                    {deleteId ? (
                        <Modal
                            open
                            danger
                            modalHeading="Are you sure you want to delete perception?"
                            modalLabel="Delete perception"
                            primaryButtonText="Delete"
                            secondaryButtonText="Cancel"
                            onRequestClose={() => setDeleteId(null)}
                            onRequestSubmit={onDelete}
                        />
                    ) : null}
                </>
            )}
        </div>
    );
};
