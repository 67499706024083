import React, { useEffect, useState } from 'react';
import { Email } from '@carbon/react/icons';
import { EmailField, ButtonUtil, PostResponseNotification, LinkUtil } from '../../utils';
import './Styles/AccountServices.scss';
import { Form } from '@carbon/react';

export const ForgotPasswordForm = () => {
    const [email, setEmail] = useState({ value: '' });
    const [notificationData, setNotificationData] = useState({});

    const [buttonDisabled, setButtonDisabled] = useState(true);

    useEffect(() => {
        (async () => {
            await global.GetCSRFToken();
        })();
    }, []);

    useEffect(() => {
        if (email.invalid === false || email.value.length > 0) {
            setButtonDisabled(false);
        }
    }, [email.value, email.invalid]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setButtonDisabled(true);
        (async () => {
            try {
                const res = await global.LibAxios.post(
                    '/api/forgotPassword',
                    {
                        email: email.value,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: true,
                        credentials: 'include',
                    }
                );
                console.log('res data', res.data);

                setNotificationData(res);
            } catch (err) {
                setNotificationData(err.response);
            } finally {
                setTimeout(() => {
                    setButtonDisabled(false);
                }, '1000');
            }
        })();
    };

    return (
        <Form>
            <h2 className="mb-2"> Forgot Your Password? </h2>
            <div
                style={{
                    display: 'flex',
                    marginTop: 32,
                }}
            >
                <Email
                    style={{
                        width: 30,
                    }}
                />
                <EmailField textInput={{ ...email, className: '' }} type="emailDomain" validation={true} setState={setEmail} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }} className="mt-4">
                <LinkUtil
                    pathKey="accountServices"
                    link={{
                        children: 'Return to Login',
                        style: { marginLeft: '2em' },
                        className: 'me-auto small',
                    }}
                />
                <ButtonUtil
                    button={{
                        children: 'Send Code to Email',
                        disabled: email.invalid === true || email.value.length === 0 || buttonDisabled,
                        onClick: handleSubmit,
                        type: 'submit',
                    }}
                />
            </div>
            <PostResponseNotification response={notificationData} />
        </Form>
    );
};
