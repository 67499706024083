import React from 'react';
import { TabsLayout } from '../../utils';
import { 
    Profile, 
    ProfilePassword, 
    ActiveSessions, 
    Notifications  
} from '../.';
import { 
    UserAvatarFilled, 
    Password, 
    NotificationFilled, 
    Account 
} from '@carbon/icons-react';
import './Styles/AccountProfile.scss'

export const AccountProfile = () => (
    <div className="account-profile-container">
        <h2 className="account-profile-header mb-5">Rhombus Account Profile</h2>
        <TabsLayout 
            titles={[
                <div> Profile <UserAvatarFilled size={24}/></div>, 
                <div> Password <Password size={24}/></div>, 
                <div> Active Sessions <Account size={24}/></div>, 
                <div> Notifications <NotificationFilled size={24}/></div>
            ]}
            descriptions={[
                <Profile/>, 
                <ProfilePassword/>, 
                <ActiveSessions/>, 
                <Notifications/>
            ]}
            tabList={{
                className:'side-tabs'
            }}
            tab={{
                className:'d-flex flex-row',
            }}
            tabs={{
                className:'container'
            }}
        />
    </div>
)
