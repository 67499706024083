import React, {useState} from 'react';
import { 
    CheckboxElement, 
    ButtonUtil, 
    ScrollableTextElement
} from '../../utils/';
import { sampleText } from './sampleText';
import './DataDump.scss'

export const CreateDataDump = () => {
    const [readTerms, setReadTerms] = useState(false)
    const [checkedBox, setCheckedBox] = useState(false)

    return (
        <div className='m-4'>
            <h1 style={{ marginBottom:21 }}> Create Data Dump </h1>
            <ScrollableTextElement 
                children={sampleText.appleTerms} 
                className='dataDumpTerms'
                setScrolledToBottom={setReadTerms}/>
            <CheckboxElement 
                checkbox={{
                    labelText: 'Yes I agree', 
                    disabled: !readTerms,
                    className:'my-2'
                }} 
                setCheckedBox={setCheckedBox}/>
            <div className='text-center'>
                <ButtonUtil button={{
                    children: 'Create Dump', 
                    disabled: !checkedBox
                }}/> 
            </div>
            
        </div>
    ) 
}