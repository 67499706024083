import { React, useState } from 'react'; 
import {
    FileUploaderDropContainer, 
    FormItem, 
    FileUploaderItem
} from '@carbon/react'

export const FileUploaderDragDrop = ({
    label={},
    formItem = {}, 
    fileUploaderDropContainer={}, 
    fileUploaderItem={} ,
    descriptionVisible =true
}) => {
    const [fileUploaderItems, setFileUploaderItems] = useState([]);
    const [disabled, setDisabled] = useState(false);

    //bugs may happen if set true 
    fileUploaderDropContainer.multiple = false

    const dropContainerProps = { 
        accept: ['.jpg', '.png'], 
        labelText: 'Drag and drop files here or click to upload',
        name: "file",
        size: "md",
        ...fileUploaderDropContainer, 
        disabled: disabled,
        onAddFiles: (evt, { addedFiles }) => {
            if(addedFiles.length===1){
                if (fileUploaderDropContainer.multiple===false && !disabled) {
                    setFileUploaderItems(addedFiles);
                    setDisabled(true); 
                }
                else if (fileUploaderDropContainer.multiple || typeof fileUploaderDropContainer.multiple==='undefined') {
                    setFileUploaderItems([...fileUploaderItems, ...addedFiles]);
                }
            }
        }
    };

    const labelProps = {
        visible:true, 
        text:'Upload Files', 
        ...label
    }

    const acceptFilesA = dropContainerProps.accept.join(' ,')
    const c = (dropContainerProps.accept.at(-1))
    const acceptFilesB = 
        (dropContainerProps.accept.length>1) 
            ?(`${acceptFilesA.slice(0,acceptFilesA.indexOf(c)-1)} and ${c}.`)
            : dropContainerProps.accept   
            
    const onDelete = (evt, uuid) => {
        const fileItems = fileUploaderItems;
        const p = evt.target
                .closest('span.cds--file__selected-file')
                .firstChild;
        const deleteName = p.title

        for (const i in fileUploaderItems) {
            if (fileUploaderItems[i].name === deleteName) {
                fileItems.splice(i, 1)
                setFileUploaderItems(fileItems)
                if (fileUploaderDropContainer.multiple===true || typeof fileUploaderDropContainer.multiple==='undefined') {
                    evt.target.closest('span.cds--file__selected-file').remove()
                }
            }
        }

        if (dropContainerProps.disabled) {
            setDisabled(false)
        }
    }

    if (formItem.className===undefined) {formItem.className= 'm-5'}
    const fileUploaderItemProps = {
        ...fileUploaderItem,
        onDelete:onDelete
    }

    return (
        <FormItem {...formItem}>
            <p className={`cds--file--label ${labelProps.visible ? '' : 'd-none'}`}>
                {labelProps.text}
            </p>
            <p className={`cds--label-description ${descriptionVisible ? '' : 'd-none'}`}>
                {`The following file types are supported: ${acceptFilesB}`}
            </p>
            <FileUploaderDropContainer {...dropContainerProps} />
            <AddFileItems 
                fileItems={fileUploaderItems} 
                fileUploaderItem={fileUploaderItemProps} 
            />
        </FormItem>
    )
};

const AddFileItems = ({fileItems, fileUploaderItem}) => {
    const fileUploaderItemProps = {
        errorBody:"500kb max file size. Select a new file and try again.", 
        errorSubject:"File size exceeds limit",
        iconDescription:"Clear file", 
        size:'md',
        status:'edit',
        ...fileUploaderItem
    }
    const items = fileItems.map((item, index) => (
        <FileUploaderItem
            key={index}
            name={item.name}
            {...fileUploaderItemProps}
        />
    ))

    return <> {items} </>;
};