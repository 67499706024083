import React, { useEffect } from 'react';
import { Button, Loading } from '@carbon/react';
import { Add, Copy, Edit } from '@carbon/react/icons';
import { useParams } from 'react-router-dom';

import { AccordionTable } from '../../components/accordionTable/AccordionTable';
import { useFetchPerceptionDetails, usePerceptionDetailsState } from './utils/perceptionDetailsHooks';

import { AccordionWithButton } from '../../components/accordionWithButton/AccordionWithButton';
import { PerceptionModal } from '../../modals/perception/PerceptionModal';
import './PerceptionDetails.scss';
import { PerceptionAccordionTableProps, PerceptionDetailsProps } from './utils/types';
import { Header, IBreadcrumItem } from '../../components/Header/Header';
import { compareNames } from '../../helpers/dataTableSort';
import { usePerceptionData } from './utils/usePerceptionData';

const objectiveHeaders = [
    { header: 'Objective Name', key: 'objectiveName' },
    { header: 'Calculus Name', key: 'calculusName' },
    { header: '', key: 'edit', isSortable: false },
];

const associatedScenarioHeaders = [
    { header: 'Scenario Name', key: 'scenarioName' },
    { header: 'Calculus Name', key: 'calculusName' },
    { header: '', key: 'edit', isSortable: false },
];

const commentsHeaders = [
    { header: 'Comment', key: 'comment' },
    { header: 'Calculus Name', key: 'calculusName' },
    { header: 'Source Link', key: 'sourceUrl' },
    { header: 'Type (Value)', key: 'typeValue' },
    { header: 'Created On', key: 'created' },
    { header: 'Prepared by', key: 'createdName' },
    { header: '', key: 'edit', isSortable: false },
];

const BREADCRUMBS: IBreadcrumItem[] = [
    {
        label: 'Perceptions',
        url: '/perceptions',
    },
];

export const PerceptionDetails = () => {
    const perceptionId = useParams().id;
    const {
        editPerceptionModalOpen,
        addComment,
        perceptionToEdit,
        setPerceptionToEdit,
        setEditPerceptionModalOpen,
        // handlers
        handleOpenEditPerception,
        handleCloseEditPerception,
        handleOpenAddCommentModal,
        handleCloseAddCommentModal,
        handleEditRow,
        handleDeleteRow,
    } = usePerceptionDetailsState();

    const { perceptions, fetchPerceptions } = usePerceptionData();

    const { perceptionData, isLoading, objectives, associatedScenarios, comments, calculusComments, fetchPerception } = useFetchPerceptionDetails({
        perceptionId,
    });

    const onEdit = () => {
        setPerceptionToEdit(perceptions.find((p) => p.id === perceptionId));
        setEditPerceptionModalOpen(true);
    };

    const onClose = () => {
        fetchPerception();
        fetchPerceptions();
        setPerceptionToEdit(perceptions.find((p) => p.id === perceptionId));
        handleCloseEditPerception();
    };

    return (
        <>
            {isLoading ? <Loading /> : null}
            <article className="perception-details">
                <Header title={perceptions.find((p) => p.id === perceptionId)?.name || ''} breadcrumbs={BREADCRUMBS}>
                    <Button className="ms-auto me-3" kind="tertiary" renderIcon={Edit} onClick={onEdit} iconDescription="edit pencil">
                        Edit Perception
                    </Button>
                    {/* <Button className="ms-auto" size="sm" kind="tertiary" renderIcon={Copy} iconDescription="cope">
                            Add to Calculus
                        </Button> */}
                </Header>
                <div className="m-4">
                    <AccordionWithButton
                        align="start"
                        title={
                            <div className="accordion-heading d-flex align-items-center">
                                <h4>Perception Details</h4>
                            </div>
                        }
                        className="accordion-item w-100"
                        open
                    >
                        <div className="p-3">
                            <h5>Perception Description</h5>
                            <p>{perceptionData && perceptionData.perceptionDescription ? perceptionData.perceptionDescription : 'No Description Found'}</p>
                        </div>
                    </AccordionWithButton>
                </div>
                <div className="row m-4">
                    <PerceptionAccordionTable
                        tableTitle="Calculus Comments"
                        tableName="comments-table"
                        buttonText="Add Comment"
                        tableRows={calculusComments}
                        onEditRow={handleEditRow}
                        onDeleteRow={handleDeleteRow}
                        onClick={handleOpenAddCommentModal}
                        tableHeaders={commentsHeaders}
                    />
                </div>
                <div className="row m-4">
                    <div className="col p-0">
                        <PerceptionAccordionTable
                            tableTitle="Objectives"
                            tableName="objectives-table"
                            tableRows={objectives}
                            onEditRow={handleEditRow}
                            onDeleteRow={handleDeleteRow}
                            tableHeaders={objectiveHeaders}
                        />
                    </div>
                    <div className="col p-0 ps-4">
                        <PerceptionAccordionTable
                            tableTitle="Associated Scenarios"
                            tableName="associated-scenarios-table"
                            tableRows={associatedScenarios}
                            onEditRow={handleEditRow}
                            onDeleteRow={handleDeleteRow}
                            tableHeaders={associatedScenarioHeaders}
                        />
                    </div>
                </div>
            </article>
            {editPerceptionModalOpen && <PerceptionModal open={editPerceptionModalOpen} onClose={onClose} perception={perceptionToEdit} />}
        </>
    );
};

const PerceptionAccordionTable = ({
    tableTitle,
    tableName,
    buttonText,
    onEditRow,
    onDeleteRow,
    onClick,
    tableRows = [],
    tableHeaders = [],
}: PerceptionAccordionTableProps) => {
    return (
        <AccordionTable
            className="perception-accordion-table"
            align="start"
            id={tableTitle}
            title={
                <div className="accordion-heading d-flex align-items-center">
                    <h4>{`${tableTitle} (${tableRows.length})`}</h4>
                </div>
            }
            tableHeaders={tableHeaders}
            tableRows={tableRows}
            startExpanded
            customCompare={{ createdName: compareNames }}
            // onEditRow={(row, index) => onEditRow(tableName, row, index)}
            // onDeleteRow={(row, index) => onDeleteRow(tableName, row, index)}
            // button={
            //     buttonText ? (
            //         <Button
            //             className="ms-auto accordion-heading-button"
            //             size="sm"
            //             kind="ghost"
            //             renderIcon={Add}
            //             iconDescription="plus sign"
            //             onClick={(e: React.MouseEvent<HTMLButtonElement>) => onClick?.(e, { table: tableName })}
            //         >
            //             {buttonText}
            //         </Button>
            //     ) : null
            // }
        ></AccordionTable>
    );
};
