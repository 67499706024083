import { useState } from 'react';
import { ModalConfig } from './types';

export const useAdminModalState = () => {
    const [adminModalOpen, setAdminModalOpen] = useState<boolean>(false);
    const [adminModalType, setAdminModalType] = useState<keyof ModalConfig>();

    const openAdminModal = (type: keyof ModalConfig) => {
        setAdminModalType(type);
        setAdminModalOpen(true);
    };

    return {
        adminModalOpen,
        adminModalType,
        openAdminModal,
        setAdminModalOpen,
    };
};
