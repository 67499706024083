import React from 'react';
import { useAtom } from 'jotai';
import { Modal, TextArea, ComboBox } from '@carbon/react';

import {
    UpdatePerceptionConnectionBody, //
    useCalculusElementApi,
    useEditCalculusElementState,
    useValueFormState,
} from './utils/editCalculusElementHooks';
import { UpdateValueForm } from './UpdateValueForm';

import { useCarbonModalOpened, useCounter, useToggle } from '../../../hooks';

import { calculusPerceptionIdSetAtom } from '../../../pages/calculus/CalculusDecisionDetails/utils/calculusDecisionDetailsAtoms';

import type { EditCalculusElementProps } from './utils/types';
import type { PerceptionConnectedToCalculus } from '../../../commonTypes/perceptionApiTypes';

import './EditCalculusElement.scss';
import { usePerceptionData } from '../../../pages/perception/utils/usePerceptionData';

export const EditCalculusElement = ({ open, calculusId, editRow, onClose, perceptionId, calculusName, perceptionType }: EditCalculusElementProps) => {
    const { count, increment } = useCounter();
    const {
        state, //
        handleSelectPerception,
        handleCommentChange,
        insertState,
        resetState,
    } = useEditCalculusElementState();
    const [showError, toggleShowError] = useToggle(false);
    const [allCalculusPerceptions] = useAtom(calculusPerceptionIdSetAtom);

    const valueForm = useValueFormState();
    const probabilityForm = useValueFormState();

    const { perceptions } = usePerceptionData();

    const {
        isLoading, //
        updatePerception,
        fetchPerceptionConnection,
        updatePerceptionConnection,
        createPerceptionConnection,
    } = useCalculusElementApi();

    // Reset when closing modal
    useCarbonModalOpened(!open, () => {
        resetState();
        valueForm.resetState();
        probabilityForm.resetState();
    });

    useCarbonModalOpened(open, async () => {
        if (editRow?.connectionId) {
            const { isOk, data } = await fetchPerceptionConnection(editRow.connectionId);
            if (isOk) {
                insertState({ perception: data.perception });

                valueForm.insertValueState(data.value);
                probabilityForm.insertValueState(data.probability);
            }
        }

        const calculusPerceptions = allCalculusPerceptions[calculusId] ?? {};

        insertState({
            perceptionList: perceptions
                .filter((perception) => perception.type === perceptionType)
                // Remove perceptions that are already connected but keep the one thats being edited
                .filter((perception) => !calculusPerceptions[perception.id] || perception.id === perceptionId)
                .map((perception) => ({
                    ...perception,
                    id: perception.id,
                    name: perception.name,
                    summary: perception.description,
                    type: perception.type,
                })),
        });

        increment();
    });

    const handleSubmitButton = async () => {
        // check validation
        // if invalid set the proper fields to invalid
        if (!state.isValid || !valueForm.state.isValid || !probabilityForm.state.isValid) {
            toggleShowError(true);
            return;
        }

        if (editRow) {
            const body: UpdatePerceptionConnectionBody = {};
            if (valueForm.state.userUpdated) {
                body.value = { ...valueForm.state, link: valueForm.state.link?.id };
            }
            if (probabilityForm.state.userUpdated) {
                body.probability = { ...probabilityForm.state, link: probabilityForm.state.link?.id };
            }
            if (body.value || body.probability) {
                // Update the value and probability quantities and comments
                await updatePerceptionConnection(editRow.connectionId, body);
            }

            // Update the perception (Top portion of the form)
            await updatePerception(perceptionId, {
                name: editRow?.perception ?? '',
                description: state.perception.name ?? '',
                type: state.perception.type,
                comment: state.comment,
            });
        } else {
            // Create a new connection for the perception to the calculus
            const { isOk, data } = await createPerceptionConnection(calculusId, state.perception.id);
            if (isOk) {
                const body: UpdatePerceptionConnectionBody = {};
                if (valueForm.state.userUpdated) {
                    body.value = { ...valueForm.state, link: valueForm.state.link?.id };
                }
                if (probabilityForm.state.userUpdated) {
                    body.probability = { ...probabilityForm.state, link: probabilityForm.state.link?.id };
                }
                if (body.value || body.probability) {
                    // Update the value and probability quantities and comments
                    await updatePerceptionConnection(data.connection_id, body);
                }
            }
        }
        onClose();
    };

    return (
        <Modal
            className="edit-calculus-element modal-container"
            size="lg"
            open={open}
            onRequestClose={onClose}
            onRequestSubmit={handleSubmitButton}
            modalHeading={<div className="ps-3">{`${editRow ? 'Edit' : 'New'} Calculus Element in ${calculusName}`}</div>}
            primaryButtonText="Update Perception"
            secondaryButtonText="Cancel"
        >
            <div className="ps-3 pe-3 pb-3 d-flex flex-column">
                <div className="mb-3">
                    <ComboBox
                        className="edit-calc-elem-dropdown w-100"
                        id="perception-picker"
                        key={`perception-picker-${count}`}
                        label="Perception"
                        titleText="Perception"
                        selectedItem={state.perception}
                        size="lg"
                        items={state.perceptionList}
                        itemToString={(item: PerceptionConnectedToCalculus) => (item ? item.name : '')}
                        onChange={handleSelectPerception}
                        invalid={showError && state.isValid === false}
                        invalidText="Please select a Perception"
                    />
                </div>
                <div>
                    <TextArea
                        id="edit-calculus-element-comment"
                        key={`edit-calculus-element-comment-${count}`}
                        className="comment-textarea"
                        labelText="Comment"
                        maxCount={500}
                        enableCounter
                        value={state.comment}
                        onChange={handleCommentChange}
                    />
                </div>
                <div className="mt-3 update-value-forms">
                    <UpdateValueForm
                        title="Value"
                        id="edit-calc-element-value"
                        quantity={valueForm.state.quantity}
                        source={valueForm.state.link}
                        sources={valueForm.linkList}
                        comment={valueForm.state.comment}
                        onQuantityChange={valueForm.handleQuantityChange}
                        onSourceChange={valueForm.handleLinkChange}
                        onCommentChange={valueForm.handleCommentChange}
                        previousComments={valueForm.state.commentList}
                        isLoading={isLoading}
                        className="update-value-card"
                    />
                    <UpdateValueForm
                        title="Probability"
                        id="edit-calc-element-probability"
                        quantity={probabilityForm.state.quantity}
                        source={probabilityForm.state.link}
                        sources={probabilityForm.linkList}
                        comment={probabilityForm.state.comment}
                        onQuantityChange={probabilityForm.handleQuantityChange}
                        onSourceChange={probabilityForm.handleLinkChange}
                        onCommentChange={probabilityForm.handleCommentChange}
                        previousComments={probabilityForm.state.commentList}
                        isLoading={isLoading}
                        className="update-value-card"
                    />
                </div>
            </div>
        </Modal>
    );
};
