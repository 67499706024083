import React, {useState} from 'react';
import { TextField, ButtonUtil, FileUploaderDragDrop } from '../../utils';
 
export const UploadDataDump = () => {
    const [bucket, setBucket] = useState({ value:'' })
    const [accessKey, setAccessKey] = useState({ value:'' })
    const [secretKey, setSecretKey] = useState({ value:'' })

    let buttonDisabled = true
    if (
        bucket.value.length>0 && 
        accessKey.value.length>0 && 
        secretKey.value.length>0
    ) {
        buttonDisabled = false
    }

    const fileUploader = {
        labelText: 'Update Your Data on the Current App', 
        accept:['rdb', '.txt', '.json', '.pdf'],
        size:'lg',
        onAddFiles: function noRefCheck(){console.log('added a file')},
        onChange: function noRefCheck(){console.log('something got changed')}, 
    }
    
    return (
        <>
            <div className='container'>
            <h1 className='w-100 text-center my-4 pb-3'>Upload Data Dump</h1>
                <div className='row'>
                    <div className='col d-flex align-items-center text-center'>
                        <FileUploaderDragDrop 
                            fileUploaderDropContainer={fileUploader}
                            fileUploaderItem={{ style:{ margin:'5% auto' } }}
                            descriptionVisible={false}
                            label={{visible:false}}
                        />
                    </div>
                    <div className='col'>
                        <h4>AWS S3 Configuration</h4>
                        <TextField 
                            textInput={{
                                value: bucket.value, 
                                labelText:'Bucket Name', 
                                className:'mb-2 mt-2'
                            }} 
                            setState= {setBucket} 
                        />
                        <TextField 
                            textInput={{
                                value: accessKey.value, 
                                labelText:'Access Key Id', 
                                className:'mb-2'
                            }} 
                            setState= {setAccessKey} 
                        />
                        <TextField 
                            textInput={{
                                value: secretKey.value, 
                                labelText:'Secret Key Id', 
                                className:'mb-2'
                            }} 
                            setState={setSecretKey} 
                        />
                        <ButtonUtil 
                            button={{
                                children: 'Fetch Database', 
                                disabled: buttonDisabled
                            }}
                        /> 
                    </div>
                </div>
            </div>
        </>

    )
}
