import React, { useState, useEffect } from 'react';
import './Styles/AccountServices.scss';
import { useParams, Navigate } from 'react-router-dom';
import { OneTimeTokenLayout } from '../../utils';

export const UserVerifyEmail = () => {
    const params = useParams();

    const [response, setResponse] = useState('');

    useEffect(() => {
        console.log('userVerifyuseEffect');
        (async () => {
            await global.GetCSRFToken();
            try {
                const res = await global.LibAxios.post(
                    '/api/userVerifyEmail',
                    {
                        emailVerificationToken: params.token,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: true,
                        credentials: 'include',
                    }
                );
                setResponse(res.data.message);
            } catch (err) {
                setResponse(err.response.data.errorMessage);
            }
        })();
    }, []);

    return <OneTimeTokenLayout type="emailVerification" homeButton="true" children={response} />;
};
