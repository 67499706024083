import React from 'react';
import {TextArea} from '@carbon/react'

export const TextAreaUtil = ({
    textArea={}, 
    setMessage=()=>{}
}) => {

    const handleChange = (e) => {
        
        setMessage( e.target.value )
        
    }

    const textAreaProps = {
        id: `textArea${Math.random.toString()}`, 
        labelText: 'Text Area', 
        // hideLabel: true,  
        onChange: e=>handleChange(e), 
        invalidText: `Please input valid text.`, 
        enableCounter:true,
        ...textArea
    }

    return <TextArea {...textAreaProps}/>
}