export const compareStrings = (a: any, b: string, locale = 'en') => {
    if (!isNaN(a) && !isNaN(parseFloat(a))) {
        return a.localeCompare(b, locale, { numeric: true, sensitivity: 'base' });
    }

    return a.localeCompare(b, locale, { sensitivity: 'base' });
};

export const compareNames = (a: any, b: any, locale = 'en') => {
    if (a === null) {
        a = '';
    }
    if (b === null) {
        b = '';
    }

    if (typeof a === 'number' && typeof b === 'number') {
        return a - b;
    }

    if (typeof a === 'string' && typeof b === 'string') {
        const splitA = a.trim().split(' ');
        const splitB = b.trim().split(' ');
        const result = compareStrings(splitA[splitA.length - 1] ?? '', splitB[splitB.length - 1] ?? '', locale);
        if (result === 0) {
            return compareStrings(a, b, locale);
        }
        return result;
    }

    if (typeof a === 'object' && typeof b === 'object') {
        if (typeof a.props.children === 'string' && typeof b.props.children === 'string') {
            const splitA = a.props.children.trim().split(' ');
            const splitB = b.props.children.trim().split(' ');
            const result = compareStrings(splitA[splitA.length - 1] ?? '', splitB[splitB.length - 1] ?? '', locale);
            if (result === 0) {
                return compareStrings(a.props.children, a.props.children, locale);
            }
        }
    }

    return compareStrings('' + a, '' + b, locale);
};
