import { Layout } from './components/Layout';
import { paths, sadcPaths } from './config';
import { GuardianPanel, PrivateRoute } from './utils';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import './global';
import { Home } from './pages/home/Home';

interface pathObj {
    path: string;
    element: any;
    pathType: string;
    name?: string;
    accountTypes?: string[];
    guardianPanel?: boolean;
    guardianPanelForm?: boolean;
}

const App = () => {
    useLocation();
    const basecodeRoutesElement = [...Object.values(paths), ...sadcPaths].map((pathObj: pathObj) => {
        const { pathType, accountTypes, path, element, name, guardianPanel, guardianPanelForm } = pathObj;
        if (path === window.location.pathname) {
            document.title = `${name} - ADC`;
        }
        let elementBody = element;

        if (pathType === 'closed') {
            elementBody = <PrivateRoute element={elementBody} accountTypes={accountTypes} />;
        }
        if (guardianPanel === true) {
            elementBody = <GuardianPanel element={element} form={true || guardianPanelForm} />;
        }

        return <Route key={path} path={path} element={elementBody} />;
    });
    return (
        <>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<PrivateRoute index element={<Home />} accountTypes={['user', 'moderator', 'admin']} />} />

                    {basecodeRoutesElement}
                </Route>
            </Routes>
        </>
    );
};

export default App;
