import React from 'react';
import { Accordion, AccordionItem } from '@carbon/react';
import { AccordionWithButtonProps } from './types';

export const AccordionWithButton = ({
    title,
    className = '',
    open = false,
    children = null,
    button = null,
    align = 'start',
}: AccordionWithButtonProps) => {
    return (
        <Accordion align={align} className="accordion-with-button">
            <AccordionItem
                title={title}
                className={`accordion-item w-100 ${className}`}
                open={open}
            >
                <div className="accordion-heading-button">{button}</div>
                {children}
            </AccordionItem>
        </Accordion>
    );
};
