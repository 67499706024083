import { useCallback, useState } from 'react';
import { Button, Popover, PopoverContent } from '@carbon/react';

import { useToggle } from '../../hooks';

import './HoverTableItem.scss';

function isEllipsisActive(e: HTMLDivElement | null) {
    if (e === null) return false;
    return e.offsetWidth < e.scrollWidth;
}

export const HoverTableItem = ({ value, onClick }: { value: string; onClick?: () => void }) => {
    const [isTruncated, setIsTruncated] = useState(false);
    const getNode = useCallback((node: HTMLDivElement) => {
        if (node) {
            setIsTruncated(isEllipsisActive(node));
        }
    }, []);

    return (
        <HoverTableItemWithPopover value={value} onClick={onClick} includePopover={isTruncated}>
            <div ref={getNode}>{value}</div>
        </HoverTableItemWithPopover>
    );
};

const HoverTableItemWithPopover = ({ children, onClick, includePopover, value }: any) => {
    const [openPopover, togglePopover] = useToggle(false);

    if (includePopover) {
        return (
            <Popover open={openPopover} align="right" autoAlign={false} className="popover-container hover-table-item">
                <Button
                    className={`${onClick ? 'btn-view-link' : 'no-link'} pe-0`}
                    size="md"
                    kind="ghost"
                    onClick={onClick}
                    onMouseEnter={() => togglePopover(true)}
                    onMouseLeave={() => togglePopover(false)}
                >
                    {children}
                </Button>
                <PopoverContent className="popover-content p-3">
                    <div>{value}</div>
                </PopoverContent>
            </Popover>
        );
    }

    return (
        <div className="popover-container hover-table-item">
            <Button className={`${onClick ? 'btn-view-link' : 'no-link'} pe-0`} size="md" kind="ghost" onClick={onClick}>
                {children}
            </Button>
        </div>
    );
};
