import axios from 'axios';

type Slug = 'entities' | 'behaviors' | 'objectives';

export const errorCodes = {
    unauthorized: { title: 'Unauthorized', text: 'Please contact an admin to delete' },
};

export const deleteRow = async (slug: Slug, id: string) => {
    try {
        const resp = await axios.delete(`/api/v1/${slug}/${id}`);
        return {
            isOk: true,
            data: resp?.data,
            error: null,
            notification: null,
        } as const;
    } catch (error: any) {
        let notification = null;
        if (error.response?.data?.detail === 'Unauthorized') {
            notification = errorCodes.unauthorized;
        }
        return {
            isOk: false,
            data: null,
            notification,
            error,
        } as const;
    }
};
