import { atom, useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useCRUDApi } from '../../hooks/useCRUDApi';
import { mapBehaviors, BehaviorModel } from './BehaviorModel';

export const loadingBehaviorsAtom = atom(false);
export const behaviorsDataAtom = atom<BehaviorModel[]>([]);

export const useBehaviors = () => {
    const { error, loading, callApi } = useCRUDApi('/api/v1');
    const [behaviors, setBehaviors] = useAtom(behaviorsDataAtom);
    const [selectedBehavior, setSelectedBehavior] = useState<BehaviorModel>();

    const createBehavior = useCallback(
        async (newBehavior: Partial<BehaviorModel>) => {
            try {
                const resp = await callApi('/behaviors', 'post', newBehavior);
                if (resp && resp.behavior_id) {
                    return {
                        isOk: true,
                        error: null,
                        behaviorId: resp.behavior_id as string,
                    } as const;
                }
                throw new Error('Could not create behavior');
            } catch (error) {
                return {
                    isOk: false,
                    behaviorId: null,
                    error,
                } as const;
            }
        },
        [callApi]
    );

    const fetchBehaviors = useCallback(async () => {
        const resp = await callApi('/behaviors', 'get');
        const data = mapBehaviors(resp.rows);
        setBehaviors(data);
        return data;
    }, [callApi, setBehaviors]);

    useEffect(() => {
        fetchBehaviors();
    }, [fetchBehaviors]);

    return {
        selectedBehavior,
        createBehavior,
        behaviors,
        error,
        loading,
        fetchBehaviors,
        setSelectedBehavior,
        setBehaviors,
        callApi,
    };
};
