import { parseISO, formatISO9075 } from 'date-fns';

export const mapObjectives = (objectives: any[]) => objectives.map((e) => new ObjectiveModel(e));

export class ObjectiveModel {
    id = '';
    objective_id = '';
    name = '';
    description = '';
    createdBy = '';
    created = '';
    user_id = '';
    user = '';
    updated = '';
    used = 0;
    constructor(data: Partial<ObjectiveModel>) {
        Object.assign(this, data);
        this.id = data.objective_id || data.id || '';
        this.createdBy = data.user || '';
        this.updated = data?.updated ? formatISO9075(parseISO(data?.updated || '')) : '';
    }
}
