import React from 'react';

export const Footer = ({ project="App"}) => {
    let currYear = new Date().getFullYear()
    return <>
        <footer >
            <div className="form-group text-muted small text-center mt-md-5"> 
                GUARDIAN © 2011-{currYear} Rhombus Power Inc. 
                <span className="text-success pl-2">
                        {/* <img></img>  */} {project}
                </span> 
            </div>
        </footer>
    </>
}
