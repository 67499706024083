import { useState, useCallback } from 'react';
import { useCRUDApi } from '../../../hooks/useCRUDApi';
import { useToggle } from '../../../hooks';
import { ReportModel, mapReports } from './ReportModel';
import { useDecisionCalculus } from '../../calculus/DecisionCalculus/utils/useDecicisionCalculusHooks';
import { CalculusData } from '../../../modals/calculusInputs/types';

export const useReportList = () => {
    const { callApi, loading, error } = useCRUDApi('/api/v1');
    const [isLoading, toggleIsLoading] = useToggle(false);
    const [tableData, setTableData] = useState<ReportModel[]>([]);
    const { fetchCalculi } = useDecisionCalculus();
    const fetchReportsList = useCallback(async () => {
        const data = await callApi('/reports', 'get');
        const reportRows = mapReports(data?.rows);
        if (reportRows) {
            setTableData(reportRows);
            return reportRows;
        }
        fetchCalculi();
    }, [callApi, setTableData, fetchCalculi]);

    const deleteReport = useCallback(
        async (deleteId: string) => {
            const res = await callApi(`/reports/${deleteId}`, 'delete');

            if (res) {
                fetchReportsList();
            }
        },
        [callApi, fetchReportsList]
    );

    return {
        callApi,
        loading,
        isLoading,
        tableData,
        fetchReportsList,
        deleteReport,
        error,
    };
};

export const useReportState = () => {
    const [newReportModalOpen, setNewReportModalOpen] = useState(false);
    const [editReportModalOpen, setEditReportModalOpen] = useState(false);
    const [reportToEdit, setReportToEdit] = useState<ReportModel>();
    const [selectedReports, setSelectedReports] = useState<string[]>([]);
    const [calcRows, setCalcRows] = useState<CalculusData[] | null>([]);

    return {
        selectedReports,
        newReportModalOpen,
        editReportModalOpen,
        reportToEdit,
        calcRows,
        setSelectedReports,
        setNewReportModalOpen,
        setEditReportModalOpen,
        setReportToEdit,
        setCalcRows,
    };
};
