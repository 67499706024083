import { Edit, TrashCan } from '@carbon/icons-react';
import {
    Button,
    DataTable,
    DataTableSkeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch,
} from '@carbon/react';
import './FilteredTable.scss';
import { HoverTableItem } from '../hoverTableItem/HoverTableItem';

type Props = {
    headers: any;
    data: any[];
    mainActionLabel?: string;
    onEdit: (id: string) => void;
    onDelete: (id: string) => void;
    onMainAction: () => void;
    loading: boolean;
    customCompare?: { [headerKey: string]: (a: any, b: any, locale: string) => number };
};

export const FilteredTable = ({ headers, data, loading, mainActionLabel, onMainAction, onEdit, onDelete, customCompare = {} }: Props) => {
    if (loading) return <DataTableSkeleton headers={headers} showHeader={false} />;

    return (
        <DataTable
            rows={data}
            headers={headers}
            stickyHeader
            isSortable
            sortRow={(a: any, b: any, options: any) => {
                if (customCompare[options.key]) {
                    if (options.sortDirection === 'ASC') {
                        return customCompare[options.key](a, b, options.locale);
                    }
                    return customCompare[options.key](b, a, options.locale);
                }
                if (options.sortDirection === 'ASC') {
                    return options.compare(a, b, options.locale);
                }
                return options.compare(b, a, options.locale);
            }}
        >
            {({ rows, headers, getTableProps, getHeaderProps, getRowProps, onInputChange }: any) => (
                <TableContainer className="filtered-table">
                    <TableToolbar>
                        <TableToolbarContent>
                            <TableToolbarSearch persistent onChange={onInputChange} />
                            {mainActionLabel && <Button onClick={() => onMainAction()}>{mainActionLabel}</Button>}
                        </TableToolbarContent>
                    </TableToolbar>
                    <Table {...getTableProps()}>
                        <TableHead>
                            <TableRow>
                                {headers.map((header: any) => (
                                    <TableHeader key={header.key} className={'cell-' + header.key} {...getHeaderProps({ header })}>
                                        {header.header}
                                    </TableHeader>
                                ))}
                                <TableHeader style={{ maxWidth: '9rem' }}></TableHeader>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={headers.length} className="text-muted justify-content-center">
                                        No Data Found
                                    </TableCell>
                                </TableRow>
                            ) : (
                                ''
                            )}
                            {rows.map((row: any) => (
                                <TableRow {...getRowProps({ row })} key={row.id}>
                                    {row.cells.map((cell: any) => {
                                        let cellElem = <>{cell.value}</>;
                                        if (cell.info.header === 'name') {
                                            cellElem = <HoverTableItem value={cell.value} />;
                                        }

                                        return (
                                            <TableCell className={'cell-' + cell.info.header} key={cell.id}>
                                                {cellElem}
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell className="icon-cell" style={{ maxWidth: '9rem' }}>
                                        <Button kind="ghost" onClick={() => onEdit(row.id)}>
                                            <Edit />
                                        </Button>
                                        <Button kind="ghost" onClick={() => onDelete(row.id)}>
                                            <TrashCan />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </DataTable>
    );
};
