import React from 'react';
import { SkeletonPlaceholder, SkeletonText } from '@carbon/react';

import type { CommentListProps } from './types';
import './CommentList.scss';
import { CommentModel } from '../../shared/CommentModel';

export const CommentList = ({
    title = 'Previous Comments',
    className = '',
    isLoading = false,
    loadingCommentLength = 5,
    hideCount = false,
    comments,
}: CommentListProps) => {
    const count = hideCount ? '' : ` (${comments.length})`;
    if (isLoading) {
        return (
            <div className={`comment-list ${className}`}>
                <h5>{`${title}${count}`}</h5>
                <div className="pe-3 ps-3 pt-2 pb-2 comment-list-container">
                    {[...Array(loadingCommentLength)].map((_x, i) => (
                        <LoadingComment key={`loading_${i}`} />
                    ))}
                </div>
            </div>
        );
    }
    return (
        <div className={`comment-list ${className}`}>
            <h5>{`${title}${count}`}</h5>
            <div className="pe-3 ps-3 pt-2 pb-2 comment-list-container">
                {comments.map((comment) => (
                    <Comment key={comment.id} comment={comment} />
                ))}
            </div>
        </div>
    );
};

const LoadingComment = () => (
    <div className="mb-3 mt-2">
        <div className="d-flex justify-content-between">
            <div className="mb-1 d-flex">
                <div>Value:</div>
                <div>
                    <SkeletonPlaceholder className="ms-2 h-100" />
                </div>
            </div>
        </div>
        <div>
            <SkeletonText lineCount={4} paragraph />
        </div>
    </div>
);

const Comment = ({ comment: { value, created, user, comment } }: { comment: CommentModel }) => {
    return (
        <div className="comment-item">
            <div className="d-flex justify-content-between comment-title">
                {value ? (
                    <div className="mb-1">
                        <strong>{`Value: ${value}`}</strong>
                    </div>
                ) : null}
                <div className="comment-user">{`[${user?.name}, ${created}]`}</div>
            </div>
            <p>{comment}</p>
        </div>
    );
};
