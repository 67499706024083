import { OrderedList, ListItem } from '@carbon/react';
export const ReleaseNotes = () => {
    return (
        <div className="release-notes-container">
            <h1>Release Notes</h1>
            <div className="release-notes-text">
                <p>Decision Calculus Application </p>
                <p>Version 1.2.0 </p>
                <p>April 28 2023</p>
            </div>
            <OrderedList className="release-notes-list">
                <ListItem>
                    Calculi
                    <OrderedList nested>
                        <ListItem>Updated edit calculus summary popup</ListItem>
                        <ListItem>Removed Edit capability from list view</ListItem>
                    </OrderedList>
                </ListItem>
                <ListItem>
                    Sources
                    <OrderedList nested>
                        <ListItem>Added list of perceptions and removed comments element </ListItem>
                        <ListItem>Updated validation on name when creating source</ListItem>
                        <ListItem>Removed time from date field</ListItem>
                    </OrderedList>
                </ListItem>
                <ListItem>
                    Perceptions
                    <OrderedList nested>
                        <ListItem> Added source selection to edit/new perception popup</ListItem>
                        <ListItem> Restrict perception edit actions based on account role</ListItem>
                    </OrderedList>
                </ListItem>
                <ListItem>
                    Reports
                    <OrderedList nested>
                        <ListItem> Connected reports list to database</ListItem>
                    </OrderedList>
                </ListItem>
                <ListItem>
                    General Updates
                    <OrderedList nested>
                        <ListItem>Add validation on entities, objectives, behaviors to prevent multiple entries with the same name</ListItem>
                    </OrderedList>
                </ListItem>
            </OrderedList>
            <div className="release-notes-text">
                <p>Version 1.1.0 </p>
                <p>April 18 2023</p>
            </div>
            <OrderedList className="release-notes-list">
                <ListItem>
                    Decision Calculi
                    <OrderedList nested>
                        <ListItem>Linked perceptions when creating new calculus using prefill method</ListItem>
                        <ListItem>Copies of existing scenario, objective, and behavior are created when creating new calculus using prefill.</ListItem>
                        <ListItem>Removed Total Ranking in details page</ListItem>
                        <ListItem>Added back button to allow user to navigate to previously viewed calculus</ListItem>
                    </OrderedList>
                </ListItem>
                <ListItem>
                    Perceptions
                    <OrderedList nested>
                        <ListItem>Added an entity dropdown to filter the perceptions displayed in the table </ListItem>
                    </OrderedList>
                </ListItem>
                <ListItem>
                    Sources
                    <OrderedList nested>
                        <ListItem>Added an entity dropdown to filter the sources displayed in the table</ListItem>
                        <ListItem>Added Title and Date of Article columns in Source List</ListItem>
                        <ListItem>Added Perceptions table in Edit Source dialogue box</ListItem>
                        <ListItem>Removed requirement for Link field when creating source</ListItem>
                    </OrderedList>
                </ListItem>
                <ListItem>
                    Scenarios
                    <OrderedList nested>
                        <ListItem>Increased Scenario Description text limit to 2000, in Calculus Detail page </ListItem>
                        <ListItem>Removed Scenario List page</ListItem>
                    </OrderedList>
                </ListItem>
                <ListItem>
                    General Updates
                    <OrderedList nested>
                        <ListItem>
                            Changed title from &quot;Decision Calculus Application&quot; to &quot;USSTRATCOM J57 Deterrence Analysis and Outreach - Decision
                            Calculus Application&quot;
                        </ListItem>
                        <ListItem>Updated Prepared By columns to sort by last name in all tables</ListItem>
                        <ListItem>Added notification with log out warning</ListItem>
                    </OrderedList>
                </ListItem>
            </OrderedList>
        </div>
    );
};
