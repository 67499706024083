import React from 'react';
import { GridLayout, LoadingUtil} from '../../utils'
export const LoadingPage = () =>  
    <GridLayout 
        container={{className:"my-auto"}} 
        cols={[
            {
                children:
                    <LoadingUtil 
                        loading={{
                            small:false, 
                            withOverlay:false
                        }}
                    /> 
            }, 
            {
                children:
                    <LoadingUtil 
                    type='inline'
                    inlineLoading={{
                        className:'mt-3',
                        description:<em>Loading data</em>
                    }}
                /> 
            }, 
        ]}
    /> 