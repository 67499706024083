import React, { useState } from 'react';
import { Button, DataTableSkeleton, TextArea, TextInput } from '@carbon/react';
import { atom, useSetAtom } from 'jotai';

import { FilteredTable } from '../../components/filtered-table/FilteredTable';
import { useBehaviors } from './BehaviorHooks';
import { BehaviorModel } from './BehaviorModel';
import { compareNames } from '../../helpers/dataTableSort';

const TABLE_HEADERS = [
    {
        key: 'name',
        header: 'Name',
    },
    {
        key: 'description',
        header: 'Description',
    },
    // {
    //     key: 'used',
    //     header: 'In Use',
    // },
    {
        key: 'createdBy',
        header: 'Created By',
    },
    {
        key: 'updated',
        header: 'Last Modified',
    },
];

export const deleteBehaviorIdAtom = atom<null | string>(null);

const BehaviorsModal = () => {
    const { selectedBehavior, behaviors, loading, fetchBehaviors, setSelectedBehavior, callApi } = useBehaviors();
    const setDeleteId = useSetAtom(deleteBehaviorIdAtom);
    const [invalidBehaviorName, setInvalidBehaviorName] = useState(false)
    const [currentBehaviorName, setCurrentBehaviorName] = useState('')

    const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedBehavior(new BehaviorModel({ ...selectedBehavior, name: e.target.value.trim() }));
        setInvalidBehaviorName(false)
    };

    const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedBehavior(
            new BehaviorModel({
                ...selectedBehavior,
                description: e.target.value,
            })
        );
    };

    const onSave = async () => {
        let url = '/behaviors',
            method = 'post';

        if (selectedBehavior?.id) {
            url = '/behaviors/' + selectedBehavior?.id;
            method = 'put';
        }

        const behaviorNames = behaviors.map((behavior) => (behavior.name))

        const nameExists = selectedBehavior?.id ?
        behaviorNames.filter((behavior) => behavior !== currentBehaviorName).includes(selectedBehavior?.name) 
        : behaviorNames.includes(selectedBehavior?.name ?? '')

        if (nameExists || selectedBehavior?.name === '') {
            setInvalidBehaviorName(true)
            return
        }

        if (!invalidBehaviorName) {
            await callApi(url, method, selectedBehavior);
            await fetchBehaviors();
            setSelectedBehavior(undefined);
        }
    };

    const onEdit = (id: string) => {
        let behavior = behaviors.find((e) => e.id === id)
        setInvalidBehaviorName(false)
        setSelectedBehavior(behaviors.find((e) => e.id === id));
        setCurrentBehaviorName(behavior?.name ?? '')
    };

    const onNew = () => {
        setInvalidBehaviorName(false)
        setSelectedBehavior(new BehaviorModel({}));
    };

    if (loading) return <DataTableSkeleton headers={TABLE_HEADERS} />;

    return (
        <div className={selectedBehavior ? 'has-form' : ''}>
            <FilteredTable
                headers={TABLE_HEADERS}
                data={behaviors}
                onEdit={onEdit}
                onDelete={setDeleteId}
                loading={loading}
                onMainAction={onNew}
                mainActionLabel="New Behavior"
                customCompare={{ createdBy: compareNames }}
            ></FilteredTable>
            {selectedBehavior && (
                <>
                    <div className="edit-item-form">
                        <h2 className="mb-2">
                            {selectedBehavior.id === '' ? 'New ' : 'Edit '}
                            Behavior
                        </h2>
                        <div className="d-flex align-items-end">
                            <div className="d-flex flex-column flex-grow-1">
                                <TextInput
                                    className="mb-3"
                                    id="name"
                                    type="text"
                                    maxLength="255"
                                    labelText="Behavior Name"
                                    value={selectedBehavior.name.trim()}
                                    onChange={onNameChange}
                                    invalid = {invalidBehaviorName}
                                    invalidText = {selectedBehavior.name === '' ? "Please try a behavior name that is not empty" : "The behavior name must be unique"}
                                />
                                <TextArea
                                    id="description"
                                    maxLength="1000"
                                    labelText="Behavior Description"
                                    value={selectedBehavior.description || ''}
                                    onChange={onDescriptionChange}
                                />
                            </div>

                            <Button kind="primary" size="md" className="mx-3" onClick={onSave}>
                                {selectedBehavior.id ? 'Update' : 'Create'}
                            </Button>
                            <Button kind="tertiary" size="md" onClick={() => setSelectedBehavior(undefined)}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default BehaviorsModal;
