import React, { useEffect, useState } from 'react';
import { Datatable, refreshTable } from '../../utils/Datatable';

export const CodeIntegrityTable = () => {
    const headers = [
        {
            header: 'ID', // Display on UI table header
            key: 'id', // id to link row with header
            format: (row) => {
                return JSON.stringify(row.id);
            }, // format of how the column should display
        },
        {
            key: 'hash',
            header: 'Hash',
            format: (row) => {
                return row.hash;
            },
        },
        {
            key: 'timestamp',
            header: 'Created On',
            format: (row) => {
                return row.timestamp;
            },
        },
        {
            key: 'userID',
            header: 'Created By',
            format: (row) => {
                return row.userID;
            },
        },
        {
            key: 'status',
            header: 'Status',
            format: (row) => {
                return row.status;
            },
        },
        {
            key: 'userTimestamp',
            header: 'User Timestamp',
            format: (row) => {
                return row.userTimestamp;
            },
        },
    ];

    // DATATABLE PROPS
    const [rows, setRows] = useState([]); // raw table data
    const [formattedRows, setFormattedRows] = useState([]); // table data formatted for UI look
    const [datatableStatus, setDatatableStatus] = useState('loading');

    useEffect(() => {
        (async () => {
            const res = await global.LibAxios.post('/api/codeIntegrity').catch((err) => console.log(err.response.data.errorMessage));
            refreshTable(res.data, headers, setRows, setFormattedRows);
            setDatatableStatus(!(res.data.length === 0));
        })();
    }, []);

    return (
        <>
            <Datatable headers={headers} rows={formattedRows} tableTitle="Code Integrity" datatableStatus={datatableStatus} />
        </>
    );
};
