import { useState, useMemo } from 'react';
/**
 * This
 * @param data a list of obejcts that should be filtered
 * @returns
 */
export const useFilteredData = (data: any[]) => {
    // A filter object of key value pairs that will be matched against the data
    const [filters, setFilters] = useState<Record<string, string>>({});

    const filteredData = useMemo(() => {
        let fData = data || [];
        for (const key in filters) {
            if (key === 'all' && filters[key] !== '') {
                // if the key 'all' is set run a search across all properties
                fData = fData.filter((d) => {
                    return Object.values(d).join('').toLowerCase().search(filters[key].toLowerCase()) !== -1;
                });
            } else if (filters[key] !== '') {
                // otherwise check if any key value pairs match the data
                fData = fData.filter((d) => {
                    let match = false;
                    if (filters[key] === d[key]) {
                        match = true;
                    }
                    return match;
                });
            }
        }
        return fData;
    }, [data, filters]);

    return {
        filteredData,
        filters,
        setFilters,
    };
};
