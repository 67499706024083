import React, { useEffect, useState } from 'react';
import { PasswordField, ButtonUtil, PostResponseNotification } from '../../utils';
import { Password } from '@carbon/icons-react';
import './Styles/AccountServices.scss';
import { Form } from '@carbon/react';

export const ResetPasswordForm = () => {
    const [currentPassword, setCurrentPassword] = useState({ value: '' });
    const [password, setPassword] = useState({ value: '' });
    const [duplicate, setDuplicate] = useState({ value: '' });
    const [notificationData, setNotificationData] = useState({});
    const [buttonDisabled, setButtonDisabled] = useState(true);

    useEffect(() => {
        (async () => {
            await global.GetCSRFToken();
        })();
    }, []);

    useEffect(() => {
        if (
            currentPassword.value.length > 0 &&
            password.invalid === false &&
            password.value.length > 0 &&
            duplicate.invalid === false &&
            duplicate.value.length > 0
        ) {
            setButtonDisabled(false);
        }
    }, [currentPassword, password, duplicate]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setButtonDisabled(true);
        (async () => {
            try {
                const res = await global.LibAxios.post(
                    '/api/resetPassword',
                    {
                        currentPassword: currentPassword.value,
                        password: password.value,
                        retypePassword: duplicate.value,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: true,
                        credentials: 'include',
                    }
                );
                console.log(res);
                setNotificationData(res);
            } catch (err) {
                setNotificationData(err.response);
            } finally {
                setTimeout(() => {
                    setButtonDisabled(false);
                }, '1000');
            }
        })();
    };

    return (
        <Form>
            <h2>Reset Password</h2>
            <div
                style={{
                    display: 'flex',
                    marginTop: 32,
                    marginBottom: 15,
                }}
            >
                <Password style={{ width: 30 }} />
                <PasswordField
                    passwordInput={{
                        ...currentPassword,
                        labelText: (
                            <>
                                Current Password <span style={{ color: 'red' }}>* </span>
                            </>
                        ),
                    }}
                    setState={setCurrentPassword}
                    type="password"
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    marginBottom: 15,
                }}
            >
                <Password style={{ width: 30 }} />
                <PasswordField
                    passwordInput={{
                        ...password,
                        labelText: (
                            <>
                                New Password <span style={{ color: 'red' }}>* </span>
                            </>
                        ),
                    }}
                    type="password"
                    validation={true}
                    duplicate={duplicate.value}
                    setState={setPassword}
                    setDuplicate={setDuplicate}
                />
            </div>
            <div
                style={{
                    display: 'flex',
                }}
            >
                <Password style={{ width: 30 }} />
                <PasswordField
                    passwordInput={{
                        ...duplicate,
                        labelText: (
                            <>
                                Confirm New Password <span style={{ color: 'red' }}>* </span>
                            </>
                        ),
                    }}
                    type="duplicate"
                    validation={true}
                    original={password.value}
                    setState={setDuplicate}
                />
            </div>
            <ButtonUtil
                button={{
                    className: 'd-flex mt-4 ms-auto',
                    children: 'Reset Password',
                    disabled: buttonDisabled,
                    onClick: handleSubmit,
                    type: 'submit',
                }}
            />
            <PostResponseNotification response={notificationData} />
        </Form>
    );
};
