/**
 * Returns true if the user is logged in as one of the given account types.
 * @param {Array} accountTypes array of strings containing the allowed account types
 */
export const verifyJWT = async (accountTypes) => {
    try {
        const res = await global.LibAxios.post(
            '/api/verifyJWT',
            {
                accountTypes: accountTypes,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                credentials: 'include',
            }
        );
        return res.data.authorized;
    } catch (err) {
        return false;
    }
};

/**
 * Returns true if the user is logged in.
 * @param {Array} accountTypes array of strings containing the allowed account types
 */
export const loggedIn = async () => {
    try {
        const res = await global.LibAxios.get('/api/loggedIn', {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
            credentials: 'include',
        });
        return res.data.authorized;
    } catch (err) {
        return false;
    }
};

export const refreshJWT = async () => {
    try {
        const res = await global.LibAxios.post('/api/refreshJWT', {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
            credentials: 'include',
        });
        return res.data;
    } catch (err) {
        return false;
    }
};

export const logout = async () => {
    try {
        const res = await global.LibAxios.post(
            '/api/logout',
            {},
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                credentials: 'include',
            }
        );
        return res.data;
    } catch (err) {
        return false;
    }
};

export const getUserRole = async () => {
    try {
        const res = await global.LibAxios.get('/api/userRole', {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
            credentials: 'include',
        });
        return res.data.role;
    } catch (err) {
        return false;
    }
};
