import { format } from 'date-fns';
import { json2csv } from 'json-2-csv';

export type ExportKeysList = (
    | string
    | {
          field: string;
          title?: string;
      }
)[];

export type ExportOptions = {
    data: any[];
    fileName: string;
    keys?: (
        | string
        | {
              field: string;
              title?: string;
          }
    )[];
};

export const exportUtility = async ({ data, fileName = 'export', keys }: ExportOptions) => {
    if (!data?.length) return;

    const csvContent = await json2csv(data, { trimFieldValues: true, keys, delimiter: { wrap: '"' } });
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', `${fileName}-${format(new Date(), 'yyyy-MM-dd')}.csv`);
    a.click();
};
