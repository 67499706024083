import React from 'react';
import {Row, Column, ClickableTile} from '@carbon/react';

export const TFAListField = (props) => {
    // const redirect = (target) => {
    //   // axios.get('https://jsonplaceholder.typicode.com/todos/1').then(response => {
    //   //   //updateTFAMethod(() => JSON.stringify(target)); 
    //   //   //console.log(response.json());
    //   // })
    //   //console.log(response.json());
    //   console.log('Target: ', target);
    // }

    return (
        <>
          <ClickableTile href={props.value['target']}>
            <Row className='d-flex align-items-center text-start' >
                    <Column className={'pe-2'}>
                        {props.value['icon']}
                    </Column>
                    <Column>
                      <p >{props.value['description']}</p>
                    </Column>
            </Row>
          </ClickableTile>
        </>
      );
  }