import React, { useState, useEffect } from 'react';
import './Styles/AccountServices.scss';
import { useParams, Navigate } from 'react-router-dom';
import { OneTimeTokenLayout } from '../../utils';

export const UserReactivateAccount = () => {
    const params = useParams();

    const [token, setToken] = useState({ value: '' });
    const [response, setResponse] = useState('');

    useEffect(() => {
        (async () => {
            await global.GetCSRFToken();
            if (params.token && params.token !== ':token') {
                setToken({
                    value: params.token,
                    invalid: false,
                });
            }
            try {
                const res = await global.LibAxios.post(
                    '/api/userReactivateAccount',
                    {
                        userReactivationToken: params.token,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: true,
                        credentials: 'include',
                    }
                );
                setResponse(res.data.message);
            } catch (err) {
                setResponse(err.response.data.errorMessage);
            }
        })();
    }, []);

    return (
        <>
            <OneTimeTokenLayout type="userReactivation" homeButton="true" children={response} />
        </>
    );
};
