import { useEffect, useState, useReducer } from 'react';
import { QuickFilterItem } from '../quick-filter/QuickFilter';
import { useToggle, useCurrentPath } from '../../hooks';
import { PageModel } from './pageModel';
import { DATA } from './data';

export interface FilterByProps {
    key: string;
    name: string;
}

interface FilterOptions {
    [key: string]: QuickFilterItem[];
}

export const useFetchPageList = <T extends { key: string }>(props: { filterBy: T[]; rows: any[] }) => {
    const { filterBy, rows } = props;
    const filterOptions = filterBy.reduce(
        (a: any, filterType: any) => {
            return { ...a, [filterType.key]: new Set() };
        },
        { [filterBy[0].key]: new Set() }
    );

    rows?.length > 0 &&
        rows.forEach((row: any): any => {
            filterBy.forEach((filter: any) => {
                const key = filter.key;
                filterOptions[key].add(row[key]);
            });
        });

    //type <FilterOptions>({});

    const [pageListData, setPageListData] = useState<PageModel[]>([]);
    const [isLoading, toggleLoading] = useToggle(false);
    const { currentDataKey } = useCurrentPath();

    return {
        pageListData,
        isLoading,
        filterOptions,
    };
};
