import { useState } from 'react';

const calculateStartAndEnd = (page: number, pageSize: number) => {
    const mult = page - 1;
    const start = mult * pageSize;
    const end = start + pageSize;
    return { start, end } as const;
};

type PaginationOptions = {
    initialPage?: number;
    initialPageSize?: number;
};

type pageChangeParameters = { page: number; pageSize: number };

/**
 * In order for pagination to work use the rowPositions start and end to slice the rows to render
 * @param options used for initial values such as initial page and page size
 * @returns
 */
export const usePagination = (options: PaginationOptions = {}) => {
    const { initialPage = 1, initialPageSize = 100 } = options;

    const [page, setPage] = useState(initialPage);
    const [pageSize, setPageSize] = useState(initialPageSize);
    const [rowPositions, setRowPositions] = useState(calculateStartAndEnd(initialPage, initialPageSize));

    const handlePageChange = (change: pageChangeParameters) => {
        setPage(change.page);
        setPageSize(change.pageSize);
        setRowPositions(calculateStartAndEnd(change.page, change.pageSize));
    };

    const jumpToFirstPage = () => {
        setPage(initialPage);
        setRowPositions(calculateStartAndEnd(initialPage, pageSize));
    };

    return {
        page,
        pageSize,
        rowPositions,
        handlePageChange,
        jumpToFirstPage,
    };
};
