import {
    Row, Grid, Column, Tile
} from '@carbon/react';

export const TileGridLayout = ({tile, column, row, grid}) => (
    <Grid {...grid}>
            <Column {...column}>
                <Tile {...tile}/>
            </Column>
    </Grid>
)