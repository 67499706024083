import React from 'react';
import { CompareDecisionCalculiHeader } from './CompareDecisionCalculiHeader';
import { DecisionCalculi } from './DecisionCalculi';
import { Modal } from '@carbon/react';
import type { CompareDecisionCalculiProps } from './types';

export const CompareDecisionCalculi = ({ open, calculiSelections, onClose, onEdit }: CompareDecisionCalculiProps) => {
    return (
        <div className="compare-decision-calculi">
            <Modal
                passiveModal
                size="lg"
                open={open}
                onRequestClose={() => {
                    onClose(false);
                }}
                modalHeading={<CompareDecisionCalculiHeader />}
            >
                <div className="container">
                    <div className="row">
                        {calculiSelections.map((calculi, i) => (
                            <DecisionCalculi calculusId={calculi} key={i} onEdit={onEdit} />
                        ))}
                    </div>
                </div>
            </Modal>
        </div>
    );
};
