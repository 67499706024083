import { useState, useMemo } from 'react';

export function useSelect(defaultSelected = '') {
    const [selected, setSelected] = useState(defaultSelected);

    const handlers = useMemo(
        () => ({
            updateSelected: (rowId: string) => {
                setSelected(rowId);
            },
        }),
        []
    );

    return [selected, handlers] as const;
}

// export function use
