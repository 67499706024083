import axios from 'axios';
import { getCSRFToken } from './helpers';

declare let global: {
    LibAxios: any;
    GetCSRFToken: () => void;
};

global.LibAxios = axios;
global.GetCSRFToken = getCSRFToken;
