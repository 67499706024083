import {
    AccountServices,
    ForgotPasswordForm,
    ResetPasswordForm,
    TokenResetPasswordForm,
    CodeIntegrityTable,
    UploadDataDump,
    CreateDataDump,
    AccountManager,
    AuthenticationOptions,
    LoginSMS,
    LoadingPage,
    FileUploadTestPage,
    AccountProfile,
    FirstAdmin,
    DownloadRecoveryCodePage,
    TermsOfService,
    AdminActivateAccount,
    UserReactivateAccount,
    UserVerifyEmail,
    EmailTFA,
    RecoveryCodeForm,
} from '../pages';
import { AdminTools } from '../pages/admin-tools/AdminTools';
import { CalculusDecisionDetails } from '../pages/calculus/CalculusDecisionDetails/CalculusDecisionDetails';
import { DecisionCalculus } from '../pages/calculus/DecisionCalculus/DecisionCalculus';
import { Home } from '../pages/home/Home';
import { PerceptionDetails } from '../pages/perception/PerceptionDetails';
import { PerceptionList } from '../pages/perception/perception-list/PerceptionList';
import { ReportList } from '../pages/reports/ReportList';
import { SourceList } from '../pages/source/SourceList';

import { IconButtonUtil, CopyButtonUtil, SideNavUtil, DateField, ErrorPageLayout } from '../utils';

const pathType = {
    open: 'open',
    closed: 'closed',
};

export const demoPaths = {
    sideNavBar: {
        path: '/side-nav-bar',
        element: <SideNavUtil sideNavItems={{ children: <>test</> }} sideNavLink={{ children: <>test</> }} />,
        pathType: pathType.open,
        name: 'Test Navbar',
    },
    iconButton: {
        path: '/icon-button',
        element: <IconButtonUtil />,
        pathType: pathType.open,
        name: 'Icon Button',
    },
    copyButton: {
        path: '/copy-button',
        element: <CopyButtonUtil />,
        pathType: pathType.open,
        name: 'Copy Button',
    },
    fileUploadTestpage: {
        path: '/fileupload',
        element: <FileUploadTestPage />,
        pathType: pathType.open,
        name: 'File Upload',
    },
    LoadingPage: {
        path: '/loading-page',
        element: <LoadingPage />,
        pathType: pathType.open,
        name: 'Loading Page',
    },
    DateField: {
        path: '/date-field',
        element: (
            <>
                <DateField datePicker={{ dateFormat: 'y-m-d' }} datePickerType="range" />{' '}
                <DateField datePicker={{ dateFormat: 'd/m/Y' }} datePickerType="single" />
            </>
        ),
        pathType: pathType.open,
        name: 'Date Picker',
    },
};

export const paths = {
    accountServices: {
        path: '/account-services',
        element: <AccountServices />,
        pathType: pathType.open,
        name: 'Account Services',
        guardianPanel: true,
    },
    loginSMS: {
        path: '/login-sms',
        element: <LoginSMS />,
        pathType: pathType.open,
        name: 'Login SMS',
        guardianPanel: true,
    },
    authenticationOptions: {
        path: '/authentication-options',
        element: <AuthenticationOptions />,
        pathType: pathType.open,
        name: 'Select TFA Methods',
        guardianPanel: true,
    },
    emailAuthentication: {
        path: '/email-authentication',
        element: <EmailTFA />,
        pathType: pathType.open,
        name: 'EmailAuthentication',
        guardianPanel: true,
    },
    forgotPassword: {
        path: '/forgot-password',
        element: <ForgotPasswordForm />,
        pathType: pathType.open,
        name: 'Forgot Password',
        guardianPanel: true,
    },
    useRecoveryCode: {
        path: '/use-recovery-code',
        element: <RecoveryCodeForm />,
        pathType: pathType.open,
        name: 'Use Recovery Code',
        guardianPanel: true,
    },
    resetPasswordUsingCurrentPassword: {
        path: '/reset-password',
        element: <ResetPasswordForm />,
        pathType: pathType.closed,
        accountTypes: ['user', 'moderator', 'admin'],
        name: 'Reset Password After Login',
    },
    resetPasswordUsingURL: {
        path: '/reset-password/:token',
        element: <TokenResetPasswordForm />,
        pathType: pathType.open,
        name: 'Reset Password With Token',
    },
    accountProfile: {
        path: '/account-profile',
        element: <AccountProfile />,
        pathType: pathType.closed,
        accountTypes: ['user', 'moderator', 'admin'],
        name: 'Account Profile Page',
    },
    downloadRecoveryCode: {
        path: '/download-recovery-code',
        element: <DownloadRecoveryCodePage />,
        pathType: pathType.closed,
        accountTypes: ['user', 'admin', 'moderator'],
        name: 'Download Recovery Code',
    },
    firstAdminForm: {
        path: '/first-admin',
        element: <FirstAdmin />,
        pathType: pathType.open,
        name: 'First Admin',
    },
    accountManager: {
        path: '/account-manager/',
        element: <AccountManager />,
        pathType: pathType.open,
        accountTypes: ['admin'],
        name: 'Account Manager',
    },
    uploadDataDump: {
        path: '/upload-data-dump',
        element: <UploadDataDump />,
        pathType: pathType.closed,
        accountTypes: ['admin', 'moderator'],
        name: 'Upload Data Dump',
    },
    createDataDump: {
        path: '/create-data-dump',
        element: <CreateDataDump />,
        pathType: pathType.closed,
        accountTypes: ['admin', 'moderator'],
        name: 'Create Data Dump',
    },
    codeIntegrity: {
        path: '/code-integrity',
        element: <CodeIntegrityTable />,
        pathType: pathType.closed,
        accountTypes: ['admin', 'moderator'],
        name: 'CodeIntegrity',
    },
    tos: {
        path: '/tos',
        element: <TermsOfService />,
        pathType: pathType.open,
        name: 'Terms of Service',
    },
    adminActivateAccount: {
        path: '/admin-activate-account/:token',
        element: <AdminActivateAccount />,
        pathType: pathType.closed,
        accountTypes: ['user', 'admin'],
        name: 'AdminActivateAccount',
    },
    userReactivateAccount: {
        path: '/user-reactivate-account/:token',
        element: <UserReactivateAccount />,
        pathType: pathType.open,
        name: 'UserReactivateAccount',
    },
    userVerifyEmail: {
        path: '/user-verify-email/:token',
        element: <UserVerifyEmail />,
        pathType: pathType.open,
        name: 'UserVerifyEmail',
    },
    empty: {
        path: '*',
        element: <ErrorPageLayout type="404" />,
        pathType: pathType.open,
        name: 'empty',
    },
};

export const sadcPaths = [
    { path: 'calculi', element: <DecisionCalculus />, pathType: pathType.closed, accountTypes: ['user', 'moderator', 'admin'] },
    { path: 'calculi/:id', element: <CalculusDecisionDetails />, pathType: pathType.closed, accountTypes: ['user', 'moderator', 'admin'] },
    { path: 'perceptions', element: <PerceptionList />, pathType: pathType.closed, accountTypes: ['user', 'moderator', 'admin'] },
    { path: 'perceptions/:id', element: <PerceptionDetails />, pathType: pathType.closed, accountTypes: ['user', 'moderator', 'admin'] },
    { path: 'sources', element: <SourceList />, pathType: pathType.closed, accountTypes: ['user', 'moderator', 'admin'] },
    { path: 'reports', element: <ReportList />, pathType: pathType.closed, accountTypes: ['user', 'moderator', 'admin'] },
    { path: 'admin', element: <AdminTools />, pathType: pathType.closed, accountTypes: ['user', 'moderator', 'admin'] },
];
