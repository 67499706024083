import React from 'react';
import { TextInput } from '@carbon/react';
import { useCompareDecisionCalculiHeaderState } from './utils/useCompareDecisionCalculiHeaderState';

export const CompareDecisionCalculiHeader = () => {
    const {
        actorValue,
        strategicObjectiveValue,
        setActorValue,
        setStrategicObjectiveValue,
    } = useCompareDecisionCalculiHeaderState();
    return (
        <div className="compare-decision-calculi-header">
            <h4>Compare Decision Calculi</h4>
            <div className="inputs">
                <div className="text-label">Actor</div>
                <TextInput
                    id="actor-input"
                    labelText=""
                    size="sm"
                    value={actorValue}
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        setActorValue(e.currentTarget.value)
                    }
                ></TextInput>
            </div>
            <div className="inputs">
                <div className="text-label">Strategic Objective</div>
                <TextInput
                    id="strategic-object-input"
                    labelText=""
                    size="sm"
                    value={strategicObjectiveValue}
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        setStrategicObjectiveValue(e.currentTarget.value)
                    }
                ></TextInput>
            </div>
        </div>
    );
};
