import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useSetAtom, useAtom } from 'jotai';

import { useToggle } from '../../../../hooks';
import { useCRUDApi } from '../../../../hooks/useCRUDApi';
import { useStateObject } from '../../../../hooks/useStateObject';

import type {
    CalculusDecisionDataTables,
    CalculusDecisionDetailsOptions,
    EditRow,
    DeleteRow,
    modifyRowOptions,
    CalculusDetailsApiData,
    PerceptionIdHashSet,
} from './types';
import {
    addNewCalculusDetailsAtom,
    addPerceptionConnectionAtom,
    perceptionConnectionsAtom,
    updateCalculusPerceptionIdSetAtom,
} from './calculusDecisionDetailsAtoms';
import { PerceptionType } from '../../../../commonTypes/perceptionApiTypes';
import { ScenarioInputData } from '../../../scenario/utils/types';
import { useDecisionCalculus } from '../../DecisionCalculus/utils/useDecicisionCalculusHooks';
import { CalculusInputData } from '../../../../modals/calculusInputs/types';
import { useScenarios } from '../../../scenario/utils/useFetchScenarios';
import { mapPerceptionData } from './mapPerceptionData';

const initialDataTable = {
    costOfAction: {
        entries: [],
        ranking: 0,
    },
    benefitsOfAction: {
        entries: [],
        ranking: 0,
    },
    benefitsOfRestraint: {
        entries: [],
        ranking: 0,
    },
    costsOfRestraint: {
        entries: [],
        ranking: 0,
    },
};

export const useFetchCalculusDecisionDetails = (options: CalculusDecisionDetailsOptions) => {
    const { calculusId } = options;
    const [dataTables, setDataTables] = useStateObject<CalculusDecisionDataTables>(initialDataTable);
    const updatePerceptionIdSet = useSetAtom(updateCalculusPerceptionIdSetAtom);
    const [overallSummary, setOverallSummary] = useState<CalculusDetailsApiData['summary']>(null);
    const [calculusDetails, setCalculusDetails] = useState<CalculusDetailsApiData | undefined>();
    const addNewCalculusDetails = useSetAtom(addNewCalculusDetailsAtom);
    const updatePerceptionConnections = useSetAtom(addPerceptionConnectionAtom);

    const { loading, callApi } = useCRUDApi('/api/v1');

    const { updateScenario, scenarios, fetchScenarios, createNewScenario } = useScenarios();

    const { updateCalculus } = useDecisionCalculus();

    const fetchCalculiPage = useCallback(async () => {
        const data: CalculusDetailsApiData = await callApi(`/calculi/${calculusId}`, 'get');
        addNewCalculusDetails({ [data.calculus_id]: data });
        const { perceptionConnections, ...rest } = mapPerceptionData(data.perceptions);
        updatePerceptionConnections(perceptionConnections);
        const obj: PerceptionIdHashSet = {};
        Object.values(rest).forEach(({ entries }) =>
            entries.forEach((perception) => {
                obj[perception.id] = true;
            })
        );
        updatePerceptionIdSet({ [calculusId]: obj });
        setDataTables({ ...rest });
        setOverallSummary(data.summary);
        // const calculusDetailsData = data.find((item) => item.id === calculusId);
        setCalculusDetails(data || {});
    }, [addNewCalculusDetails, calculusId, callApi, setDataTables, updatePerceptionConnections, updatePerceptionIdSet]);

    const deletePerceptionConnection = async (connection_id: string) => {
        try {
            const data = await callApi(`/connections/${connection_id}`, 'delete');
            return {
                isOk: true,
                error: null,
            } as const;
        } catch (error) {
            console.error(error);
            return {
                isOk: false,
                error,
            } as const;
        }
    };

    const scenarioData = scenarios.find((scenario) => scenario.id === calculusDetails?.scenario.id);

    const handleUpdateCalculus = useCallback(
        async (calculus: CalculusInputData) => {
            await updateCalculus(calculusId, calculus);
            fetchCalculiPage();
            fetchScenarios();
        },
        [calculusId, updateCalculus, fetchCalculiPage, fetchScenarios]
    );

    const handleUpdateScenario = async (scenario: ScenarioInputData, options: { cloneScenario?: boolean } = {}) => {
        const scenarioId = calculusDetails?.scenario.id ?? '';
        const { cloneScenario = false } = options;
        if (cloneScenario) {
            const response = await createNewScenario({ ...scenario, name: `${calculusId.slice(0, 5)} - ${scenario.name}` });
            if (response.isOk) {
                handleUpdateCalculus({
                    name: calculusDetails?.name ?? '',
                    entity: calculusDetails?.entity?.id ?? '',
                    createdBy: '',
                    behavior: calculusDetails?.behavior?.id ?? '',
                    summary: calculusDetails?.summary ?? '',
                    objective: calculusDetails?.objective?.id ?? '',
                    scenario: response.scenarioId,
                });
            }
        } else {
            await updateScenario(scenarioId, scenario);
        }
        fetchScenarios();
        fetchCalculiPage();
    };

    useEffect(() => {
        fetchCalculiPage();
    }, [fetchCalculiPage]);

    return {
        ...dataTables,
        calculusDetails,
        fetchCalculiPage,
        setDataTables,
        overallSummary,
        scenarioData,
        handleUpdateScenario,
        handleUpdateCalculus,
        deletePerceptionConnection,
        isLoading: loading,
    } as const;
};

export const useCalculusDecisionState = () => {
    const [isEditScenarioOpen, toggleIsEditScenarioOpen] = useToggle(false);
    const [isEditCalculusOpen, toggleIsEditCalculusOpen] = useToggle(false);
    const [addPerception, setAddPerception] = useState<PerceptionType | null>(null);
    const [perceptionType, setPerceptionType] = useState<'benefit-of-restraint' | 'cost-of-restraint' | 'benefit-of-action' | 'cost-of-action' | null>(null);
    const [editRow, setEditRow] = useState<EditRow | null>(null);
    const [deleteRow, setDeleteRow] = useState<DeleteRow | null>(null);
    const [perceptionConnections] = useAtom(perceptionConnectionsAtom);

    // Handlers
    const handlers = useMemo(
        () => ({
            handleOpenEditScenario: (e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                e.stopPropagation();
                toggleIsEditScenarioOpen();
            },
            handleCloseEditScenario: () => toggleIsEditScenarioOpen(false),
            handleOpenEditCalculus: (e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                e.stopPropagation();
                toggleIsEditCalculusOpen();
            },
            handleCloseEditCalculus: () => toggleIsEditCalculusOpen(false),
            handleOpenPerceptionModal: (e: React.MouseEvent<HTMLButtonElement>, { table }: any) => {
                e.stopPropagation();
                e.preventDefault();
                setPerceptionType(table);
                setAddPerception(table);
            },
            handleClosePerceptionModal: () => setAddPerception(null),
        }),
        [toggleIsEditCalculusOpen, toggleIsEditScenarioOpen]
    );

    // Handlers that require state
    const handleDeleteRow = (options: modifyRowOptions | null = null) => {
        if (!options) {
            setDeleteRow(null);
            return;
        }
        const { table, row, rowIndex } = options;
        const rowToDelete: DeleteRow = {
            id: row.id,
            connectionId: perceptionConnections[row.id] ?? null,
        };
        row.cells.forEach((cell: any) => {
            switch (cell.info.header) {
                case 'perceptionName':
                    rowToDelete.name = cell.value;
                    break;
                default:
                    break;
            }
        });

        setDeleteRow(rowToDelete);
    };
    const handleEditRow = (options: modifyRowOptions | null = null) => {
        if (!options) {
            setEditRow(null);
            return;
        }
        const { table, row, rowIndex } = options;
        const rowToEdit: EditRow = {
            perception: '',
            value: 0,
            probability: 0,
            table,
            preparedBy: '',
            rowIndex: rowIndex,
            perceptionId: row.id,
            connectionId: perceptionConnections[row.id] ?? '',
        };
        setPerceptionType(table as any);
        row.cells.forEach((cell: any) => {
            switch (cell.info.header) {
                case 'perceptionName':
                    rowToEdit.perception = cell.value;
                    break;
                case 'value':
                    rowToEdit.value = cell.value;
                    break;
                case 'probability':
                    rowToEdit.probability = cell.value;
                    break;
                case 'preparedBy':
                    rowToEdit.preparedBy = cell.value;
                    break;
                default:
                    break;
            }
        });
        setEditRow(rowToEdit);
    };

    return {
        isEditScenarioOpen,
        isEditCalculusOpen,
        addPerception,
        perceptionType,
        editRow,
        deleteRow,
        handleEditRow,
        handleDeleteRow,
        ...handlers,
    };
};

/**
 * Hook that stores calculus ids in session storage and returns the previous id
 */
export const usePreviousCalculusId = (currentId: string) => {
    const prevIdRef = useRef<null | string>(null);

    useEffect(() => {
        const currId = window.sessionStorage.getItem('currCalculusId') ?? '';
        let prevId = window.sessionStorage.getItem('prevCalculusId') ?? '';

        if (currId !== currentId && currentId) {
            window.sessionStorage.setItem('currCalculusId', currentId);
            window.sessionStorage.setItem('prevCalculusId', currId);
            prevId = currId;
        }

        prevIdRef.current = prevId;
    }, [currentId]);

    return prevIdRef.current;
};

export type clearPreviousCalculusOptions = {
    enabled?: boolean;
};
/**
 * Clears calculus ids from sessionStorage
 */
export const useClearPreviousCalculusId = (options: clearPreviousCalculusOptions = {}) => {
    const { enabled = false } = options;

    useEffect(() => {
        if (enabled) {
            window.sessionStorage.removeItem('currCalculusId');
            window.sessionStorage.removeItem('prevCalculusId');
        }
    }, [enabled]);
};
