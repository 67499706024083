import { useState } from 'react';

export const useCompareDecisionCalculiHeaderState = () => {
    const [actorValue, setActorValue] = useState('');
    const [strategicObjectiveValue, setStrategicObjectiveValue] = useState('');

    return {
        actorValue,
        strategicObjectiveValue,
        setActorValue,
        setStrategicObjectiveValue,
    };
};
