import { useEffect, useRef } from 'react';

/**
 * Used for as an onMount for modals if they're not conditionally rendered
 * @param open if true runs a use effect similar to an onMount hook
 * @param callbackFunc is passed to a reference so does not need to be memoized/useCallbacked
 */
export const useCarbonModalOpened = (open: boolean, callbackFunc: () => void) => {
    const cbRef = useRef(callbackFunc);

    cbRef.current = callbackFunc;

    useEffect(() => {
        if (open) {
            cbRef.current?.();
        }
    }, [open]);
};
