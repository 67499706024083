import React from 'react';
import { Button } from '@carbon/react';
import { CarbonIconType } from '@carbon/icons-react';

interface PageListHeaderProps {
    header: string;
    buttons: { title: string; icon: CarbonIconType }[];
}

export const PageListHeader = (props: PageListHeaderProps) => {
    const { header, buttons } = props;
    const buttonsElem = buttons.map((button, i) => (
        <div key={i}>
            <Button kind={'tertiary'} renderIcon={button.icon}>
                {button['title']}
            </Button>
        </div>
    ));
    return (
        <div className="page-list-header">
            <div>
                <h3>{header}</h3>
            </div>
            <div className="buttons-container">{buttonsElem}</div>
        </div>
    );
};
