import { useState, useMemo } from 'react';

export const useCounter = (initialValue = 0) => {
    const [count, setCount] = useState(initialValue);

    const handlers = useMemo(
        () => ({
            increment: () => setCount((prevCount) => prevCount + 1),
            decrement: () => setCount((prevCount) => prevCount - 1),
            reset: () => setCount(initialValue),
        }),
        [initialValue]
    );

    return {
        ...handlers,
        count,
        setCount,
    } as const;
};
