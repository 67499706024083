import React from 'react';
import { ComposedModal, ModalHeader, ModalBody, ModalFooter, Button, TextInput, TextArea } from '@carbon/react';

import { invalidNameEnum, useScenarioModalState } from './utils/scenarioModalHooks';
import { DecisionCalculiTable } from './DecisionCalculiTable';

import { useCarbonModalOpened, useCounter, useToggle } from '../../hooks';

import './ScenarioModal.scss';
import { ScenarioModalProps } from './types';
import { SelectCalcScenarioWrapper } from '../selectionDialog/SelectCalcScenarioWrapper';
import { useScenarios } from '../../pages/scenario/utils/useFetchScenarios';
import { useNavigate } from 'react-router-dom';

export const ScenarioModal = ({ open, onClose, onSubmit, initialScenario }: ScenarioModalProps) => {
    const navigate = useNavigate();
    const {
        name,
        objectiveSummary,
        adversaryDecision,
        description,
        newScenario,
        scenarioDetails,
        loading,
        nameInvalid,
        handleScenarioNameChange,
        handleStrategicSummaryChange,
        handleAdversaryDecisionChange,
        handleScenarioDescriptionChange,
        insertScenarioState,
        initNewScenario,
        setNameInvalid,
    } = useScenarioModalState(initialScenario);
    const [openPrefill, toggleOpenPrefill] = useToggle(false);
    const { count: insertCount, increment: incrementInsertCount } = useCounter();
    const isEdit = initialScenario !== undefined;

    const { scenarios } = useScenarios();

    useCarbonModalOpened(open, () => {
        if (initialScenario) {
            insertScenarioState(initialScenario);
        } else {
            initNewScenario();
        }
        incrementInsertCount();
    });

    const handleSaveAndSubmit = () => {
        const scenarioNames = scenarios.map((scenario) => scenario.name);
        const nameExists = isEdit
            ? scenarioNames.filter((name) => name !== initialScenario.name).includes(newScenario.name.trim())
            : scenarioNames.includes(newScenario.name.trim());
        if (newScenario.name.trim() === '') {
            setNameInvalid(invalidNameEnum.emptyName);
            return;
        }
        if (nameExists) {
            setNameInvalid(invalidNameEnum.duplicate);
            return;
        }
        let cloneScenario = false;
        if (isEdit) {
            const scenarioUpdated =
                name !== initialScenario.name ||
                objectiveSummary !== initialScenario.objectiveSummary ||
                adversaryDecision !== initialScenario.adversaryDecision;
            const totalOfConnectedCalculi = scenarioDetails?.calculus.rows.length ?? 0;
            if (scenarioUpdated && totalOfConnectedCalculi > 1) {
                cloneScenario = true;
            }
        }

        onSubmit(newScenario, { cloneScenario });
        onClose();
    };

    const onView = (id: string) => {
        navigate(`/calculi/${id}`);
        onClose();
    };

    if (openPrefill) {
        return (
            <SelectCalcScenarioWrapper
                open={openPrefill}
                onClose={toggleOpenPrefill}
                onSubmit={(calc) => {
                    const scenario = scenarios.find((scenario) => scenario.name === calc?.scenario);
                    insertScenarioState({
                        name: scenario?.name ?? '',
                        description: scenario?.description ?? '',
                        objectiveSummary: scenario?.objectiveSummary ?? '',
                        adversaryDecision: scenario?.adversaryDecision ?? '',
                    });
                    toggleOpenPrefill(false);
                }}
            />
        );
    }

    return (
        <ComposedModal open={open} size="lg" className="scenario-modal" onClose={onClose}>
            <ModalHeader title={isEdit ? 'Edit Scenario' : 'New Scenario'} className="d-flex align-items-center">
                {!isEdit && (
                    <Button className="ms-auto" onClick={toggleOpenPrefill} size="sm" kind="tertiary">
                        Prefill Scenario From Calculus
                    </Button>
                )}
            </ModalHeader>
            <ModalBody>
                <div className="scenario-modal-body">
                    <div className="mb-3 scenario-name">
                        <TextInput
                            id="scenario-name-input"
                            key={`scenario-name-input-${insertCount}`}
                            className="scenario-name-input"
                            labelText="Scenario Name"
                            placeholder="Scenario name"
                            value={name}
                            invalid={nameInvalid !== invalidNameEnum.none}
                            invalidText={nameInvalid === invalidNameEnum.duplicate ? 'Name must be unique' : 'Please enter a valid name.'}
                            onChange={handleScenarioNameChange}
                        />
                    </div>
                    <div className="row mt-3 mb-3">
                        <div className="col-md-6">
                            <TextArea
                                id="strategic-objective-summary"
                                key={`strategic-objective-summary-${insertCount}`}
                                className="mb-2 strategic-objective-summary"
                                labelText="Strategic Objective Summary"
                                maxCount={1000}
                                enableCounter
                                value={objectiveSummary}
                                onChange={handleStrategicSummaryChange}
                            />
                            <TextArea
                                id="adversay-decision"
                                key={`adversay-decision-${insertCount}`}
                                className="adversay-decision"
                                labelText="Adversary Decision"
                                maxCount={1000}
                                enableCounter
                                value={adversaryDecision}
                                onChange={handleAdversaryDecisionChange}
                            />
                        </div>
                        <div className="col-md-6 scenario-description">
                            <TextArea
                                id="scenario-description"
                                key={`scenario-description-${insertCount}`}
                                className="scenario-description-textarea"
                                labelText="Scenario Description"
                                maxCount={2000}
                                enableCounter
                                value={description}
                                onChange={handleScenarioDescriptionChange}
                            />
                        </div>
                    </div>
                    {initialScenario && (
                        <div className="row">
                            <div className="col-md-10">
                                <DecisionCalculiTable id="decision-calculi-table" tableData={scenarioDetails} isLoading={loading} onView={onView} />
                            </div>
                        </div>
                    )}
                </div>
            </ModalBody>
            <ModalFooter
                primaryButtonDisabled={false}
                primaryButtonText={isEdit ? 'Update Scenario' : 'Create Scenario'}
                secondaryButtonText="Cancel"
                onRequestSubmit={handleSaveAndSubmit}
            />
        </ComposedModal>
    );
};
