import React, { ReactNode } from 'react';
import { Breadcrumb, BreadcrumbItem } from '@carbon/react';
import './Header.scss';
import { useNavigate } from 'react-router';

export interface IBreadcrumItem {
    label: string;
    url?: string;
}

type Props = {
    title: string | ReactNode;
    breadcrumbs?: IBreadcrumItem[];
    children?: ReactNode;
};

export const Header = ({ title, breadcrumbs, children }: Props) => {
    const navigate = useNavigate();

    const onNavigate = (e: React.MouseEvent, url: string) => {
        e.preventDefault();
        navigate(url);
    };

    return (
        <div className="page-header">
            <div>
                <div>
                    <Breadcrumb noTrailingSlash>
                        <BreadcrumbItem href="/" onClick={(e: React.MouseEvent) => onNavigate(e, '/')}>
                            Home
                        </BreadcrumbItem>
                        {(breadcrumbs || []).map((b) => {
                            if (b.url) {
                                return (
                                    <BreadcrumbItem href={b.url} key={b.label} onClick={(e: React.MouseEvent) => onNavigate(e, b.url + '')}>
                                        {b.label}
                                    </BreadcrumbItem>
                                );
                            } else {
                                return (
                                    <BreadcrumbItem isCurrentPage key={b.label}>
                                        {b.label}
                                    </BreadcrumbItem>
                                );
                            }
                        })}
                    </Breadcrumb>
                </div>
                <h1>{title}</h1>
            </div>
            {children && <div className="actions">{children}</div>}
        </div>
    );
};
