import {
    TableContainer,
    TableToolbar,
    DataTable,
    Table,
    TableHead,
    TableHeader,
    TableRow,
    TableBody,
    TableCell,
    TableSelectRow,
    Pagination,
    TableToolbarContent,
    TableToolbarSearch,
} from '@carbon/react';
import { usePagination, useFilteredData } from '../../hooks';
import { SelectionTableProps } from './types';

export const SelectionTable = ({ headers, data, selected, onSelectedChange }: SelectionTableProps) => {
    const { page, pageSize, rowPositions, handlePageChange } = usePagination();
    const { filters, setFilters, filteredData } = useFilteredData(data);

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilters({ ...filters, all: e.target.value.trim() });
    };

    return (
        <div>
            <DataTable rows={filteredData} headers={headers} radio isSortable>
                {({ rows, headers, getTableProps, getHeaderProps, getRowProps }: any) => {
                    return (
                        <TableContainer>
                            <TableToolbar>
                                <TableToolbarContent>
                                    <TableToolbarSearch onChange={onSearch} persistent />
                                </TableToolbarContent>
                            </TableToolbar>
                            <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        <th scope="col" />
                                        {headers.map((header: { key: string; header: string }) => {
                                            return (
                                                <TableHeader key={header.key} {...getHeaderProps({ header })} className={'cell-' + header.key}>
                                                    {header.header}
                                                </TableHeader>
                                            );
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.slice(rowPositions.start, rowPositions.end).map((row: { cells: any[]; id: string }) => {
                                        return (
                                            <TableRow key={row.id} {...getRowProps({ row })}>
                                                <TableSelectRow
                                                    onSelect={() => onSelectedChange(row.id)}
                                                    checked={selected === row.id}
                                                    radio
                                                    ariaLabel=""
                                                    id={row.id}
                                                    name="selection row"
                                                />
                                                {row.cells.map((cell) => {
                                                    return <TableCell key={cell.id}>{cell.value}</TableCell>;
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    );
                }}
            </DataTable>
            <Pagination
                id="selection-pagination"
                page={page}
                pageSize={pageSize}
                pageSizes={[10, 25, 50, 100, 500]}
                totalItems={data.length}
                onChange={handlePageChange}
            />
        </div>
    );
};
