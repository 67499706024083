import React, {useState} from 'react';
import { TextInput } from '@carbon/react';
import { InputValidator } from '../../helpers';

export const EmailField = ({ 
    textInput = {}, 
    type = 'emailBasic', 
    setState = () => {},
    validation = false
}) => {
    const [invalidText, setInvalidText] = useState('Enter a valid email.')

    const handleChange = (e) => {
        const typedEmail = e.target.value
        if (validation) {
            const conditions = InputValidator({ type: type, value: typedEmail })
            if (typedEmail.length < 91) {
                let valid = true
                valid = conditions.allConditionsMet || typedEmail === ''

                if (conditions.lengthMet === false) {
                    setInvalidText('Use less than 80 characters to type your email.')
                }
                else if (conditions.basicConditionsMet === false){
                    setInvalidText('Enter a valid email.')
                }

                else if (conditions.hasValidPrefix === false){
                    setInvalidText('Enter a valid email prefix.')
                }
                else if (conditions.domainApproved === false) {
                    setInvalidText('Use a valid email with an approved domain.')
                }
                setState({ 
                    value:typedEmail, 
                    invalid:!valid 
                } ) 
            }
        }
    
        else {
            setState({
                value:e.target.value
            })
        }
    }

    const textInputProps = { 
        id: `${type}Email${Math.random().toString()}`,
        labelText: 'Email',
        // hideLabel: true,
        invalidText: invalidText,
        //className: 'my-3',
        onChange: (e) => handleChange(e), 
        ...textInput
    }

    return  <TextInput {...textInputProps} /> 
}