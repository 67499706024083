import { IconButton } from '@carbon/react';
import { Asleep, AsleepFilled } from '@carbon/react/icons';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
export const CUSTOM_THEME = {
    LIGHT: 'light',
    DARK: 'dark',
};

export const CARBON_THEME = {
    WHITE: 'white',
    G10: 'g10',
    G90: 'g90',
    G100: 'g100',
};

type ThemeButtonProps = {
    changeTheme: (theme: string) => void;
    carbonTheme: string;
};

export const ThemeButton = ({ changeTheme, carbonTheme }: ThemeButtonProps) => {
    // store theme in localstorage when it is changed
    useEffect(() => {
        localStorage.setItem('theme', carbonTheme);
    }, [carbonTheme]);

    return (
        <>
            <IconButton
                className="theme-button"
                label="Switch Theme"
                align="left"
                enterDelayMs={1000}
                onClick={() => changeTheme(carbonTheme === CARBON_THEME.G100 ? CARBON_THEME.WHITE : CARBON_THEME.G100)}
                kind={carbonTheme === CARBON_THEME.WHITE ? 'secondary' : 'tertiary'}
            >
                {carbonTheme === CARBON_THEME.G100 ? <AsleepFilled /> : <Asleep />}
            </IconButton>
        </>
    );
};

ThemeButton.propTypes = {
    changeTheme: PropTypes.func,
    carbonTheme: PropTypes.string,
};
