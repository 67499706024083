import React, { useState } from 'react';
import { useDecisionCalculus } from '../../../pages/calculus/DecisionCalculus/utils/useDecicisionCalculusHooks';
import { Scenario } from '../../../pages/scenario/utils/types';
import { DropdownEvent } from '../types';
import { useStateObject } from '../../../hooks/useStateObject';
import { EntityModel } from '../../entities/EntityModel';
import { ObjectiveModel } from '../../objectives/ObjectiveModel';
import { BehaviorModel } from '../../behaviors/BehaviorModel';
import { ScenarioInputData } from '../../Scenario/types';
import { useScenarios } from '../../../pages/scenario/utils/useFetchScenarios';

const emptyCalculus = {
    id: '',
    name: '',
    summary: '',
    entity: null,
    objective: null,
    scenario: null,
    behavior: null,
    createdBy: '',
};

export type CalculusInputsState = {
    id: string;
    name: string;
    summary: string;
    entity: EntityModel | null;
    objective: ObjectiveModel | null;
    scenario: Scenario | null;
    behavior: BehaviorModel | null;
    createdBy: string;
};

// Valdiation step
const requiredDropdowns = ['entity', 'objective', 'scenario', 'behavior'] as const;

export const useCalculusInputsState = (calculus: Partial<CalculusInputsState> = emptyCalculus) => {
    const { calculi, setPrefillCalculus } = useDecisionCalculus();
    const calculiNames = calculi.map((calculus) => calculus.name);
    const [calculusToEdit, setCalculusToEdit] = useState<string>('');
    const [scenarioModalOpen, setScenarioModalOpen] = useState<boolean>(false);

    const { createNewScenario } = useScenarios();

    const validateState = (state: CalculusInputsState) => {
        const nameExists =
            calculusToEdit.length > 0 ? calculiNames.filter((name) => name !== calculusToEdit).includes(state.name.trim()) : calculiNames.includes(state.name.trim());
        if (typeof state.name !== 'string' || state.name.length === 0 || nameExists) return false;

        return requiredDropdowns.every((field) => state[field] !== null && typeof state[field] !== 'string');
    };

    const [selectedCalculus, setSelectedCalculus] = useStateObject<CalculusInputsState>({ ...emptyCalculus, ...calculus }, validateState);

    const [invalidInputs, setInvalidInputs] = useStateObject({ entity: false, objective: false, behavior: false, scenario: false, name: false });

    const nameExists =
        calculusToEdit.length > 0
            ? calculiNames.filter((name) => name !== calculusToEdit).includes(selectedCalculus.name.trim())
            : calculiNames.includes(selectedCalculus.name.trim());

    const handlers = {
        handleCalculusNameChange(e: React.ChangeEvent<HTMLInputElement>) {
            setSelectedCalculus({ name: e.target.value });
            setInvalidInputs({ name: false });
        },
        handleCalculusSummaryChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
            setSelectedCalculus({ summary: e.target.value });
        },
        handleEntityChange(e: DropdownEvent<EntityModel>) {
            setSelectedCalculus({ entity: e.selectedItem });
            setInvalidInputs({ entity: false });
        },
        handleObjectiveChange(e: DropdownEvent<ObjectiveModel>) {
            setSelectedCalculus({ objective: e.selectedItem });
            setInvalidInputs({ objective: false });
        },
        handleScenarioChange(e: DropdownEvent<Scenario>) {
            setSelectedCalculus({ scenario: e.selectedItem });
            setInvalidInputs({ scenario: false });
        },
        handleBehaviorChange(e: DropdownEvent<BehaviorModel>) {
            setSelectedCalculus({ behavior: e.selectedItem });
            setInvalidInputs({ behavior: false });
        },
        initNewCalculus() {
            setSelectedCalculus(emptyCalculus);
        },
        findInvalidInputs() {
            const nameIsInvalid = typeof selectedCalculus.name !== 'string' || selectedCalculus.name.length === 0 || nameExists;
            const object: { [key: string]: boolean } = {};
            requiredDropdowns.forEach((dropdown) => {
                object[dropdown] = selectedCalculus[dropdown] === null;
            });
            setInvalidInputs({ name: nameIsInvalid, ...object });
        },
    };

    const handleCreateNewScenario = (newScenario: ScenarioInputData) => {
        createNewScenario(newScenario);
        setScenarioModalOpen(false);
    };

    return {
        selectedCalculus,
        scenarioModalOpen,
        setSelectedCalculus,
        setCalculusToEdit,
        setScenarioModalOpen,
        setPrefillCalculus,
        handleCreateNewScenario,
        invalidInputs,
        ...handlers,
    };
};
