import React from 'react';
import { Link } from 'react-router-dom';
import { paths, demoPaths } from '../config';
import './LinkUtil.scss';

export const LinkUtil = React.forwardRef( ({ link={}, pathKey='empty' }, ref) => {
    const allPaths = {...paths, ...demoPaths}
    const linkClassName = link.className === undefined ? '' : ` ${link.className}`

    const linkProps={
        children: 'Link', 
        to: allPaths[pathKey]['path'],
        ...link, 
        className: `link${linkClassName}`
    }

    return <Link {...linkProps}/>
})