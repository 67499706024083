import {
    Modal,
    TextInput,
    Dropdown,
    TextArea,
    DataTable,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    Button,
    Loading,
} from '@carbon/react';
import { useEffect } from 'react';
import { GenerateReportProps } from './types';
import './GenerateReport.scss';
import { useReport } from './utils/useGenerateReport';
import { ReportTypeListItem } from '../../pages/reports/utils/types';
import { useReportList } from '../../pages/reports/utils/reportListHooks';
import { REPORT_TYPE_LIST } from '../../pages/reports/utils/ReportModel';

const headers = [
    { key: 'name', header: 'Name' },
    { key: 'dc_value', header: 'DC Value' },
];

export const GenerateReport = ({ open, onClose, calcRows, addCalc, report, onSubmit, setCalcRows }: GenerateReportProps) => {
    const {
        callApi,
        error,
        handlers: { updateTitle, updateType, updateSummary },
        loading,
        titleInvalid,
        descInvalid,
        selectedReport,
        setTitleInvalid,
        setDescInvalid,
    } = useReport(report);
    const isEdit = report !== undefined;

    const { tableData, fetchReportsList } = useReportList();

    useEffect(() => {
        fetchReportsList();
    }, [fetchReportsList]);

    const onSave = async () => {
        const { name, description, type, calculus_ids } = selectedReport;
        const newCalculus_ids: string[] = [...calculus_ids];
        calcRows?.forEach((calc) => {
            newCalculus_ids.push(calc.id);
        });
        const reportNames = tableData.map((report) => report.name);
        const nameExists = isEdit
            ? reportNames.filter((name) => name !== report.name).includes(selectedReport.name)
            : reportNames.includes(selectedReport.name);

        const checkInvalid = () => {
            let invalidFound = false;
            if (name.trim() === '' || nameExists) {
                setTitleInvalid(true);
                invalidFound = true;
            }
            if (!description || description.length <= 0) {
                setDescInvalid(true);
                invalidFound = true;
            }
            return invalidFound;
        };

        if (checkInvalid()) {
            return;
        }

        let resp;
        if (selectedReport.id) {
            resp = await callApi('/reports/' + selectedReport.id, 'PUT', { name, description, type, calculus_ids: newCalculus_ids });
        } else {
            resp = await callApi('/reports', 'POST', { name, description, type, calculus_ids: newCalculus_ids });
        }

        onSubmit?.(resp);
        if (resp) {
            setCalcRows([]);
            onClose();
        } else {
            console.error(error);
        }
    };

    return (
        <Modal
            className="generate-report"
            modalHeading={isEdit ? 'Edit Report' : 'New Report'}
            primaryButtonText={isEdit ? 'Update Report' : 'Create Report'}
            secondaryButtonText="Cancel"
            open={open}
            onRequestClose={() => {
                onClose();
                setCalcRows([]);
            }}
            onRequestSubmit={onSave}
        >
            {loading ? <Loading /> : null}
            <div className="top-report-input-container mb-3">
                <TextInput
                    labelText="Report Title"
                    id="report-title-input"
                    invalid={titleInvalid}
                    invalidText={selectedReport.name === '' ? 'Enter report title' : 'Report already exists, please enter a different title'}
                    value={selectedReport.name}
                    onChange={updateTitle}
                />
                <Dropdown
                    titleText="Report Type"
                    id="report-type-dropdown"
                    label="Report Type"
                    items={REPORT_TYPE_LIST}
                    itemToString={(item: ReportTypeListItem) => (item ? item.text : '')}
                    initialSelectedItem={REPORT_TYPE_LIST.find((t) => t.id === selectedReport.type)}
                    onChange={updateType}
                />
            </div>
            <TextArea
                labelText="Report Summary"
                value={selectedReport.description}
                onChange={updateSummary}
                invalid={descInvalid}
                invalidText="Summary is required"
                maxCount={1000}
                enableCounter
            />
            <DataTable rows={calcRows} headers={headers} isSortable>
                {({ rows, headers, getHeaderProps, getRowProps }: any) => {
                    return (
                        <div>
                            <Button className="add-calculus-button" onClick={() => addCalc(true)} kind="ghost">
                                + Add
                            </Button>
                            <TableContainer title="Select Calculus for Report">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {headers.map((header: { key: string; header: string }) => {
                                                return (
                                                    <TableHeader key={header.key} {...getHeaderProps({ header })} className={'cell-' + header.key}>
                                                        {header.header}
                                                    </TableHeader>
                                                );
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row: { cells: any[]; id: string }) => {
                                            return (
                                                <TableRow key={row.id} {...getRowProps({ row })}>
                                                    {row.cells.map((cell) => {
                                                        return <TableCell key={cell.id}>{cell.value}</TableCell>;
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    );
                }}
            </DataTable>
        </Modal>
    );
};
