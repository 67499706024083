import { Button, DatePicker, DatePickerInput, IconButton } from '@carbon/react';
import React, { useEffect, useState } from 'react';
import { Datatable, handleTableSubmitLayout, refreshTable } from '../utils/Datatable';
import { TrashCan } from '@carbon/icons-react';
import { Modal } from '../utils/Modal';
import { PostResponseNotification, SelectUtil } from '../utils';

export const AccountManager = () => {
    const headers = [
        {
            header: 'ID', // Display on UI table header
            key: 'id', // id to link row with header
            format: (row) => {
                return JSON.stringify(row.userID);
            }, // format of how the column should display
        },
        {
            header: 'Email',
            key: 'email',
            format: (row) => {
                return row.email;
            },
        },
        {
            header: 'Status',
            key: 'status',
            format: (row) => {
                return row.status;
            },
        },
        {
            header: 'Account Type',
            key: 'accountType',
            format: (row) => {
                return row.accountType;
            },
        },
        {
            header: 'Expired Date',
            key: 'expiredDate',
            format: (row) => {
                return row.adminExpiry;
            },
        },
        {
            header: '',
            key: 'submit',
            format: (row) => {
                let btnProps = {
                    children: '',
                    onClick: '',
                    className: 'w-100',
                    kind: 'ghost',
                };

                if (row.status === 'registrationPending') {
                    btnProps.children = 'Register';
                    btnProps.onClick = () => {
                        handleClick(row, 'register');
                    };
                } else {
                    btnProps.children = 'Edit';
                    btnProps.onClick = () => {
                        handleClick(row, 'edit');
                    };
                }

                return <Button {...btnProps} />;
            },
        },
        {
            header: '',
            key: 'delete',
            format: (row) => {
                return (
                    <IconButton
                        label="Delete"
                        kind="ghost"
                        children={<TrashCan />}
                        onClick={() => {
                            handleDeleteClick(row);
                        }}
                    />
                );
            },
        },
    ];

    // TABLE ROWS/DATA
    const [rows, setRows] = useState([]); // raw table data
    const [formattedRows, setFormattedRows] = useState([]); // table data formatted for UI look
    const [selectedRow, setSelectedRow] = useState({});
    const [datatableStatus, setDatatableStatus] = useState('loading');

    // FORM MODAL PROPS
    const [openFormModal, setOpenFormModal] = useState(false);
    const [submitType, setSubmitType] = useState('');
    const [formAdminExpiry, setFormAdminExpiry] = useState('');
    const [formAccountType, setFormAccountType] = useState({ value: 'user', invalid: false });
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [notificationData, setNotificationData] = useState({});
    const handleSubmit = () => {
        console.log('modal submit');
        setDatatableStatus('loading');
        setButtonDisabled(true);
        let url = submitType === 'edit' ? '/api/account/editAccount' : '/api/account/approveAccount';
        let postData = {
            adminExpiry: formAdminExpiry,
            accountType: formAccountType.value,
            userID: selectedRow.userID,
        };
        try {
            handleTableSubmitLayout(url, postData, (data) => {
                refreshTable(data, headers, setRows, setFormattedRows);
                setOpenFormModal(false);
                setDatatableStatus(!(data.length === 0));
            });
        } catch (err) {
            setNotificationData(err.response);
        } finally {
            setTimeout(() => {
                setButtonDisabled(false);
            }, '1000');
        }
    };

    const handleClick = (row, type) => {
        setSelectedRow(row);
        setSubmitType(type);
        setFormAdminExpiry(row.adminExpiry);
        setFormAccountType((state) => {
            return { ...state, value: row.accountType || 'user' };
        });
        setOpenFormModal(true);
    };

    // DELETE MODAL PROPS
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const handleDeleteSubmit = () => {
        console.log('delete modal submit');
        setDatatableStatus('loading');
        setButtonDisabled(true);
        let url = '/api/account/deleteAccount';
        let postData = {
            userID: selectedRow.userID,
        };
        console.log(postData);
        try {
            handleTableSubmitLayout(url, postData, (data) => {
                refreshTable(data, headers, setRows, setFormattedRows);
                setOpenDeleteModal(false);
                setDatatableStatus(!(data.length === 0));
            });
        } catch (error) {
            setNotificationData(error.response);
        } finally {
            setTimeout(() => {
                setButtonDisabled(false);
            }, '1000');
        }
    };

    const handleDeleteClick = (row) => {
        setSelectedRow(row);
        setOpenDeleteModal(true);
    };

    const TODO_component_datepicker = () => (
        // TODO turn into separate component
        <DatePicker
            datePickerType="single"
            onChange={(fullDate, val) => {
                setFormAdminExpiry(val);
            }}
            dateFormat="m/d/Y"
            minDate={new Date()}
            maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
            value={formAdminExpiry || ''}
        >
            <DatePickerInput
                placeholder="MM/DD/YYYY"
                id={`datepicker${Math.random().toString()}`}
                labelText="ADMIN EXPIRY"
                disabled={formAccountType.value !== 'admin'}
            />
        </DatePicker>
    );

    useEffect(() => {
        (async () => {
            const res = await global.LibAxios.post('/api/account/getAccountData');
            refreshTable(res.data, headers, setRows, setFormattedRows);
            setDatatableStatus(!(res.data.length === 0));
        })();
    }, []);

    return (
        <>
            <Modal
                modal={{
                    open: openFormModal,
                    onClose: () => {
                        setOpenFormModal(false);
                    },
                }}
                header={{
                    title: submitType === 'edit' ? 'Edit Account' : 'Register Account',
                }}
                footer={{
                    hasFooter: true,
                    footerProps: {
                        onRequestSubmit: handleSubmit,
                        primaryButtonDisabled: buttonDisabled,
                    },
                }}
                children={
                    <>
                        <SelectUtil
                            select={{
                                hideLabel: false,
                                labelText: 'ACCOUNT TYPE',
                                value: formAccountType.value || 'user',
                                className: 'mb-3',
                            }}
                            options={{ user: 'user', moderator: 'moderator', admin: 'admin' }}
                            setState={setFormAccountType}
                        />
                        {TODO_component_datepicker()}
                        <PostResponseNotification response={notificationData} />
                    </>
                }
            />
            <Modal
                modal={{
                    open: openDeleteModal,
                    onClose: () => {
                        setOpenDeleteModal(false);
                    },
                }}
                header={{
                    title: 'Delete Account',
                }}
                footer={{
                    hasFooter: true,
                    footerProps: {
                        onRequestSubmit: handleDeleteSubmit,
                        primaryButtonDisabled: buttonDisabled,
                    },
                }}
                children={
                    <>
                        <div>{`Are you sure you want to delete ${selectedRow.email}.`}</div>
                        <PostResponseNotification response={notificationData} />
                    </>
                }
            />
            <Datatable headers={headers} rows={formattedRows} tableTitle="Account Manager" datatableStatus={datatableStatus} />
        </>
    );
};
