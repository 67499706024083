import React from 'react';
import { Loading, InlineLoading } from '@carbon/react';

export const LoadingUtil = ({ loading={}, inlineLoading={}, type='loading' }) =>  {
    let loadingComponent = <></>
    if (type === 'loading') {
        loadingComponent = <Loading {...loading} />
    }
    else if (type === 'inline') {
        loadingComponent = <InlineLoading {...inlineLoading} />
    }

    return loadingComponent;
}
