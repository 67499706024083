import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ComposedModal, ModalHeader, ModalBody, ModalFooter, Button } from '@carbon/react';
import { useNavigate } from 'react-router-dom';

import { useCounter, useToggle } from '../../hooks';
import { refreshJWT } from '../../helpers';
import { logout } from '../../helpers/jwt';
import { useWindowIsVisible } from '../../hooks/useWindowIsVisible';

import './ExpirationModal.scss';

function calculateTimeLeft(unixTime: number) {
    const numUnixTime = Number(unixTime);
    const currentTime = Math.floor(new Date().getTime() / 1000);
    return (numUnixTime - currentTime) * 1000;
}

const oneMinute = 60000;

export const useExpiry = (popuptime = oneMinute) => {
    const [showWarning, toggleShowWarning] = useToggle(false);
    const [expirationDate, setExpirationDate] = useState(Number.MAX_SAFE_INTEGER);
    const { count: timeleft, setCount: setTimeleft, decrement } = useCounter(Math.floor(popuptime / 1000));
    const warningRef = useRef<null | NodeJS.Timeout>(null);
    const logoutRef = useRef<null | NodeJS.Timer>(null);
    const navigate = useNavigate();
    const windowIsVisible = useWindowIsVisible();

    const timers = useMemo(
        () => ({
            createWarningTimer() {
                return setTimeout(() => {
                    toggleShowWarning(true);
                }, Math.floor(calculateTimeLeft(expirationDate) - popuptime));
            },
            createLogoutInterval() {
                setTimeleft(Math.floor(calculateTimeLeft(expirationDate) / 1000));
                return setInterval(() => {
                    decrement();
                }, 1000);
            },
        }),
        [expirationDate, popuptime, setTimeleft, toggleShowWarning, decrement]
    );

    useEffect(() => {
        const time = document.cookie
            .split('; ')
            .find((row) => row.startsWith('exp='))
            ?.split('=')[1];
        setExpirationDate(Number(time));
    }, []);

    useEffect(() => {
        if (typeof expirationDate === 'number') {
            warningRef.current = timers.createWarningTimer();
        }

        return () => {
            if (warningRef.current) {
                clearTimeout(warningRef.current);
            }
        };
    }, [expirationDate, popuptime, timers, windowIsVisible]);

    useEffect(() => {
        if (showWarning) {
            logoutRef.current = timers.createLogoutInterval();
        }
        return () => {
            if (logoutRef.current) {
                clearInterval(logoutRef.current);
            }
        };
    }, [showWarning, timers, windowIsVisible]);

    const handlers = useMemo(
        () => ({
            async refreshToken() {
                if (logoutRef.current) {
                    clearInterval(logoutRef.current);
                }
                if (warningRef.current) {
                    clearTimeout(warningRef.current);
                }
                toggleShowWarning(false);
                const response = await refreshJWT();
                if (response === false) {
                    navigate('/account-services');
                }
                setExpirationDate(response.exp);
            },
            async logout() {
                toggleShowWarning(false);
                const response = await logout();
                if (response !== false) {
                    navigate('/account-services');
                }
            },
            hideModal() {
                // Do not close modal
                return false;
            },
        }),
        [navigate, toggleShowWarning]
    );

    return {
        showWarning,
        ...handlers,
        timeleft: Math.max(timeleft, 0),
    };
};

export const ExpirationModal = () => {
    const { showWarning, refreshToken, hideModal, timeleft, logout } = useExpiry();
    const refreshPage = () => {
        window.location.reload();
    };

    if (timeleft >= 1) {
        return (
            <ComposedModal open={showWarning} onClose={hideModal} className="expiration-modal-timeout" preventCloseOnClickOutside>
                <ModalHeader label="" title="Timeout Alert!" />
                <ModalBody>
                    <p>{`You are about to be logged out in ${timeleft} sec${timeleft > 1 ? 's' : ''}`}</p>
                    <p>Would you like to continue?</p>
                </ModalBody>
                <ModalFooter className="button-row">
                    <Button kind="secondary" onClick={logout}>
                        No, Logout
                    </Button>
                    <Button kind="primary" onClick={refreshToken}>
                        Yes, Keep me logged in
                    </Button>
                </ModalFooter>
            </ComposedModal>
        );
    }

    return (
        <ComposedModal open={showWarning} onClose={refreshPage} className="expiration-modal">
            <ModalHeader label="" title="Timeout Alert!" />
            <ModalBody>
                <p>Reload the page to continue</p>
                <p>Would you like to continue?</p>
            </ModalBody>
            <ModalFooter className="button-row">
                <Button kind="primary" onClick={refreshPage}>
                    Reload
                </Button>
            </ModalFooter>
        </ComposedModal>
    );
};
