import { useCallback, useEffect } from 'react';
import { atom, useAtom } from 'jotai';
import { useCRUDApi } from '../../../hooks/useCRUDApi';
import { mapSources, SourceModel } from './sourceModel';

import type { EntityModel } from '../../../modals/entities/EntityModel';

export const allSourcesAtom = atom<SourceModel[]>([]);
export const filteredSourcesAtom = atom<SourceModel[]>([]);

type SourceListOptions = {
    entity?: EntityModel;
    fetchAll?: boolean;
};
export const useSourceList = ({ entity, fetchAll = false }: SourceListOptions) => {
    const { callApi, loading, error } = useCRUDApi('/api/v1');
    const [allSources, setAllSources] = useAtom(allSourcesAtom);
    const [filteredSources, setFilteredSources] = useAtom(filteredSourcesAtom);

    const fetchAllSources = useCallback(async () => {
        const url = '/sources';
        const resp = await callApi(url, 'get');
        const data = mapSources(resp.rows);
        setAllSources(data);
        return data;
    }, [callApi, setAllSources]);

    const fetchFilteredSources = useCallback(
        async (entity_id: string) => {
            const url = `/sources?entity=${entity_id}`;
            const resp = await callApi(url, 'get');
            const data = mapSources(resp.rows);
            setFilteredSources(data);
            return data;
        },
        [callApi, setFilteredSources]
    );

    const entityId = entity?.id ?? '';
    useEffect(() => {
        if (entityId) {
            fetchFilteredSources(entityId);
        }
    }, [entityId, fetchFilteredSources]);

    useEffect(() => {
        if (fetchAll) {
            fetchAllSources();
        }
    }, [fetchAll, fetchAllSources]);

    return {
        loading,
        allSources,
        filteredSources,
        fetchAllSources,
        fetchFilteredSources,
        callApi,
        error,
    };
};
