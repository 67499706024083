import { atom } from 'jotai';
import { SourceModel } from '../../../source/utils/sourceModel';

import type { ScenarioDataState, EditRow, CalculusDetails, PerceptionConnection, CalculusPerceptionIdSet } from './types';

export const calculusDetailsAtom = atom<CalculusDetails>({});

export const addNewCalculusDetailsAtom = atom(null, (get, set, update: CalculusDetails) => {
    const calculusDetails = get(calculusDetailsAtom);
    set(calculusDetailsAtom, {
        ...calculusDetails,
        ...update,
    });
});

export const editRowAtom = atom<EditRow | null>(null);

export const perceptionConnectionsAtom = atom<PerceptionConnection>({});
export const addPerceptionConnectionAtom = atom(null, (get, set, update: PerceptionConnection) => {
    const perceptionConnections = get(perceptionConnectionsAtom);

    set(perceptionConnectionsAtom, {
        ...perceptionConnections,
        ...update,
    });
});

export const normalizedPerceptions = atom<{ [perceptionId: string]: ScenarioDataState }>({});
export const perceptionTables = atom({});

export const linksByIdAtom = atom<{ [linkId: string]: SourceModel }>({});

export const calculusPerceptionIdSetAtom = atom<CalculusPerceptionIdSet>({});
export const updateCalculusPerceptionIdSetAtom = atom(null, (get, set, update: CalculusPerceptionIdSet) => {
    const calculusPerceptionIdSet = get(calculusPerceptionIdSetAtom);
    set(calculusPerceptionIdSetAtom, {
        ...calculusPerceptionIdSet,
        ...update,
    });
});
