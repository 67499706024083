import { React, useEffect, useState } from 'react';
import { ClickableTileRow, LoadingUtil, PostResponseNotification } from '../../utils';
import { Chat, Email, Mobile, Password, Purchase } from '@carbon/icons-react';
import { Link, useNavigate } from 'react-router-dom';
import { solveRegistrationChallenge, solveLoginChallenge } from '@webauthn/client';
import './Styles/AccountServices.scss';

export const AuthenticationOptions = () => {
    const onClickEmailAuth = () => {
        (async () => {
            try {
                const res = await global.LibAxios.post('/api/emailTFA', {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                    credentials: 'include',
                });
            } catch (err) {
                //TODO add error handling
            }
        })();
    };

    const onClickYubikeyReg = () => {
        (async () => {
            try {
                const reqRegResponse = await global.LibAxios.post(
                    '/api/registerYubikey',
                    { temp: 'temp' },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: true,
                        credentials: 'include',
                    }
                );
                const credentials = await solveRegistrationChallenge(reqRegResponse.data);
                const confirmRegRes = await global.LibAxios.post('/api/registerYubikeyConfirm', credentials, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                    credentials: 'include',
                });
                console.log(confirmRegRes);
                if (confirmRegRes.data == 'yubikey success')
                    setTFAMethods((state) => {
                        let newState = state;
                        newState.yubiKey.value = 'true';
                        return newState;
                    });
            } catch (err) {
                console.log(err.response.data);
                if (err.response.data.status === 'unauthorized') navigate('/');
                else setNotificationData(err.response);
            }
        })();
    };

    const onClickYubikeyAuth = () => {
        (async () => {
            try {
                const yubikeyAuthResponse = await global.LibAxios.post(
                    '/api/yubikeyAuth',
                    { temp: 'temp' },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: true,
                        credentials: 'include',
                    }
                );
                console.log(yubikeyAuthResponse.data);
                const credentials = await solveLoginChallenge(yubikeyAuthResponse.data);
                const confirmAuthRes = await global.LibAxios.post('/api/yubikeyAuthConfirm', credentials, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                    credentials: 'include',
                });
                console.log(confirmAuthRes.data);
                if (confirmAuthRes.data.verified) {
                    navigate(confirmAuthRes.data.redirectURL || '/account-profile');
                }
            } catch (err) {
                console.log(err);
                if (err.response == undefined) setNotificationData({ status: 'authFail' });
                else if (err.response.data.status === 'unauthorized') navigate('/');
                else setNotificationData(err.response);
            }
        })();
    };

    let navigate = useNavigate();
    const [notificationData, setNotificationData] = useState({});
    const [isLoggedIn, setIsLoggedIn] = useState();
    const [TFAMethods, setTFAMethods] = useState({
        phone: {
            heading: 'Text Message',
            description: `Text an authentication code to `,
            icon: <Chat />,
            target: '/login-sms',
        },
        email: {
            heading: 'Email',
            description: `Email an authentication code to `,
            icon: <Email />,
            onClick: onClickEmailAuth,
            target: '/email-authentication',
        },
        googleKey: {
            heading: 'Google Authenticator App',
            description: 'Get an authentication code from the Google Authenticator app.',
            icon: <Mobile />,
            target: '/app-authenticator',
        },
        yubiKey: {
            heading: 'Yubikey',
            description: 'Verify your login with a Yubikey.',
            icon: <Password />,
            onClick: onClickYubikeyAuth,
            onClickReg: onClickYubikeyReg,
        },
        cacKey: {
            heading: 'CAC Card',
            description: 'Verify your login with a CAC Card.',
            icon: <Purchase />,
            target: '/cac-verification',
        },
    });

    useEffect(() => {
        (async () => {
            try {
                const res = await global.LibAxios.post(
                    '/api/getTFAMethods',
                    {
                        temp: 'temp',
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: true,
                        credentials: 'include',
                    }
                );
                const resTfa = res.data.TFAMethods;
                console.log(resTfa);
                setTFAMethods((state) => {
                    let newState = {};
                    Object.keys(resTfa).forEach((label) => {
                        newState[label] = { ...state[label], value: resTfa[label] };
                    });
                    return newState;
                });
                setIsLoggedIn(true);
            } catch (err) {
                console.log(err);
                if (err.response.data.status === 'unauthorized') navigate('/');
                else setNotificationData(err.response);
            }
        })();
    }, [navigate]);

    if (isLoggedIn === undefined) {
        console.log('logged in undefined');
        navigate('/account-services');
        return (
            <LoadingUtil
                type="inline"
                inlineLoading={{
                    className: 'mt-3 justify-content-center',
                    description: <em>Loading</em>,
                }}
            />
        );
    } else {
        return (
            <>
                {/* <img src="/assets/Logos/guardian_logo.png" alt='Logo' className='logo_style' /> */}
                <h3>2-Step Verification</h3>
                <p>To help keep your account safe, we want to make sure it's really you trying to log in</p>
                <div className="w-100 pt-4">
                    <h5>Choose how you want to log in: </h5>
                    <div className="tile-container">
                        {Object.values(TFAMethods).map((tfa, i) => {
                            return (
                                <ClickableTileRow
                                    clickableTile={{
                                        href: tfa.target,
                                        onClick: tfa.value ? tfa.onClick : tfa.onClickReg,
                                    }}
                                    columns={[
                                        { children: tfa.icon },
                                        {
                                            children: (
                                                <>
                                                    <h5>
                                                        {tfa.value ? '' : 'Register '}
                                                        {tfa.heading}
                                                    </h5>
                                                    <div>
                                                        {tfa.description} {tfa.value == 'true' ? '' : tfa.value}
                                                    </div>
                                                </>
                                            ),
                                        },
                                    ]}
                                    key={i}
                                />
                            );
                        })}
                    </div>
                    <div className="text-start">
                        Or try{' '}
                        <Link className="recoveryLink" to="/forgot-password">
                            {' '}
                            account recovery
                        </Link>{' '}
                        instead
                    </div>
                    <PostResponseNotification response={notificationData} />
                </div>
            </>
        );
    }
};
