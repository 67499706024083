import { Modal, TextInput } from '@carbon/react';

import { useSourceList } from '../../pages/source/utils/sourceListHooks';
import { SourceModel } from '../../pages/source/utils/sourceModel';
import { useNewSourceModalState } from './useNewSourceModalState';
import { PerceptionTable } from './PerceptionTable';
import { useNavigate } from 'react-router-dom';

type NewSourceModalProps = {
    open: boolean;
    source?: Partial<SourceModel>;
    sourceId?: string;
    entityId?: string;
    onClose: (source?: string) => void;
};
export const SourceModal = ({ open, source, onClose, entityId }: NewSourceModalProps) => {
    const navigate = useNavigate();
    const { state, loading, error, errorInputs, setErrorInputs, callApi, handleSourceNameChange, handleSourceLinkChange, handleTitleChange, handleDateChange } =
        useNewSourceModalState(open, source);
    const isNew = source?.id === '';

    const { allSources } = useSourceList({ fetchAll: true });

    const onSave = async () => {
        const { title, dateOfArticle, name, linkUrl } = state;
        const sourceNames = allSources.map((source) => source.name);

        if (!state.isValid) {
            if (name.trim() === '') {
                setErrorInputs({ name: true });
            }
            if (title.trim() === '') {
                setErrorInputs({ title: true });
            }
            if (dateOfArticle.length === 0) {
                setErrorInputs({ dateOfArticle: true });
            }
            return;
        }

        let resp;
        if (state.id) {
            resp = await callApi('/sources/' + state.id, 'PUT', {
                title,
                name,
                linkUrl,
                entity_id: entityId,
                date_of_article: dateOfArticle.split(' ')[0],
            });
        } else {
            resp = await callApi('/sources', 'POST', { title, name, linkUrl, entity_id: entityId, date_of_article: dateOfArticle });
        }

        if (resp) {
            onClose('new');
        } else {
            console.error(error);
        }
    };

    const onView = (id: string) => {
        navigate(`/perceptions/${id}`);
        onClose();
    };

    return (
        <Modal
            className="source-modal modal-container"
            size="lg"
            open={open}
            onRequestClose={onClose}
            onRequestSubmit={onSave}
            modalHeading={isNew ? <div className="ps-3 pe-3">New Source</div> : <div className="ps-3 pe-3">Edit Source</div>}
            primaryButtonText={isNew ? 'Create Source' : 'Update Source'}
            secondaryButtonText="Cancel"
        >
            <div className="ps-3 pe-3">
                <div className="d-flex mb-3">
                    <TextInput
                        id="title-input"
                        className="title-input me-3"
                        labelText="Title"
                        invalid={errorInputs.title && !state.isValid}
                        value={state.title}
                        invalidText={'Enter a source title.'}
                        onChange={handleTitleChange}
                    />

                    <TextInput
                        id="source-name-input"
                        className="source-name-input me-3"
                        labelText="Source Name"
                        value={state.name}
                        invalid={errorInputs.name && !state.isValid}
                        invalidText={'Enter a source name.'}
                        onChange={handleSourceNameChange}
                    />
                    <TextInput
                        id="scenario-link-input"
                        className="scenario-link-input me-3"
                        labelText="Link"
                        placeholder="https://google.com"
                        value={state.linkUrl}
                        onChange={handleSourceLinkChange}
                    />
                    <TextInput
                        id="date-of-article-input"
                        className="date-of-article-input"
                        labelText="Date of Article"
                        placeholder="yyyy-mm-dd"
                        value={state.dateOfArticle}
                        type="date"
                        invalid={errorInputs.dateOfArticle && !state.isValid}
                        invalidText="Enter a valid date."
                        onChange={handleDateChange}
                    />
                </div>
                {/* <div className="mb-1">
                    <FileUploader
                        accept={['.pdf', '.jpeg', '.jpg']}
                        // accept="jpeg,jpg"
                        buttonKind="tertiary"
                        buttonLabel="Upload Source Document"
                        iconDescription="Upload to cloud"
                        onChange={console.log}
                    />
                </div> */}
                {!isNew && (
                    <div className="row mt-3">
                        <div className="col-md-10">
                            <PerceptionTable id="decision-calculi-table" tableData={state.perceptions} isLoading={loading} onView={onView} />
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
};
