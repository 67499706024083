import React from 'react'; 
import {Tile} from '@carbon/react'

export const ScrollableTextElement = ({ setScrolledToBottom=false, className='', children='' }) => {

    const onScroll = (e) => {
        if ( e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight) {
            setScrolledToBottom(true)
        } 
    }
    
    return (            
        <Tile>
            <p className={className} onScroll={onScroll}>{children}</p>
        </Tile>
    )
}