import React from 'react';

import { DataTable, DataTableSkeleton, Table, TableBody, TableHead, TableHeader, TableRow, TableCell, Button } from '@carbon/react';
import { ZoomIn } from '@carbon/icons-react';
import { PerceptionTableProps } from './utils/types';

const perceptionTableHeaders = [
    { header: 'Perception Name', key: 'name' },
    { header: 'Entity', key: 'entity_name' },
    { header: 'Type', key: 'type' },
    { header: '', key: 'view', isSortable: false },
];

export const PerceptionTable = ({ tableData, onView, isLoading = false, id = 'perception-table' }: PerceptionTableProps) => {
    if (isLoading) {
        return (
            <section className="perception-table-section">
                <h6 className="mb-2 header text-left">All Perceptions with this Source</h6>
                <DataTableSkeleton headers={perceptionTableHeaders} id={`${id}_loading`} rowCount={5} showHeader={false} showToolbar={false} />
            </section>
        );
    }

    const perceptionRows = tableData?.map((row) => ({ ...row, id: row.perception_id }));
    return (
        <section className="perception-table-section">
            <h6 className="mb-2 header text-left">All Perceptions with this Source</h6>
            <DataTable rows={perceptionRows ?? []} headers={perceptionTableHeaders} isSortable id={id} className="perception-table-container">
                {({ rows, headers, getHeaderProps, getRowProps, getTableProps }: any) => (
                    <Table className="perception-table" {...getTableProps()}>
                        <TableHead>
                            <TableRow>
                                {headers.map((header: any) => (
                                    <TableHeader key={header.key} {...getHeaderProps({ header })}>
                                        {header.header}
                                    </TableHeader>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row: any) => (
                                <TableRow key={`${row.id}`} {...getRowProps({ row })}>
                                    {row.cells.map((cell: any) => (
                                        <React.Fragment key={cell.id}>
                                            {cell.info.header === 'view' ? (
                                                <TableCell key={cell.id} className="view-button-cell">
                                                    <Button
                                                        renderIcon={ZoomIn}
                                                        kind="ghost"
                                                        className="view-button"
                                                        size="sm"
                                                        iconDescription="Zoom"
                                                        onClick={() => onView(row.id)}
                                                    />
                                                </TableCell>
                                            ) : (
                                                <TableCell key={cell.id}>{cell.value}</TableCell>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </DataTable>
        </section>
    );
};
