import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useStateObject } from '../../hooks/useStateObject';

import { useCarbonModalOpened } from '../../hooks';
import { SourceModel, TSourceModel } from '../../pages/source/utils/sourceModel';
import { useCRUDApi } from '../../hooks/useCRUDApi';

function validateState({ name, title, dateOfArticle }: TSourceModel) {
    if (name.length > 0 && title.length > 0 && dateOfArticle.length > 0) {
        return true;
    }
    return false;
}

export const useNewSourceModalState = (open: boolean, source: Partial<SourceModel> = {}) => {
    const { callApi, error, loading } = useCRUDApi('/api/v1');
    const [state, setState] = useStateObject<TSourceModel>(
        {
            ...source,
            ...new SourceModel({}),
        },
        validateState
    );
    const [errorInputs, setErrorInputs] = useStateObject({
        name: false,
        title: false,
        dateOfArticle: false,
    });

    useCarbonModalOpened(open, () => {
        setState({
            ...new SourceModel({}),
            ...source,
        });
    });

    const handlers = useMemo(
        () => ({
            handleSourceNameChange(e: ChangeEvent<HTMLInputElement>) {
                setState({ name: e.target.value });
                setErrorInputs({ name: false });
            },
            handleTitleChange(e: ChangeEvent<HTMLInputElement>) {
                setState({ title: e.target.value });
                setErrorInputs({ title: false });
            },
            handleDateChange(e: ChangeEvent<HTMLInputElement>) {
                setState({ dateOfArticle: e.target.value });
                setErrorInputs({ dateOfArticle: false });
            },
            handleSourceLinkChange(e: ChangeEvent<HTMLInputElement>) {
                setState({ linkUrl: e.target.value });
            },
        }),
        [setState, setErrorInputs]
    );

    return {
        state,
        error,
        errorInputs,
        setErrorInputs,
        loading,
        callApi,
        setState,
        ...handlers,
    } as const;
};
