import { useEffect, useCallback, useState } from 'react';
import { useAtom, atom } from 'jotai';

import { getUserRole } from '../helpers/jwt';

export type UserRole = 'init' | 'user' | 'admin' | 'moderator';

export const userRoleAtom = atom<UserRole>('init');

export const useUserRole = () => {
    const [userRole, setUserRole] = useAtom(userRoleAtom);
    const [loading, setLoading] = useState(false);

    const fetchUserRole = useCallback(async () => {
        setLoading(true);
        const role: null | false | UserRole = await getUserRole();
        setLoading(false);
        if (role !== false && role !== null) {
            setUserRole(role);
        }
    }, [setUserRole]);

    useEffect(() => {
        if (userRole === 'init' && !loading) {
            fetchUserRole();
        }
    }, [fetchUserRole, loading, userRole]);

    return {
        userRole,
        fetchUserRole,
    } as const;
};
