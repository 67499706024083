import { Logout, UserProfile } from '@carbon/icons-react';
import { Header, HeaderGlobalBar, HeaderMenuItem, HeaderName, HeaderNavigation, OverflowMenu, OverflowMenuItem } from '@carbon/react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThemeButton } from '../ThemeButton';
import './Navigation.scss';
import React, { useEffect } from 'react';
import axios from 'axios';
import { getCSRFToken } from '../../helpers';
import { sadcPaths } from '../../config';

type NavigationProps = {
    changeTheme: (theme: string) => void;
    carbonTheme: string;
};
declare let global: {
    LibAxios: any;
    GetCSRFToken: any;
};

const Navigation = ({ changeTheme, carbonTheme }: NavigationProps) => {
    useEffect(() => {
        (async () => {
            await getCSRFToken();
        })();
    }, []);
    const navigate = useNavigate();
    const logout = () => {
        (async () => {
            try {
                await axios.post(
                    '/api/logout',
                    {},
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: true,
                    }
                );
            } catch (err) {
                console.log('failed to log out');
            }
            navigate('/account-services');
        })();
    };
    const { pathname } = useLocation();

    return (
        <div>
            <div className="global-banner">NOTIONAL</div>
            <Header className="top-nav" aria-label="Guardian Header">
                <HeaderName href="/" prefix="">
                    <img src="/assets/logos/guardian_logo_70x70.png" alt="Guardian Logo" width="30" />
                    Guardian
                </HeaderName>
                {sadcPaths.find((path) => path.path.includes(pathname.split('/')[1])) && (
                    <HeaderNavigation aria-label="IBM [Platform]">
                        <HeaderMenuItem onClick={() => navigate('/')} isActive={pathname === '/'}>
                            Home
                        </HeaderMenuItem>
                        <HeaderMenuItem onClick={() => navigate('/calculi')} isActive={pathname.includes('/calculi')}>
                            Decision Calculi
                        </HeaderMenuItem>
                        <HeaderMenuItem onClick={() => navigate('/perceptions')} isActive={pathname.includes('/perceptions')}>
                            Perceptions
                        </HeaderMenuItem>
                        <HeaderMenuItem onClick={() => navigate('/sources')} isActive={pathname.includes('/sources')}>
                            Sources
                        </HeaderMenuItem>
                        <HeaderMenuItem onClick={() => navigate('/reports')} isActive={pathname.includes('/reports')}>
                            Reports
                        </HeaderMenuItem>
                    </HeaderNavigation>
                )}
                <HeaderGlobalBar>
                    <ThemeButton changeTheme={changeTheme} carbonTheme={carbonTheme} />
                    {sadcPaths.find((path) => path.path.includes(pathname.split('/')[1])) && (
                        <OverflowMenu
                            size="lg"
                            className="user-profile-top-nav-button"
                            flipped
                            aria-label="User Profile"
                            label="User Profile"
                            renderIcon={UserProfile}
                            align="left"
                            iconDescription="User Profile"
                        >
                            <OverflowMenuItem
                                label="Logout"
                                className="user-profile-item"
                                hasDivider
                                itemText={
                                    <>
                                        <Logout />
                                        <span className="ms-2 text-nowrap">Logout</span>
                                    </>
                                }
                                onClick={logout}
                            />
                        </OverflowMenu>
                    )}
                </HeaderGlobalBar>
            </Header>
        </div>
    );
};

Navigation.propTypes = {
    changeTheme: PropTypes.func,
    carbonTheme: PropTypes.string,
};

export default Navigation;
