import React, { useEffect, useState } from 'react';
import { ButtonUtil } from '../ButtonUtil';
import { TileGridLayout } from './TileGridLayout';
import { Loading } from '@carbon/react';

/**
 * Template page for handling one-time tokens
 * @param {String} type type of token
 * @param {Boolean} homeButton whether a "Return to Home" button will be displayed
 * @param children contents of the page. This can be either a string or HTML
 */
export const OneTimeTokenLayout = ({ type, homeButton, children }) => {
    const [pageStrings, setPageStrings] = useState({ title: "", message: "" });

    useEffect(() => {
        setPageStrings(tokenPages[type]);
    }, [])

    const loadingIcon =
        <>
            <div style={{ display: "inline-flex", justifyContent: 'center', lineHeight: ".65em" }}>
                <Loading small={true} withOverlay={false} />
            </div>
        </>;

    return <TileGridLayout
        grid={{
            style: { height: '90vh' },

        }}
        column={{
            lg: { span: 6, offset: 5 },
            md: { span: 6, offset: 1 },
            sm: 4,
            className: 'h-100 d-flex align-items-center',
        }}
        tile={{
            className: 'rounded w-100 text-center px-5 pb-5',
            style: {
                // height:'100%',
                // backgroundColor:''
            },
            children: <>
                <div className='d-flex justify-content-center border-bottom border-2'>
                    <div style={{ maxWidth: '4em' }}>
                        <img
                            className='h-auto w-100'
                            src="/assets/Logos/guardian_logo.png"
                            alt='Logo'
                        />
                    </div>
                </div>
                <h3 className='fw-bold mt-4'>{pageStrings.title}</h3>
                <p className='py-3'>
                    {children || loadingIcon}
                </p>
                {
                    homeButton && <ButtonUtil
                        button={{
                            children: 'Return to Home',
                            className: 'mt-3 rounded',
                            href: '/'
                        }}
                    />
                }

            </>
        }}
    />
};

const tokenPages = {
    tokenResetPassword: {
        title: "Reset Password",
        loadingMessage: "",
        successMessage: "Your password has successfully been reset.",
        errorMessage: "",
    },
    emailVerification: {
        title: "Email Verification",
        loadingMessage: "",
        successMessage: "Your email has successfully been verified.",
        errorMessage: "",
    },
    userReactivation: {
        title: "Account Reactivation",
        loadingMessage: "",
        successMessage: "Your account has successfully been reactivated.",
        errorMessage: "",
    },
    adminActivation: {
        title: "Account Activation",
        loadingMessage: "",
        successMessage: "The account associated with the email PLACEHOLDER has been successfully activated.",
        errorMessage: "",
    },
}