import { CalculusInputsHeaderProps } from './types';
import { Button } from '@carbon/react';

export const CalculusInputsHeader = ({ headerText, prefillButton, handlePrefill }: CalculusInputsHeaderProps) => {
    return (
        <div className="d-flex justify-content-between">
            <h3>{headerText}</h3>
            {prefillButton && (
                <Button kind="tertiary" onClick={() => handlePrefill?.(true)}>
                    Prefill From Calculus
                </Button>
            )}
        </div>
    );
};
