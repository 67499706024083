import { ComboBox } from '@carbon/react';
import { NumberInput, TextArea } from '@carbon/react';
import React from 'react';
import { CommentList } from '../../../components/commentList/CommentList';
import { SourceModel } from '../../../pages/source/utils/sourceModel';
import { CommentModel } from '../../../shared/CommentModel';
import { DropdownEvent } from './utils/types';

interface UpdateValueFormProps {
    title: string;
    id: string;
    quantity: number;
    source: SourceModel | null;
    comment: string;
    sources: SourceModel[];
    commentMaxLength?: number | null;
    previousComments?: CommentModel[];
    quantityInputProps?: React.HTMLProps<HTMLInputElement>;
    sourceDropdownProps?: React.HTMLProps<HTMLSelectElement>;
    commentTextAreaProps?: React.HTMLProps<HTMLTextAreaElement>;
    onQuantityChange: (e: React.ChangeEvent<HTMLInputElement>, { value }: { value: string | number }) => void;
    onSourceChange: (e: DropdownEvent<SourceModel>) => void;
    onCommentChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    isLoading: boolean;
    className?: string;
}

export const UpdateValueForm = ({
    title,
    id,
    quantity,
    source,
    sources,
    comment,
    commentMaxLength = null,
    previousComments = [],
    quantityInputProps = {},
    sourceDropdownProps = {},
    commentTextAreaProps = {},
    onQuantityChange,
    onSourceChange,
    onCommentChange,
    isLoading,
    className = '',
}: UpdateValueFormProps) => {
    return (
        <section className={`p-3 w-100 ${className}`}>
            <h4 className="mb-0">{title}</h4>
            <div className="row mb-2">
                <div className="col-md-5 align-items-start" style={{ marginTop: '25px' }}>
                    <NumberInput
                        id={`num-input-${id}`}
                        value={quantity}
                        onChange={onQuantityChange}
                        // max & min is required for Carbon components
                        min={0}
                        max={100}
                        invalidText="Please enter a value between 0 and 100."
                        {...quantityInputProps}
                    />
                </div>
                <div className="col-md-7 align-items-start">
                    <ComboBox
                        id="Sources-list"
                        titleText="Source"
                        label=""
                        direction="top"
                        selectedItem={sources.find((option) => option.id === source?.id) ?? null}
                        items={sources}
                        itemToString={(item: SourceModel) => (item ? item.name || item.linkUrl : '')}
                        onChange={onSourceChange}
                        {...sourceDropdownProps}
                    />
                </div>

                {/* <TextInput className="col-md-7" id={`text-input-${id}`} labelText="Source" value={source} onChange={onSourceChange} {...sourceInputProps} /> */}
            </div>
            <div>
                <TextArea
                    id={`textarea-${id}`}
                    labelText="Comment"
                    maxCount={commentMaxLength}
                    enableCounter={!!commentMaxLength}
                    value={comment}
                    onChange={onCommentChange}
                    {...commentTextAreaProps}
                />
            </div>
            <div className="divider" />
            <CommentList isLoading={isLoading} title="Previous Comments" comments={previousComments} />
        </section>
    );
};
