import React from 'react';

export const ColumnsLayout = ({ row={}, cols=[] }) => {
    return(
        <div 
            className={'row ' + row.className}
            children=
                {cols.map( (col, i) => {
                    const colClassName = col.className === undefined 
                        ? 'col-md-6'
                        : `col-md-6 ${col.className}`
                    return (
                        <div 
                            key={i} 
                            {...col}
                            className={colClassName}
                        /> 
                    )
                })}
        />
    )
}

export const GridLayout = ({cols=[], row={}, container={}}) => {
    return (
        <div className={`container${container.className ? ` ${container.className}` : ''}`}>
            <div className={`row${row.className ? ` ${row.className}` : ''}`}>
                {cols.map((col, i)=>{
                    return (
                        <div key={i} className={`col${col.className ? ` ${col.className}` : ''}`} style={col.style}>
                            {col.children}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}


export const VerticalAlignCenter = (props) => (
    <>
        <div className="row card mt-5 text-center">
            <div className="col-sm-12">
                {props.children}
            </div>
        </div>
    </>
)
