import React from 'react';
import { useAtom } from 'jotai';

import { SelectionDialog } from './SelectionDialog';
import { Mode } from './types';
import { calculiDataAtom } from '../../pages/calculus/DecisionCalculus/utils/useDecicisionCalculusHooks';
import { CalculusData } from '../calculusInputs/types';

interface SelectCalcWrapperProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (param: CalculusData | null) => void;
}
export const SelectCalcWrapper = ({ open, onClose, onSubmit }: SelectCalcWrapperProps) => {
    const [calculiData] = useAtom(calculiDataAtom);

    const handleSubmit = (selectedId: string) => {
        const selectedPerception = calculiData.find((data: any) => data.id === selectedId) ?? null;
        onSubmit(selectedPerception);
    };

    return <SelectionDialog open={open} onClose={onClose} mode={Mode.Calc} data={calculiData} onSubmit={handleSubmit} />;
};
