import { parseISO, formatISO9075, format } from 'date-fns';
import { Link, Tag } from '@carbon/react';
import { fixLink } from '../../../helpers/linkPrefix';

export type TSourceModel = Omit<SourceModel, 'getExportColumns'>;

export class SourceModel {
    title = '';
    dateOfArticle = '';
    linkUrl = '';
    linkElement: JSX.Element = (<></>);
    createdBy = '';
    createdOn = '';
    hasAttachment = false;
    hasAttachmentLabel: JSX.Element = (<></>);
    id = '';
    name = '';
    comment? = '';
    perceptions = [];

    constructor(data: Partial<SourceModel>) {
        Object.assign(this, data);
        this.name = data.name ?? '';
        this.linkElement = (
            <Link inlinehref={this.linkUrl} target="_blank" rel="noreferrer">
                {fixLink(this.linkUrl)}
            </Link>
        );
        this.hasAttachment = !!data.hasAttachment;
        this.hasAttachmentLabel = <Tag>{this.hasAttachment ? 'Yes' : 'No'}</Tag>;
        this.createdOn = data?.createdOn ? formatISO9075(parseISO(data?.createdOn || '')) : '';
        this.dateOfArticle = data?.dateOfArticle ? format(parseISO(data?.dateOfArticle || ''), 'yyyy-MM-dd') : '';
    }

    getExportColumns() {
        return [
            { field: 'id', title: 'Id' },
            { field: 'title', title: 'Title' },
            { field: 'name', title: 'Name' },
            { field: 'dateOfArticle', title: 'Date of Article' },
            { field: 'linkUrl', title: 'Link Url' },
            { field: 'createdBy', title: 'Created By' },
            { field: 'createdOn', title: 'Created' },
            { field: 'hasAttachment', title: 'Has Attachment' },
            { field: 'perceptions', title: 'Perceptions' },
        ];
    }
}

export const mapSources = (rows: any[]) => rows.map((r) => new SourceModel(r));
