import React, { useState } from 'react';
import { Button, DataTableSkeleton, TextArea, TextInput } from '@carbon/react';
import { atom, useSetAtom } from 'jotai';

import { FilteredTable } from '../../components/filtered-table/FilteredTable';
import { ObjectiveModel } from './ObjectiveModel';
import { useObjectives } from './ObjectivesHooks';
import { compareNames } from '../../helpers/dataTableSort';

const TABLE_HEADERS = [
    {
        key: 'name',
        header: 'Name',
    },
    {
        key: 'description',
        header: 'Description',
    },
    // {
    //     key: 'used',
    //     header: 'In Use',
    // },
    {
        key: 'createdBy',
        header: 'Created By',
    },
    {
        key: 'updated',
        header: 'Last Modified',
    },
];

export const deleteObjectiveIdAtom = atom<null | string>(null);
const ObjectivesModal = () => {
    const { selectedObjective, objectives, error, loading, fetchObjectives, setSelectedObjective, callApi } = useObjectives();
    const setDeleteId = useSetAtom(deleteObjectiveIdAtom);
    const [invalidObjectiveName, setInvalidObjectiveName] = useState(false)
    const [currentObjectiveName, setCurrentObjectiveName] = useState('')

    const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedObjective(new ObjectiveModel({ ...selectedObjective, name: e.target.value.trim() }));
        setInvalidObjectiveName(false)
    };

    const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedObjective(
            new ObjectiveModel({
                ...selectedObjective,
                description: e.target.value,
            })
        );
    };

    const onSave = async () => {
        let url = '/objectives',
            method = 'post';

        if (selectedObjective?.id) {
            url = '/objectives/' + selectedObjective?.id;
            method = 'put';
        }

        const objectiveNames = objectives.map((objective) => (objective.name))

        const nameExists = selectedObjective?.id ?
        objectiveNames.filter((objective) => objective !== currentObjectiveName).includes(selectedObjective?.name) 
        : objectiveNames.includes(selectedObjective?.name ?? '')

        if (nameExists || selectedObjective?.name === '') {
            setInvalidObjectiveName(true)
            return
        }

        if (!invalidObjectiveName) {
            await callApi(url, method, selectedObjective);
            await fetchObjectives();
            setSelectedObjective(undefined);
        }
    };

    const onEdit = (id: string) => {
        let objective = objectives.find((e) => e.id === id)
        setInvalidObjectiveName(false)
        setSelectedObjective(objectives.find((e) => e.id === id));
        setCurrentObjectiveName(objective?.name ?? '')
    };

    const onNew = () => {
        setInvalidObjectiveName(false)
        setSelectedObjective(new ObjectiveModel({}));
    };

    if (loading) return <DataTableSkeleton headers={TABLE_HEADERS} />;

    return (
        <div className={selectedObjective ? 'has-form' : ''}>
            <FilteredTable
                headers={TABLE_HEADERS}
                data={objectives}
                onEdit={onEdit}
                onDelete={setDeleteId}
                loading={loading}
                onMainAction={onNew}
                mainActionLabel="New Objective"
                customCompare={{ createdBy: compareNames }}
            ></FilteredTable>
            {selectedObjective && (
                <>
                    <div className="edit-item-form">
                        <h2 className="mb-2">
                            {selectedObjective.id === '' ? 'New ' : 'Edit '}
                            Objective
                        </h2>
                        <div className="d-flex align-items-end">
                            <div className="d-flex flex-column flex-grow-1">
                                <TextInput
                                    className="mb-3"
                                    id="name"
                                    type="text"
                                    maxLength="255"
                                    labelText="Objective Name"
                                    value={selectedObjective.name.trim()}
                                    onChange={onNameChange}
                                    invalid = {invalidObjectiveName}
                                    invalidText = {selectedObjective.name === '' ? "Please try an objective name that is not empty" : "The objective name must be unique"}
                                />
                                <TextArea
                                    id="description"
                                    maxLength="1000"
                                    labelText="Objective Description"
                                    value={selectedObjective.description || ''}
                                    onChange={onDescriptionChange}
                                />
                            </div>

                            <Button kind="primary" size="md" className="mx-3" onClick={onSave}>
                                {selectedObjective.id ? 'Update' : 'Create'}
                            </Button>
                            <Button kind="tertiary" size="md" onClick={() => setSelectedObjective(undefined)}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ObjectivesModal;
