import React, { useState } from 'react';
import { Tile } from '@carbon/react';
import { 
    ButtonUtil, 
    TextField, 
    PasswordField, 
    EmailField, 
    PostResponseNotification, 
    CheckboxElement
} from '../../utils';
import {Email, Password, User, Identification, Chat } from '@carbon/icons-react';

import './Styles/FirstAdminForm.scss';
import './Styles/AccountServices.scss';

export const FirstAdmin = () => {
    const [email, setEmail] = useState({ value: '' });
    const [name, setName] = useState({ value:'' });
    const [password, setPassword] = useState({ value:'' });
    const [duplicate, setDuplicate] = useState({ value:'' });
    const [notificationData, setNotificationData] = useState({});
    const [checkedBox, setCheckedBox] = useState(false)

    let buttonDisabled = true
    if (
        email.invalid===false && email.value.length>0 &&
        name.invalid===false && name.value.length>0 &&
        password.invalid===false && password.value.length>0 &&
        duplicate.invalid===false && duplicate.value.length>0 &&
        checkedBox===true
    ){
        buttonDisabled = false
    }

    return (
        <div className='rounded m-5 text-center account-services-form'>
            <div className="d-flex flex-column">
                <div className='d-flex flex-row m-auto'>
                    <span><img src="/assets/Logos/guardian_logo_70x70.png" alt='Logo' className='logo_style' /></span>
                    <div className='page-header-font m-auto ml-2'>Welcome to Rhombus Guardian</div>
                </div>
                
                <div className="d-flex flex-row">
                    <div className='p-3 w-50 m-auto'>
                        <div className='w-50 m-auto disclaimer-font'>
                            <div className='mb-4'>You are seeing this page because this is a new UI with no registered users. If this is not the case, please contact <span className='highlight-url'>it@rhombuspower.com.</span></div>
                            <div className='mb-4'>This page is visible only because the <span className='highlight-text'>users table</span> is empty.</div>
                            <div className='mb-4'>The user who will register here will automatically be granted <span className='highlight-text'>Admin privileges</span>.</div>
                            Do not forget to add this registered email to <span className='highlight-text'>RB_admin_emails in the .env file.</span>
                        </div>
                    </div>
                    
                <div className="d-flex flex-column w-50">
                <Tile>
                    <div 
                        style={{
                            display:'flex', 
                            marginBottom:15, 
                            marginTop: 32
                        }}
                    >
                        <Email style={{ width:30 }} />
                        <EmailField 
                            textInput={{
                                ...email, 
                                labelText:<>Email  <span style={{color:'red'}}>* </span></>
                            }} 
                            type='emailBasic' 
                            validation={true} 
                            setState={setEmail}
                        />
                    </div>
                    <div
                        style={{
                            display:'flex', 
                            marginBottom:15
                        }}
                    >
                        <User style={{ width:30 }} />
                        <TextField 
                            textInput={{ 
                                ...name, 
                                labelText:<>Name  <span style={{color:'red'}}>* </span></> 
                            }} 
                            setState={setName} 
                            type='name'
                            validation={true}
                        />
                    </div>
                    <div 
                        style={{
                            display:'flex', 
                            marginBottom:15
                        }}
                    >
                        <Password style={{ width:30 }}/>
                        <PasswordField 
                            passwordInput={{ 
                                ...password, 
                                labelText:<>Password  <span style={{color:'red'}}>* </span></> 
                            }} 
                            type='password' 
                            validation={true} 
                            duplicate={duplicate.value} 
                            setState={setPassword}
                            setDuplicate={setDuplicate}
                        />
                    </div>
                    <div 
                        style={{
                            display:'flex', 
                            marginBottom:15
                        }}
                    >
                        <Password style={{ width:30 }}/>
                        <PasswordField 
                            passwordInput={{
                                ...duplicate, 
                                labelText:<>Confirm  <span style={{color:'red'}}>* </span></>
                            }} 
                            type='duplicate'  
                            validation={true} 
                            original={password.value} 
                            setState={setDuplicate}
                        />
                    </div>
                    <CheckboxElement 
                        checkbox={{
                            labelText: 'I agree to the Rhombus Power Terms of Service and Privacy Policy', 
                            className:'mb-4'
                        }} 
                        setCheckedBox={setCheckedBox}
                    />
                    <ButtonUtil 
                        button={{ 
                            children:'Create Admin', 
                            disabled: buttonDisabled
                        }} 
                    />
                    <PostResponseNotification response={notificationData}/>
                </Tile>
                </div>
                </div>
            </div>  
        </div>
    )
};
