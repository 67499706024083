import { useCallback, useEffect, useState } from 'react';
import { atom, useAtom } from 'jotai';

import { useCRUDApi } from '../../../hooks/useCRUDApi';
import { mapPerceptions, PerceptionModel } from './PerceptionModel';
import { useEntities } from '../../../modals/entities/EntitiesHooks';
import { EntityModel } from '../../../modals/entities/EntityModel';

export const selectedEntityAtom = atom<EntityModel | null>(null);

export const usePerceptionList = () => {
    const { callApi, loading, error } = useCRUDApi('/api/v1');
    const [perceptions, setPerceptions] = useState<PerceptionModel[]>([]);
    const [filteredPerceptions, setFilteredPerceptions] = useState<PerceptionModel[]>([]);
    const [selectedEntity, setSelectedEntity] = useAtom(selectedEntityAtom);
    const { entities } = useEntities();

    const fetchPerceptions = useCallback(async () => {
        const resp = await callApi('/perceptions', 'get');
        const data = mapPerceptions(resp.rows);
        setPerceptions(data);
        return data;
    }, [callApi, setPerceptions]);

    const fetchFilteredPerceptions = useCallback(async () => {
        const resp = await callApi(`/perceptions?entity=${selectedEntity?.id}`, 'get');
        const data = mapPerceptions(resp.rows);
        setFilteredPerceptions(data);
        return data;
    }, [callApi, setFilteredPerceptions, selectedEntity]);

    useEffect(() => {
        if (selectedEntity) {
            fetchFilteredPerceptions();
        }
    }, [selectedEntity, fetchFilteredPerceptions]);

    return {
        loading,
        perceptions,
        entities,
        filteredPerceptions,
        fetchFilteredPerceptions,
        selectedEntity,
        fetchPerceptions,
        callApi,
        setSelectedEntity,
        error,
    };
};

export const usePerceptionState = () => {
    const [newPerceptionModalOpen, setNewPerceptionModalOpen] = useState(false);
    const [editPerceptionModalOpen, setEditPerceptionModalOpen] = useState(false);
    const [perceptionToEdit, setPerceptionToEdit] = useState<PerceptionModel>();
    const [selectedPerceptions, setSelectedPerceptions] = useState<string[]>([]);

    return {
        selectedPerceptions,
        newPerceptionModalOpen,
        editPerceptionModalOpen,
        perceptionToEdit,
        setSelectedPerceptions,
        setNewPerceptionModalOpen,
        setEditPerceptionModalOpen,
        setPerceptionToEdit,
    };
};
