import { Document, DocumentDownload } from '@carbon/icons-react';
import { Button, Modal } from '@carbon/react';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';

import { Header, IBreadcrumItem } from '../../components/Header/Header';
import { PageList } from '../../components/pageList';
import { PageListViewLink } from '../../components/pageList/PageList';
import { exportUtility } from '../../utils/export';
import { SelectCalcWrapper } from '../../modals/selectionDialog/SelectCalcWrapper';
import { GenerateReport } from '../../modals/generateReport/GenerateReport';
import { useToggle } from '../../hooks';
import { compareNames } from '../../helpers/dataTableSort';
import { useReportList, useReportState } from './utils/reportListHooks';
import { CalculusData } from '../../modals/calculusInputs/types';
import { useDecisionCalculus } from '../calculus/DecisionCalculus/utils/useDecicisionCalculusHooks';

const reportListTableHeaders = [
    { header: 'Report Name', key: 'name' },
    { header: 'Report Type', key: 'type' },
    { header: 'Created On', key: 'createdOn' },
    { header: 'Prepared By', key: 'preparedBy' },
];

const BREADCRUMBS: IBreadcrumItem[] = [
    {
        label: 'Reports',
    },
];

const EXPORT_COLUMNS = [
    { field: 'id', title: 'Id' },
    { field: 'name', title: 'Name' },
    { field: 'type', title: 'Type' },
    { field: 'preparedBy', title: 'Created By' },
    { field: 'createdOn', title: 'Created' },
];

const VIEW_LINK: PageListViewLink = {
    baseUrl: '/reports',
    columns: ['name'],
};

export const ReportList = () => {
    const navigate = useNavigate();
    const [selectCalcOpen, setSelectCalcOpen] = useToggle();
    const [deleteId, setDeleteId] = useState<null | string>(null);
    const { calculi, fetchCalculi } = useDecisionCalculus();

    const { callApi, loading, tableData, fetchReportsList } = useReportList();

    const { newReportModalOpen, editReportModalOpen, reportToEdit, calcRows, setNewReportModalOpen, setEditReportModalOpen, setReportToEdit, setCalcRows } =
        useReportState();

    const handleEdit = (id: string) => {
        setReportToEdit(tableData.find((r) => r.id === id));
        const selectedCalcs: CalculusData[] = [];
        reportToEdit?.calculus_ids.forEach((id) => {
            const foundCalc = calculi.find((calc: CalculusData) => calc.id === id);
            foundCalc && selectedCalcs.push(foundCalc);
        });
        setCalcRows(selectedCalcs);
        reportToEdit && setEditReportModalOpen(true);
    };

    const onDelete = async () => {
        await callApi('/reports/' + deleteId, 'DELETE');
        fetchReportsList();
        setDeleteId(null);
    };

    const onOpenDeleteModal = (rows: any[]) => {
        setDeleteId(rows[0]?.id ?? null);
    };

    const onClose = (source: string | undefined) => {
        if (source === 'new' || source === 'edit') {
            fetchReportsList();
        }
        setEditReportModalOpen(false);
        setNewReportModalOpen(false);
    };

    const onMainAction = () => {
        setNewReportModalOpen(true);
    };

    const handleView = (id: string) => {
        navigate(`/reports/${id}`);
    };

    const onExport = () => {
        exportUtility({ data: tableData, fileName: 'reports', keys: EXPORT_COLUMNS });
    };

    const submitCalcModal = (data: CalculusData | null) => {
        data ? setCalcRows([data]) : setCalcRows([]);
        setSelectCalcOpen(false);
    };

    useEffect(() => {
        fetchReportsList();
        fetchCalculi();
    }, [fetchReportsList, fetchCalculi]);

    return (
        <section className="decision-table-section">
            <Header title="Reports List" breadcrumbs={BREADCRUMBS}>
                <Button kind="tertiary" size="md" renderIcon={DocumentDownload} disabled={tableData.length === 0} onClick={onExport}>
                    Export Data
                </Button>
            </Header>
            <div className="p-4">
                <PageList
                    hasOverflowMenu
                    detailIcon={<Document />}
                    onEdit={handleEdit}
                    // onView={handleView}
                    onDeleteRows={onOpenDeleteModal}
                    loading={loading}
                    rows={tableData}
                    headers={reportListTableHeaders}
                    mainActionLabel="New Report"
                    emptyPlaceholder="No Reports"
                    onMainAction={onMainAction}
                    filter={{
                        supported: true,
                        filterBy: [
                            { key: 'type', name: 'Report Type' },
                            { key: 'preparedBy', name: 'Prepared By' },
                        ],
                    }}
                    customCompare={{ preparedBy: compareNames }}
                />

                {newReportModalOpen && (
                    <GenerateReport
                        open={newReportModalOpen}
                        onSubmit={fetchReportsList}
                        onClose={onClose}
                        calcRows={calcRows}
                        addCalc={setSelectCalcOpen}
                        setCalcRows={() => setCalcRows([])}
                    />
                )}
                {editReportModalOpen && (
                    <GenerateReport
                        open={editReportModalOpen}
                        onSubmit={fetchReportsList}
                        onClose={onClose}
                        report={reportToEdit}
                        calcRows={calcRows}
                        addCalc={setSelectCalcOpen}
                        setCalcRows={() => setCalcRows([])}
                    />
                )}

                {deleteId ? (
                    <Modal
                        open
                        danger
                        modalHeading="Are you sure you want to delete report?"
                        modalLabel="Delete report"
                        primaryButtonText="Delete"
                        secondaryButtonText="Cancel"
                        onRequestClose={() => setDeleteId(null)}
                        onRequestSubmit={onDelete}
                    />
                ) : null}
            </div>
            <SelectCalcWrapper open={selectCalcOpen} onClose={setSelectCalcOpen} onSubmit={(data) => submitCalcModal(data)} />
        </section>
    );
};
