import React, { useMemo, useState } from 'react';
import { useCRUDApi } from '../../../../hooks/useCRUDApi';

import { useStateObject } from '../../../../hooks/useStateObject';

import type {
    PerceptionApiData,
    PerceptionConnectionApiData,
    PerceptionConnectedToCalculus,
    PerceptionConnectionValue,
} from '../../../../commonTypes/perceptionApiTypes';
import type { DropdownEvent, EditCalculusState, PerceptionType, ValueFormState } from './types';
import { mapComments } from '../../../../shared/CommentModel';
import { useCarbonModalOpened } from '../../../../hooks';
import { SourceModel } from '../../../../pages/source/utils/sourceModel';
import { useSourceList } from '../../../../pages/source/utils/sourceListHooks';

const initialState: EditCalculusState = {
    perception: {},
    perceptionList: [],
    perceptionType: {},
    comment: '',
};

type PerceptionPutBody = {
    name: string;
    description: string;
    type: PerceptionType['key'];
    comment: string;
};

export type UpdatePerceptionConnectionBody = {
    value?: Partial<PerceptionConnectionValue>;
    probability?: Partial<PerceptionConnectionValue>;
};

export const perceptionTypes = [
    { key: 'cost-of-action', label: 'Cost of Action' },
    { key: 'benefit-of-action', label: 'Benefits of Action' },
    { key: 'benefit-of-restraint', label: 'Benefits of Restraint' },
    { key: 'cost-of-restraint', label: 'Cost of Restraint' },
];

/**
 * Hook for data fetching and submitting
 */
export const useCalculusElementApi = () => {
    const { loading, callApi } = useCRUDApi('/api/v1');

    const apiCalls = useMemo(
        () => ({
            async updatePerception(id: string, putBody: PerceptionPutBody) {
                try {
                    await callApi(`/perceptions/${id}`, 'put', putBody);
                    return {
                        isOk: true,
                        error: null,
                    };
                } catch (error) {
                    console.error(error);
                    return {
                        isOk: false,
                        error,
                    };
                }
            },
            async createPerceptionConnection(calculusId: string, perceptionId: string) {
                try {
                    const data: { connection_id: string } = await callApi('/connections', 'post', { calculus_id: calculusId, perception_id: perceptionId });
                    return {
                        isOk: true,
                        data,
                        error: null,
                    } as const;
                } catch (error) {
                    console.error(error);
                    return {
                        isOk: false,
                        data: null,
                        error,
                    } as const;
                }
            },
            async updatePerceptionConnection(connectionId: string, body: UpdatePerceptionConnectionBody) {
                try {
                    if (!body.value && !body.probability) {
                        return {
                            isOk: false,
                            error: null,
                        } as const;
                    }
                    await callApi(`/connections/${connectionId}`, 'put', {
                        ...body,
                    });
                    return {
                        isOk: true,
                        error: null,
                    } as const;
                } catch (error) {
                    console.error(error);
                    return {
                        isOk: false,
                        error,
                    } as const;
                }
            },
            async fetchPerceptionConnection(connectionId: string) {
                try {
                    const data: PerceptionConnectionApiData = await callApi(`/connections/${connectionId}`, 'get');
                    return {
                        isOk: true,
                        data,
                        error: null,
                    } as const;
                } catch (error) {
                    console.error(error);
                    return {
                        isOk: false,
                        data: null,
                        error,
                    } as const;
                }
            },
            async fetchPerception(id: string) {
                try {
                    const data: PerceptionApiData = await callApi(`/perceptions/${id}`, 'get');
                    return {
                        isOk: true,
                        data,
                        error: null,
                    } as const;
                } catch (error) {
                    console.error(error);
                    return {
                        isOk: false,
                        data: null,
                        error,
                    } as const;
                }
            },
        }),
        [callApi]
    );

    return { isLoading: loading, ...apiCalls };
};

const validateEditCalculusElementState = (state: EditCalculusState) => {
    if (!state.perception || !state.perception.name) {
        return false;
    }
    return true;
};
/**
 * State for top portion of form
 */
export const useEditCalculusElementState = () => {
    const [state, setState] = useStateObject<EditCalculusState>(initialState, validateEditCalculusElementState);

    const handlers = useMemo(
        () => ({
            handleSelectPerception({ selectedItem }: DropdownEvent<PerceptionConnectedToCalculus>) {
                setState({ perception: selectedItem });
            },
            handleCommentChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
                setState({ comment: e.target.value });
            },
            insertState(stateUpdate: Partial<EditCalculusState>) {
                setState(stateUpdate);
            },
            resetState() {
                setState(initialState);
            },
        }),
        [setState]
    );

    return {
        state,
        ...handlers,
    } as const;
};

const initialFormState = {
    quantity: 0,
    link: null,
    comment: '',
    userUpdated: false,
    commentList: [],
};

/**
 * State for probability and value forms
 */
export const useValueFormState = () => {
    const [state, setState] = useStateObject<ValueFormState>(initialFormState, (formState) => {
        const quantity = Number(formState.quantity);
        console.log(quantity);
        if (isNaN(quantity) || quantity < 0 || quantity > 100) {
            return false;
        }
        return true;
    });
    // Not actual link list - list of links
    const [linkList, setLinkList] = useState<SourceModel[]>([]);
    const handlers = useMemo(
        () => ({
            resetState() {
                setState(initialFormState);
            },
            handleQuantityChange(_e: React.ChangeEvent<HTMLInputElement>, { value }: { value: string | number }) {
                const update = typeof value === 'string' ? parseInt(value, 10) : value;
                setState({ userUpdated: true, quantity: isNaN(update) ? 0 : update });
                // setInvalidInputs({ quantity: false });
            },
            handleCommentChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
                setState({ userUpdated: true, comment: e.target.value });
            },
            insertComments(stateUpdate: ValueFormState['commentList']) {
                setState({ commentList: stateUpdate });
            },
        }),
        [setState]
    );

    const [linksById, setLinksById] = useState<{ [linkId: string]: SourceModel }>({});

    const { loading: sourcesLoading, allSources } = useSourceList({ fetchAll: true });

    // if sources are done fetching update linksById with all sources
    useCarbonModalOpened(!sourcesLoading, () => {
        const mappedSources: { [id: string]: SourceModel } = allSources.reduce(
            (acc: any, curr: SourceModel) => ({
                ...acc,
                [curr.id]: curr,
            }),
            {}
        );
        if (Array.isArray(allSources) && allSources.length > 0) {
            setLinkList(allSources);
        }
        setLinksById(mappedSources);
    });

    const handleLinkChange = ({ selectedItem }: DropdownEvent<SourceModel>) => {
        setState({ userUpdated: true, link: selectedItem });
    };

    const insertValueState = (data: PerceptionConnectionValue[]) => {
        if (Array.isArray(allSources) && allSources.length > 0) {
            setLinkList(allSources);
        }
        const currentValue = data[0] ?? null;
        if (!currentValue) return;
        const comments = data || [];
        setState({
            quantity: currentValue.quantity,
            link: linksById[currentValue.link ?? ''] ?? null,
            comment: '',
            commentList: mapComments(
                comments.map((entry) => ({
                    ...entry,
                    id: entry.id,
                    comment_id: entry.id,
                    value: entry.quantity,
                    updated: entry.created.updated,
                    user: { user_id: entry.created.id, name: entry.created.name },
                    comment: entry.comment,
                    created: entry.created.created,
                }))
            ),
        });
    };

    return {
        state,
        setState,
        linkList,
        handleLinkChange,
        insertValueState,
        ...handlers,
    } as const;
};
