import React from 'react'; 
import { 
    SideNav, 
    SideNavItems,
    SideNavLink 
} from '@carbon/react'

export const SideNavUtil = ({ sideNav, sideNavItems, sideNavLink }) => {
    return (
        <SideNav {...sideNav} aria-label='side-nav'>
            <SideNavItems {...sideNavItems}>
                <SideNavLink {...sideNavLink}>
                    {/* may need another sub component other than nav link to pass component*/ }
                </SideNavLink>
            </SideNavItems>
        </SideNav>
    )
}