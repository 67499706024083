import { parseISO, formatISO9075 } from 'date-fns';
import { ReportTypeListItem, ReportType } from './types';
import { Tag } from '@carbon/react';

export type TReportModel = Omit<ReportModel, 'getExportColumns'>;

export class ReportModel {
    id = '';
    name = '';
    description = '';
    type: ReportType = 'analysts-report';
    calculus_ids = [];
    typeLabel: JSX.Element;
    createdBy = '';
    created = '';
    updated = '';

    constructor(data: Partial<ReportModel>) {
        Object.assign(this, data);
        this.created = data?.created ? formatISO9075(parseISO(data?.created || '')) : '';
        this.updated = data?.updated ? formatISO9075(parseISO(data?.updated || '')) : '';
        const label = REPORT_TYPE_LIST.find((i) => i.id === this.type)?.text || "Analyst's Report";
        this.typeLabel = (
            <Tag className="some-class" size="sm">
                {label}
            </Tag>
        );
    }

    getExportColumns() {
        return [
            { field: 'id', title: 'Id' },
            { field: 'name', title: 'Name' },
            { field: 'description', title: 'Description' },
            { field: 'type', title: 'Type' },
            { field: 'created', title: 'Created' },
            { field: 'createdBy', title: 'Created By' },
            { field: 'updated', title: 'Updated' },
        ];
    }
}

export const REPORT_TYPE_LIST: ReportTypeListItem[] = [
    { id: 'analysts-report', text: "Analyst's Report" },
    { id: 'planners-report', text: "Planner's Report" },
    { id: 'bibliography', text: 'Bibiliography' },
    { id: 'narrative', text: 'Narrative Report' },
];

export const mapReports = (rows: any[]) => rows.map((r) => new ReportModel(r));
