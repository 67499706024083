import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@carbon/react';

export const TabsLayout = ({ 
    tabs={},
    tabList = {}, 
    tab = {}, 
    titles = [], 
    tabPanels = {}, 
    tabPanel = {}, 
    descriptions = []
}) => {
    return (
        <Tabs {...tabs}>
            <TabListUtil tabList={tabList} tab={tab} titles={titles} />
            <TabPanelsUtil tabPanels={tabPanels} tabPanel={tabPanel} descriptions={descriptions} />
        </Tabs>
    )
}

const TabListUtil = ({ tabList, tab, titles }) => {
    const tabListProps = {
        'aria-label': 'Tab',
        ...tabList
    }
    
    return (
        <TabList { ...tabListProps } >
            { titles.map( (title, i) => (
                <Tab {...tab} key={i} children={title}/>
            ) ) }
        </TabList>
    )
}
const TabPanelsUtil = ({ tabPanels, tabPanel, descriptions }) => {
    return (
        <TabPanels {...tabPanels}>
            {descriptions.map((child, i) => (
                <TabPanel {...tabPanel} key={i} children={child} />
            ) ) }
        </TabPanels>
    )
}