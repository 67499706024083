import React, { useState } from 'react';
import {Binoculars} from '@carbon/icons-react';
import { Popover, PopoverContent } from '@carbon/react';
import { IconButtonUtil } from './buttons/IconButtonUtil';
export const Tracker = () => {
    const [open, setOpen] = useState(false);

    return (
            <Popover open={open} align='top-left' className='position-fixed bottom-0 start-0'>
                <IconButtonUtil 
                    iconButton={{
                        children:<Binoculars/>,
                        onClick:()=>{setOpen(!open)},
                        label:'Cookie Policy',
                        align:'right'
                    }}
                />
                <PopoverContent className='p-3 mx-4'>
                    <h3 className='mb-1'>Cookie Policy</h3>
                    <p>
                    This website uses cookies to make your browsing experience better.
                    By using this site, you agree to this website's use of cookies.
                    </p>
                </PopoverContent>
            </Popover>
    )
}