import { useState } from 'react';
import { DocumentDownload, Edit } from '@carbon/icons-react';
import { Button, Modal, ComboBox } from '@carbon/react';
import { atom, useAtom } from 'jotai';

import { Header, IBreadcrumItem } from '../../components/Header/Header';
import { PageList } from '../../components/pageList';
import { SourceModal } from '../../modals/sources/SourceModal';
import { exportUtility } from '../../utils/export';

import { useSourceList } from './utils/sourceListHooks';
import { SourceModel } from './utils/sourceModel';
import { useSourceListState } from './utils/useSourceListState';
import { compareNames } from '../../helpers/dataTableSort';
import { useEntities } from '../../modals/entities/EntitiesHooks';
import { EntityModel } from '../../modals/entities/EntityModel';
import { DropdownEvent } from '../perception/utils/types';

import './SourceList.scss';

const emptyEntityModel = new EntityModel({});

export const selectedEntityAtom = atom<EntityModel>(emptyEntityModel);

const sourceListTableHeaders = [
    { header: 'Title', key: 'title' },
    { header: 'Source Name', key: 'name' },
    { header: 'Date of Article', key: 'dateOfArticle' },
    { header: 'Link', key: 'linkElement' },
    // { header: 'Has Attachments', key: 'hasAttachmentLabel' },
    { header: 'Created On', key: 'createdOn' },
    { header: 'Prepared By', key: 'createdBy' },
];

const BREADCRUMBS: IBreadcrumItem[] = [
    {
        label: 'Sources',
    },
];

export const SourceList = () => {
    const [selectedEntity, setSelectedEntity] = useAtom(selectedEntityAtom);
    const { loading, filteredSources, fetchFilteredSources, callApi } = useSourceList({ entity: selectedEntity });
    const { sourceToEdit, setSourceToEdit, sourceModalOpen, setSourceModalOpen } = useSourceListState();
    const [rowsToDelete, setRowsToDelete] = useState<string[]>([]);
    const { entities } = useEntities();

    const onEdit = (id: string) => {
        setSourceToEdit(filteredSources.find((p) => p.id === id));
        setSourceModalOpen(true);
    };

    const onDelete = async () => {
        await Promise.all(rowsToDelete.map((id) => callApi('/sources/' + id, 'DELETE')));

        fetchFilteredSources(selectedEntity.id);
        setRowsToDelete([]);
    };

    const onOpenDeleteModal = (rows: any[]) => {
        setRowsToDelete(rows.map((row) => row.id));
    };

    const onClose = (source?: string) => {
        if (source === 'new' || source === 'edit') {
            fetchFilteredSources(selectedEntity.id);
        }
        setSourceModalOpen(false);
    };

    const onMainAction = () => {
        setSourceToEdit(new SourceModel({}));
        setSourceModalOpen(true);
    };

    const onExport = () => {
        exportUtility({ data: filteredSources, fileName: 'sources', keys: filteredSources[0]?.getExportColumns() });
    };

    return (
        <section className="source-list-section">
            <Header title="Sources List" breadcrumbs={BREADCRUMBS}>
                <Button kind="tertiary" size="md" renderIcon={DocumentDownload} disabled={filteredSources.length === 0} onClick={onExport}>
                    Export Data
                </Button>
            </Header>
            <div className="flex-grow-1 p-4 overflow-auto source-list-body">
                <div className="col-4 mb-3">
                    <ComboBox
                        id="source-list-entity"
                        titleText="Entity"
                        label="Select an entity"
                        placeholder="Select an entity to display list of sources"
                        items={entities}
                        itemToString={(item: EntityModel) => (item ? item.name : '')}
                        selectedItem={selectedEntity}
                        onChange={(e: DropdownEvent<EntityModel>) => setSelectedEntity(e.selectedItem ?? emptyEntityModel)}
                        invalidText="Entity is required"
                    />
                </div>
                {selectedEntity.id ? (
                    <PageList
                        editIcon={<Edit />}
                        onEdit={onEdit}
                        onDeleteRows={onOpenDeleteModal}
                        loading={loading}
                        rows={filteredSources}
                        headers={sourceListTableHeaders}
                        mainActionLabel="New Source"
                        onMainAction={onMainAction}
                        filter={{
                            supported: true,
                            filterBy: [
                                // { key: 'hasAttachmentLabel', name: 'Has Attachment' },
                                { key: 'createdBy', name: 'Prepared By' },
                            ],
                        }}
                        customCompare={{ createdBy: compareNames }}
                    />
                ) : null}
            </div>
            {sourceModalOpen ? <SourceModal open={sourceModalOpen} source={sourceToEdit} onClose={onClose} entityId={selectedEntity.id} /> : null}
            {rowsToDelete.length > 0 ? (
                <Modal
                    open
                    danger
                    modalHeading={`Are you sure you want to delete ${rowsToDelete.length} row${rowsToDelete.length > 1 ? 's' : ''}?`}
                    modalLabel={`Delete Source${rowsToDelete.length > 1 ? 's' : ''}`}
                    primaryButtonText="Delete"
                    secondaryButtonText="Cancel"
                    onRequestClose={() => setRowsToDelete([])}
                    onRequestSubmit={onDelete}
                />
            ) : null}
        </section>
    );
};
