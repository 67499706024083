import { parseISO, formatISO9075 } from 'date-fns';

export const mapBehaviors = (behaviors: any[]) => behaviors.map((e) => new BehaviorModel(e));

export class BehaviorModel {
    id = '';
    name = '';
    description = '';
    user = '';
    createdBy = '';
    created = '';
    updated = '';
    used = 0;
    constructor(data: Partial<BehaviorModel>) {
        Object.assign(this, data);
        this.createdBy = this.user;
        this.updated = data?.updated ? formatISO9075(parseISO(data?.updated || '')) : '';
    }
}
