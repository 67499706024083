import axios from 'axios';

/**
 * returns true if the user is logged else false
 */
export const getLoggedIn = async () => {
    try {
        const { data } = await axios.get('/api/loggedIn');
        return !!data.authorized;
    } catch (error) {
        return false;
    }
};
