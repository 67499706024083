import React, { useEffect, useState } from 'react';
import { EmailField, PasswordField, ButtonUtil, LinkUtil, PostResponseNotification } from '../../utils';
import { Email, Password } from '@carbon/icons-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Checkbox } from '@carbon/react';

export const LoginForm = () => {
    const [email, setEmail] = useState({ value: '' });
    const [password, setPassword] = useState({ value: '' });
    const [notificationData, setNotificationData] = useState({});
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const location = useLocation();

    useEffect(() => {
        (async () => {
            await global.GetCSRFToken();
        })();
    }, []);

    useEffect(() => {
        if (email.invalid === false && email.value.length > 0 && password.value.length > 0) {
            setButtonDisabled(false);
        }
    }, [email.value, password.value, email.invalid]);

    const navigate = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();
        setButtonDisabled(true);
        (async () => {
            try {
                const res = await global.LibAxios.post(
                    '/api/login',
                    {
                        email: email.value,
                        password: password.value,
                        redirectURL: location.state,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: true,
                        credentials: 'include',
                    }
                );

                if (res.data.status === 'success' && res.data.tfa) {
                    navigate('/authentication-options');
                } else if (res.data.status === 'success') {
                    if (location.state) {
                        navigate(location.state);
                    } else {
                        navigate('/');
                    }
                }
                setNotificationData(res);
            } catch (err) {
                setNotificationData(err.response);
                console.log(err);
            } finally {
                setTimeout(() => {
                    setButtonDisabled(false);
                }, '1000');
            }
        })();
    };

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    marginBottom: 15,
                    marginTop: 32,
                }}
            >
                <Email style={{ width: 30 }} />
                <EmailField
                    textInput={{
                        ...email,
                        className: '',
                    }}
                    type="emailDomain"
                    validation
                    setState={setEmail}
                />
            </div>
            <div
                style={{
                    display: 'flex',
                }}
            >
                <Password style={{ width: 30 }} />
                <PasswordField
                    passwordInput={{
                        ...password,
                        className: '',
                        style: {},
                        labelText: <>Password</>,
                    }}
                    type="password"
                    setState={setPassword}
                    validation={false}
                />
            </div>
            <div className="d-flex justify-content-end align-items-center mt-3">
                <LinkUtil
                    pathKey="forgotPassword"
                    link={{
                        children: 'Forgot Password?',
                        className: '',
                    }}
                />
            </div>
            <p className="ms-4 mt-4 small text-muted">
                By signing in you accept the
                <LinkUtil
                    pathKey="tos"
                    link={{
                        children: ' Terms of Use and acknowledge the Privacy Policy and Cookie Policy.',
                    }}
                />
            </p>
            <div className="d-flex justify-content-end mt-4">
                <ButtonUtil
                    button={{
                        children: 'Login',
                        onClick: handleSubmit,
                        disabled: buttonDisabled,
                        type: 'submit',
                    }}
                />
            </div>
            <PostResponseNotification response={notificationData} />
        </>
    );
};
