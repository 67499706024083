import React from 'react';
import { Select, SelectItem } from '@carbon/react';

export const SelectUtil = ({ 
    select={}, 
    type='select', 
    options={}, 
    placeholderItem={}, 
    setState=()=>{}
}) => {

    const handleChange = (e) => {
        let invalid = false
        if(e.target.value==='placeholder-item') {
            invalid=true
        }
        setState({ value:e.target.value, invalid: invalid })
    }

    const selectProps={ 
        id: `${type}${Math.random().toString()}`, 
        value: '',
        labelText:'Account Type*',
        // hideLabel: true,
        onChange: (e) => handleChange(e) ,
        ...select
    }
    
    const placeholderItemProps = {
        hidden:false, 
        value: 'placeholder-item', 
        text: 'Choose an option', 
        ...placeholderItem
    }

    return (
        <Select {...selectProps} defaultValue={select.defaultValue}>
            <SelectItem {...placeholderItemProps}/>
            {Object.values(options).map( o => <SelectItem key={o} text={o} value={o} /> )}
        </Select>
    )
}