import React from 'react';
import {
    Accordion,
    AccordionItem,
    DataTable,
    DataTableSkeleton,
    SkeletonText,
    Table,
    TableBody,
    TableHead,
    TableHeader,
    TableRow,
    TableCell,
    Button,
} from '@carbon/react';
import { Edit, TrashCan, Information } from '@carbon/react/icons';

import { HoverTooltip } from '../hoverTooltip/HoverTooltip';

import type { AccordionTableProps } from './types';

import './AccordionTable.scss';

export const AccordionTable = ({
    title,
    tableHeaders,
    tableRows,
    children,
    id = 'accordion-table',
    emptyRows = 0,
    emptyPlaceholder = null,
    onEditRow = null,
    onDeleteRow = null,
    isSortable = false,
    className = '',
    dataTableClassName = '',
    isLoading = false,
    startExpanded = false,
    align = 'end',
    button = null,
    customCompare = {},
}: AccordionTableProps) => {
    if (isLoading) {
        return (
            <Accordion align={align} className="accordion-table">
                <AccordionItem title={<SkeletonText />} className={`accordion-item w-100 ${className}`} open={startExpanded}>
                    <DataTableSkeleton headers={tableHeaders} id={`${id}_loading`} rowCount={3} showHeader={false} showToolbar={false} />
                </AccordionItem>
            </Accordion>
        );
    }
    return (
        <Accordion align={align} className="accordion-table">
            <AccordionItem title={title} className={`accordion-item w-100 ps-0 pe-0 ${className}`} open={startExpanded}>
                <div className="accordion-heading-button">{button}</div>
                <DataTable
                    rows={tableRows}
                    headers={tableHeaders}
                    isSortable={isSortable}
                    id={id}
                    className={`accordion-data-table ${dataTableClassName}`}
                    sortRow={(a: any, b: any, options: any) => {
                        if (customCompare[options.key]) {
                            if (options.sortDirection === 'ASC') {
                                return customCompare[options.key](a, b, options.locale);
                            }
                            return customCompare[options.key](b, a, options.locale);
                        }
                        if (options.sortDirection === 'ASC') {
                            return options.compare(a, b, options.locale);
                        }
                        return options.compare(b, a, options.locale);
                    }}
                >
                    {({ rows, headers, getHeaderProps, getRowProps, getTableProps }: any) => (
                        <Table className="accordion-table-datatable" {...getTableProps()}>
                            <TableHead className="accordion-table-head">
                                <TableRow>
                                    {headers.map((header: any) => (
                                        <TableHeader key={header.key} {...getHeaderProps({ header })}>
                                            <div className="d-flex align-items-center">
                                                {header.header}
                                                {header.tooltip ? (
                                                    <HoverTooltip className="ms-2" tooltipContent={header.tooltip}>
                                                        <Information />
                                                    </HoverTooltip>
                                                ) : null}
                                            </div>
                                        </TableHeader>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody className="accordion-table-body">
                                {rows.length === 0 && emptyPlaceholder ? (
                                    <TableRow key="empty-row">
                                        <td className="empty-row" colSpan={tableHeaders.length}>
                                            {emptyPlaceholder}
                                        </td>
                                    </TableRow>
                                ) : null}
                                {rows.map((row: any, rowIdx: number) => (
                                    <TableRow key={`${row.id}`} {...getRowProps({ row })}>
                                        {row.cells.map((cell: any) => (
                                            <React.Fragment key={cell.id}>
                                                {cell.info.header === 'edit' ? (
                                                    <TableCell key={cell.id}>
                                                        <div className="action-buttons">
                                                            {onEditRow ? (
                                                                <Button
                                                                    renderIcon={Edit}
                                                                    kind="ghost"
                                                                    className="edit-button"
                                                                    iconDescription="Pencil"
                                                                    size="sm"
                                                                    onClick={() => onEditRow(row, rowIdx)}
                                                                />
                                                            ) : null}
                                                            {onDeleteRow ? (
                                                                <Button
                                                                    renderIcon={TrashCan}
                                                                    kind="ghost"
                                                                    className="edit-button"
                                                                    iconDescription="Trash"
                                                                    size="sm"
                                                                    onClick={() => onDeleteRow(row, rowIdx)}
                                                                />
                                                            ) : null}
                                                        </div>
                                                    </TableCell>
                                                ) : (
                                                    <TableCell key={cell.id}>{cell.value}</TableCell>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </TableRow>
                                ))}
                                {emptyRows > 0
                                    ? [...Array(emptyRows)].map((_v, i) => (
                                          <TableRow key={`empty-row-${i}`}>
                                              <td className="empty-row" colSpan={tableHeaders.length}></td>
                                          </TableRow>
                                      ))
                                    : null}
                            </TableBody>
                        </Table>
                    )}
                </DataTable>
                {children}
            </AccordionItem>
        </Accordion>
    );
};
