import { useState, useEffect } from 'react';

const checkIsHidden = () => document && document.hidden;

export const useWindowIsVisible = () => {
    const [isVisible, setIsVisibile] = useState(!checkIsHidden());

    useEffect(() => {
        const visbilityHandler = () => {
            if (checkIsHidden()) {
                setIsVisibile(false);
            } else {
                setIsVisibile(true);
            }
        };

        document?.addEventListener('visibilitychange', visbilityHandler);

        return () => {
            document?.removeEventListener('visibilitychange', visbilityHandler);
        };
    }, []);

    return isVisible;
};
