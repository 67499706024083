import React, { useState } from 'react';
import { Button, DataTableSkeleton, TextInput } from '@carbon/react';
import { atom, useSetAtom } from 'jotai';

import { FilteredTable } from '../../components/filtered-table/FilteredTable';
import { useEntities } from './EntitiesHooks';
import { EntityModel } from './EntityModel';
import { compareNames } from '../../helpers/dataTableSort';

const TABLE_HEADERS = [
    {
        key: 'name',
        header: 'Name',
    },
    // {
    //     key: 'used',
    //     header: 'In Use',
    // },
    {
        key: 'createdBy',
        header: 'Created By',
    },
    {
        key: 'updated',
        header: 'Last Modified',
    },
];

export const deleteEntityIdAtom = atom<null | string>(null);

const EntitiesModal = () => {
    const { selectedEntity, entities, error, loading, fetchEntities, setSelectedEntity, callApi } = useEntities();
    const setDeleteId = useSetAtom(deleteEntityIdAtom);
    const [invalidEntityName, setInvalidEntityName] = useState(false)
    const [currentEntityName, setCurrentEntityName] = useState('')

    const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedEntity(new EntityModel({ ...selectedEntity, name: e.target.value.trim() }));
        setInvalidEntityName(false)
    };

    const onSave = async () => {
        let url = '/entities',
            method = 'post';

        if (selectedEntity?.id) {
            url = '/entities/' + selectedEntity?.id;
            method = 'put';
        }

        const entityNames = entities.map((entity) => (entity.name))

        const nameExists = selectedEntity?.id ?
        entityNames.filter((entity) => entity !== currentEntityName).includes(selectedEntity?.name) 
        : entityNames.includes(selectedEntity?.name ?? '')

        if (nameExists || selectedEntity?.name === '') {
            setInvalidEntityName(true)
            return
        }

        if (!invalidEntityName) {
            await callApi(url, method, selectedEntity);
            await fetchEntities();
            setSelectedEntity(undefined);
        }
    };

    const onEdit = (id: string) => {
        let entity = entities.find((e) => e.id === id)
        setInvalidEntityName(false)
        setSelectedEntity(entities.find((e) => e.id === id));
        setCurrentEntityName(entity?.name ?? '')
    };

    const onNew = () => {
        setInvalidEntityName(false)
        setSelectedEntity(new EntityModel({}));
    };

    if (loading) return <DataTableSkeleton headers={TABLE_HEADERS} />;

    return (
        <div className={selectedEntity ? 'has-form' : ''}>
            <FilteredTable
                headers={TABLE_HEADERS}
                data={entities}
                onEdit={onEdit}
                onDelete={setDeleteId}
                loading={loading}
                onMainAction={onNew}
                mainActionLabel="New Entity"
                customCompare={{ createdBy: compareNames }}
            ></FilteredTable>
            {selectedEntity && (
                <>
                    <div className="edit-item-form">
                        <h2 className="mb-2">{selectedEntity.id === '' ? 'New ' : 'Edit '} Entity</h2>
                        <div className="d-flex align-items-end">
                            <TextInput id="name" type="text" maxLength="255" labelText="Entity Name" value={selectedEntity.name.trim()} onChange={onNameChange} invalid = {invalidEntityName} invalidText = {selectedEntity.name === '' ? "Please try an entity name that is not empty" : "The entity name must be unique"}/>
                            <div className = {invalidEntityName ? 'moveEntityModalButtonsUp' : ''}>
                            <Button kind="primary" size="md" className="mx-3" onClick={onSave}>
                                {selectedEntity.id ? 'Update' : 'Create'}
                            </Button>
                            <Button kind="tertiary" size="md" onClick={() => setSelectedEntity(undefined)}>
                                Cancel
                            </Button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default EntitiesModal;
