import React, { useEffect, useState } from 'react';
import { Loading } from '@carbon/react';
import { ButtonUtil } from '.';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { TileGridLayout } from './layout/TileGridLayout';
import { useClearPreviousCalculusId } from '../pages/calculus/CalculusDecisionDetails/utils/calculusDecisionDetailsHooks';
import { ExpirationModal } from '../modals/expiration/ExpirationModal';

export function PrivateRoute(props) {
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const { pathname } = location;

    useClearPreviousCalculusId({
        enabled: !(pathname.includes('/calculi') && params.id),
    });

    useEffect(() => {
        (async () => {
            await global.GetCSRFToken();
            const res = await global.LibAxios.post(
                '/api/verifyJWT',
                {
                    accountTypes: props.accountTypes,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                    credentials: 'include',
                }
            );
            if (res.data.authorized === false) {
                navigate('/account-services');
            }
            setTimeout(() => {
                setIsLoggedIn(res.data.authorized);
            }, 500);
        })();
    }, []);

    if (isLoggedIn === null) {
        navigate('/account-services');
    } else if (isLoggedIn) {
        //jwt is valid and the account type matches
        return (
            <>
                {props.element}
                <ExpirationModal />
            </>
        );
    } else if (!isLoggedIn) {
        navigate('/account-services');
        // return (
        //     <TileGridLayout
        //         grid={{
        //             style: { height: '90vh' },
        //         }}
        //         column={{
        //             lg: { span: 6, offset: 5 },
        //             md: { span: 6, offset: 1 },
        //             sm: 4,
        //             className: 'h-100 d-flex align-items-center',
        //         }}
        //         tile={{
        //             className: 'rounded w-100 text-center px-5 pb-5',
        //             style: {
        //                 // height: '50vh',
        //                 // backgroundColor:''
        //             },
        //             children: (
        //                 <>
        //                     <div className="d-flex justify-content-center border-bottom border-2">
        //                         <div style={{ maxWidth: '4em' }}>
        //                             <img className="h-auto w-100" src="/assets/Logos/guardian_logo.png" alt="Logo" />
        //                         </div>
        //                     </div>
        //                     <h3 className="fw-bold mt-4">Login Required</h3>
        //                     <p className="mt-4">You must be logged in to access this page.</p>
        //                     <ButtonUtil
        //                         button={{
        //                             children: 'Log In',
        //                             className: 'mt-5 rounded',
        //                             onClick: () => {
        //                                 navigate('/account-services', { state: location.pathname });
        //                             },
        //                             //s
        //                         }}
        //                     />
        //                 </>
        //             ),
        //         }}
        //     />
        // );
    }
    return null;
}
