import React from 'react';
import { Button } from '@carbon/react';
import type { DecisionCalculiProps } from './types';
import { useFetchCalculusDecisionDetails } from '../../pages/calculus/CalculusDecisionDetails/utils/calculusDecisionDetailsHooks';
import { Link } from 'react-router-dom';

export const DecisionCalculi = ({ calculusId, onEdit }: DecisionCalculiProps) => {
    const { calculusDetails, costOfAction, benefitsOfAction, benefitsOfRestraint, costsOfRestraint } = useFetchCalculusDecisionDetails({
        calculusId,
    });

    const renderRow = (perceptionId: string, perceptionName: string, entity: string) => {
        return (
            <div className="row row-cells" key={perceptionId}>
                <div className="col-7 col-cells">
                    <Link to={`/perceptions/${perceptionId}`} className="text-decoration-none">
                        {perceptionName}
                    </Link>
                </div>
                <div className="col-5 col-cells">{entity}</div>
            </div>
        );
    };

    const noPerceptions = () => {
        return (
            <div className="row row-cells">
                <div className="col-12 col-cells">No Perceptions</div>
            </div>
        );
    };

    return (
        <div className="calculi col-sm">
            <div className="mb-2">
                <h5>{calculusDetails?.name}</h5>
            </div>
            <div className="container">
                <div className="row mb-1">CoA ({costOfAction.entries.length})</div>
                {costOfAction.entries.length > 0
                    ? costOfAction.entries.map((entry) => renderRow(entry.id, entry.perceptionName, calculusDetails?.entity.name ?? ''))
                    : noPerceptions()}
            </div>
            <div className="container">
                <div className="row mb-1">BoA ({benefitsOfAction.entries.length})</div>
                {benefitsOfAction.entries.length > 0
                    ? benefitsOfAction.entries.map((entry) => renderRow(entry.id, entry.perceptionName, calculusDetails?.entity.name ?? ''))
                    : noPerceptions()}
            </div>
            <div className="container">
                <div className="row mb-1">CoR ({costsOfRestraint.entries.length})</div>
                {costsOfRestraint.entries.length > 0
                    ? costsOfRestraint.entries.map((entry) => renderRow(entry.id, entry.perceptionName, calculusDetails?.entity.name ?? ''))
                    : noPerceptions()}
            </div>
            <div className="container">
                <div className="row mb-1">BoR ({benefitsOfRestraint.entries.length})</div>
                {benefitsOfRestraint.entries.length > 0
                    ? benefitsOfRestraint.entries.map((entry) => renderRow(entry.id, entry.perceptionName, calculusDetails?.entity.name ?? ''))
                    : noPerceptions()}
            </div>
            <Button size="sm" kind="tertiary" className="mt-2" onClick={() => onEdit(calculusId)}>
                Edit Calculus
            </Button>
        </div>
    );
};
