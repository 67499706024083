import { useState } from 'react';

import { SourceModel } from './sourceModel';

export type SourceListState = {
    isNewSourceModalOpen: boolean;
    editId: string;
    sourceToEdit: Partial<SourceModel>;
};
export const useSourceListState = () => {
    const [sourceToEdit, setSourceToEdit] = useState<SourceModel>();
    const [sourceModalOpen, setSourceModalOpen] = useState(false);

    return {
        sourceToEdit,
        sourceModalOpen,
        setSourceToEdit,
        setSourceModalOpen,
    } as const;
};
