import { parseISO, formatISO9075 } from 'date-fns';

export class CommentModel {
    id = '';
    created = '';
    updated = '';
    user: { user_id: string; name: string } | undefined;
    targetId = '';
    comment = '';
    value = '';

    constructor(data: CommentModel) {
        Object.assign(this, data);
        this.updated = data?.updated ? formatISO9075(parseISO(data?.updated || '')) : '';
        this.created = data?.created ? formatISO9075(parseISO(data?.created || '')) : '';
    }
}

export const mapComments = (rows: any[]) => rows.map((r) => new CommentModel(r));
