import { parseISO, formatISO9075 } from 'date-fns';
import { PerceptionTypeListItem, PerceptionType } from './types';
import { Tag } from '@carbon/react';

export type TPerceptionModel = Omit<PerceptionModel, 'getExportColumns'>;

export class PerceptionModel {
    id = '';
    name = '';
    description = '';
    type: PerceptionType = 'cost-of-action';
    typeLabel: JSX.Element;
    createdBy = '';
    created = '';
    updated = '';
    source = '';

    constructor(data: Partial<PerceptionModel>) {
        Object.assign(this, data);
        this.created = data?.created ? formatISO9075(parseISO(data?.created || '')) : '';
        this.updated = data?.updated ? formatISO9075(parseISO(data?.updated || '')) : '';
        const label = PERCEPTION_TYPE_LIST.find((i) => i.id === this.type)?.text || 'Costs of Action';
        this.typeLabel = (
            <Tag className="some-class" size="sm">
                {label}
            </Tag>
        );
    }

    getExportColumns() {
        return [
            { field: 'id', title: 'Id' },
            { field: 'name', title: 'Name' },
            { field: 'description', title: 'Description' },
            { field: 'type', title: 'Type' },
            { field: 'created', title: 'Created' },
            { field: 'createdBy', title: 'Created By' },
            { field: 'updated', title: 'Updated' },
        ];
    }
}

export const PERCEPTION_TYPE_LIST: PerceptionTypeListItem[] = [
    { id: 'benefit-of-action', text: 'Benefits of Action' },
    { id: 'cost-of-action', text: 'Costs of Action' },
    { id: 'benefit-of-restraint', text: 'Benefits of Restraint' },
    { id: 'cost-of-restraint', text: 'Costs of Restraint' },
];

export const mapPerceptions = (rows: any[]) => rows.map((r) => new PerceptionModel(r));
