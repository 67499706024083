import { atom, useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useCRUDApi } from '../../hooks/useCRUDApi';
import { EntityModel, mapEntities } from './EntityModel';

export const loadingEntitiesAtom = atom(false);
export const entitiesAtom = atom<EntityModel[]>([]);

export const useEntities = () => {
    const { error, loading, callApi } = useCRUDApi('/api/v1');
    const [entities, setEntities] = useAtom(entitiesAtom);
    const [selectedEntity, setSelectedEntity] = useState<EntityModel>();

    const fetchEntities = useCallback(async () => {
        const resp = await callApi('/entities', 'get');
        const data = mapEntities(resp.rows);
        setEntities(data);
        return data;
    }, [callApi, setEntities]);

    useEffect(() => {
        fetchEntities();
    }, [fetchEntities]);

    return {
        selectedEntity,
        entities,
        error,
        loading,
        fetchEntities,
        setSelectedEntity,
        setEntities,
        callApi,
    };
};
