import { GatewayUserAccess, LicenseGlobal, WarningHex } from '@carbon/icons-react';
import { Button } from '@carbon/react';
import { AdminModal } from '../../modals/admin/AdminModal';
import './AdminTools.scss';
import { useAdminModalState } from '../../modals/admin/utils/useAdminModalState';
import { ReleaseNotes } from './ReleaseNotes';

export const AdminTools = () => {
    const { adminModalOpen, adminModalType, openAdminModal, setAdminModalOpen } = useAdminModalState();

    return (
        <div className="admin-page-container">
            <div className="admin-tools-container">
                <Button kind="tertiary" size="lg" renderIcon={WarningHex} onClick={() => openAdminModal('entity')}>
                    Manage Entities
                </Button>
                <Button kind="tertiary" size="lg" renderIcon={LicenseGlobal} onClick={() => openAdminModal('objective')}>
                    Manage Objectives
                </Button>
                <Button kind="tertiary" size="lg" renderIcon={GatewayUserAccess} onClick={() => openAdminModal('behavior')}>
                    Manage Behaviors
                </Button>
                <AdminModal open={adminModalOpen} modalType={adminModalType} setOpen={setAdminModalOpen} />
            </div>
            <ReleaseNotes />
        </div>
    );
};
