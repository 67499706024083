import React, { useState, useEffect, useMemo } from 'react';
import { useToggle } from '../../../hooks';
import { useCRUDApi } from '../../../hooks/useCRUDApi';
import { useEditPerceptionState } from '../../../modals/perception/utils/usePerceptionModalState';
import type { PerceptionDetailsProps, PerceptionResponse, PerceptionData, Objective, AssociatedScenario, Comment, CalcComment } from './types';
import { PerceptionModel } from '../utils/PerceptionModel';

export const useFetchPerceptionDetails = (options: PerceptionDetailsProps) => {
    const { perceptionId } = options;
    const [perceptionData, setPerceptionData] = useState<PerceptionData>();
    const [objectives, setObjectives] = useState<Objective[]>([]);
    const [associatedScenarios, setAssociatedScenarios] = useState<AssociatedScenario[]>([]);
    const [comments, setComments] = useState<Comment[]>([]);
    const [calculusComments, setCalculusComments] = useState<CalcComment[]>([]);
    const [isLoading, toggleIsLoading] = useToggle(false);
    const { callApi, loading, error } = useCRUDApi('/api/v1');

    const fetchPerception = () => {
        async function fetchData() {
            try {
                const data: PerceptionResponse = await callApi(`/perceptions/${perceptionId}`, 'get');
                const calcComments = data.connections.rows.map((calcComment) => {
                    return { ...calcComment, typeValue: `${calcComment.type} (${calcComment.value})` };
                });
                setObjectives(data.objectives.rows);
                setAssociatedScenarios(data.associatedScenarios.rows);
                setCalculusComments(calcComments);
                setComments(data.comments.rows);
                setPerceptionData(data.selectedPerception.rows[0]);
            } catch (error) {
                console.error(error);
            } finally {
                toggleIsLoading(false);
            }
        }

        if (perceptionId) {
            fetchData();
            toggleIsLoading(true);
        }
    };

    useEffect(() => {
        fetchPerception();
    }, [perceptionId, toggleIsLoading]);

    return {
        objectives,
        associatedScenarios,
        comments,
        calculusComments,
        perceptionData,
        isLoading,
        fetchPerception,
    } as const;
};

export const usePerceptionDetailsState = () => {
    const { callApi, loading, error } = useCRUDApi('/api/v1');
    const [addComment, setAddComment] = useState(null);
    const [perceptionToEdit, setPerceptionToEdit] = useState<PerceptionModel>();

    const { editPerceptionModalOpen, setEditPerceptionModalOpen } = useEditPerceptionState();

    // Handlers
    const handlers = useMemo(
        () => ({
            handleOpenEditPerception: (e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                e.stopPropagation();
                setEditPerceptionModalOpen(true);
            },
            handleCloseEditPerception: () => setEditPerceptionModalOpen(false),
            handleEditRow: (table: string, row: any, _index: number) => {
                console.log('editing row: ', row);
                console.log('in table: ', table);
                switch (table) {
                    case 'comments-table':
                        console.log('call edit comments api');
                        break;
                    case 'objectives-table':
                        console.log('call edit objectives api');
                        break;
                    case 'associated-scenarios-table':
                        console.log('call edit associated scenarios api');
                }
            },
            handleDeleteRow: (table: string, row: any, _index: number) => {
                console.log('editing row: ', row);
                console.log('in table: ', table);
                switch (table) {
                    case 'comments-table': {
                        console.log('call delete comments api');
                        const res = callApi(`/comments/${row.id}`, 'delete');
                        console.log(res);
                        break;
                    }
                    case 'objectives-table':
                        console.log('call delete objectives api');
                        break;
                    case 'associated-scenarios-table':
                        console.log('call delete associated scenarios api');
                }
            },
            handleOpenAddCommentModal: (e: React.MouseEvent<HTMLButtonElement>, { table }: any) => {
                e.stopPropagation();
                e.preventDefault();
                console.log('add comment: ', table);
                setAddComment(table);
            },
            handleCloseAddCommentModal: () => setAddComment(null),
        }),
        [setEditPerceptionModalOpen]
    );

    return {
        editPerceptionModalOpen,
        addComment,
        perceptionToEdit,
        setPerceptionToEdit,
        setEditPerceptionModalOpen,
        ...handlers,
    };
};
