import React from 'react';
import { CopyButton } from '@carbon/react';

export const CopyButtonUtil = ({copyButton = {}, copyText = ''}) => {   
    const handleClick = () => { 
      /* Copy the text inside the text field */
      navigator.clipboard.writeText(copyText);
    };
    
    return <CopyButton onClick={handleClick} {...copyButton} /> 
}
