import React from 'react';
import { Button, Loading, Modal, Link } from '@carbon/react';
import { Add, Edit } from '@carbon/react/icons';
import { useParams } from 'react-router-dom';

import { AccordionTable } from '../../../components/accordionTable/AccordionTable';
import { AccordionWithButton } from '../../../components/accordionWithButton/AccordionWithButton';
import { EditCalculusElement } from '../../../modals/edit/EditCalculusElement/EditCalculusElement';
import { CalculusInputs } from '../../../modals/calculusInputs/CalculusInputs';
import { ScenarioModal } from '../../../modals/Scenario/ScenarioModal';
import { Header, IBreadcrumItem } from '../../../components/Header/Header';

import { useCalculusDecisionState, useFetchCalculusDecisionDetails, usePreviousCalculusId } from './utils/calculusDecisionDetailsHooks';
import type { CalculusAccordionTableProps } from './utils/types';

import './CalculusDecisionDetails.scss';
import { useToggle } from '../../../hooks';

const dataTableHeaders = [
    { header: 'Perception Name', key: 'perceptionName' },
    {
        header: 'Value',
        key: 'value',
        tooltip: (
            <div>
                <div>VH = 81-100(90)</div>
                <div>H = 61-80(70)</div>
                <div>M = 41-60(50)</div>
                <div>L = 21-40(30)</div>
                <div>VL = 0-20(10)</div>
            </div>
        ),
    },
    { header: 'Probability', key: 'probability' },
    { header: 'Weight', key: 'weight' },
    { header: 'Prepared by', key: 'preparedBy' },
    { header: '', key: 'edit', isSortable: false },
];

const BREADCRUMBS: IBreadcrumItem[] = [
    {
        label: 'Decision Calculi',
        url: '/calculi',
    },
];
const getEmptyRowCount = (mainTableLength: number, siblingTableLength: number) => Math.max(0, siblingTableLength - mainTableLength);

export const CalculusDecisionDetails = () => {
    const params = useParams();
    const calculusId = params.id ?? '';
    const previousCalculusId = usePreviousCalculusId(calculusId);
    const [editCalculusSummary, toggleEditCalculusSummary] = useToggle(false);

    const {
        isEditCalculusOpen,
        isEditScenarioOpen,
        addPerception,
        perceptionType,
        editRow,
        deleteRow,

        // handlers
        handleOpenEditCalculus,
        handleCloseEditCalculus,
        handleOpenEditScenario,
        handleCloseEditScenario,
        handleEditRow,
        handleDeleteRow,
        handleOpenPerceptionModal,
        handleClosePerceptionModal,
    } = useCalculusDecisionState();

    const {
        calculusDetails, // API data
        scenarioData,
        fetchCalculiPage,
        isLoading,
        costOfAction,
        benefitsOfAction,
        benefitsOfRestraint,
        costsOfRestraint,
        overallSummary,
        deletePerceptionConnection,
        handleUpdateScenario,
        handleUpdateCalculus,
    } = useFetchCalculusDecisionDetails({
        calculusId,
    });

    return (
        <div className="calculus-decision-details">
            {isLoading ? <Loading /> : null}
            <article className="calculus-decision-details-body">
                <Header
                    title={
                        <>
                            {previousCalculusId ? <Link href={`/calculi/${previousCalculusId}`}>Previous Calculus</Link> : null}
                            <div>{calculusDetails?.name || ''}</div>
                        </>
                    }
                    breadcrumbs={BREADCRUMBS}
                >
                    <Button className="ms-auto" kind="tertiary" renderIcon={Edit} onClick={handleOpenEditCalculus} iconDescription="edit pencil">
                        Edit Calculus
                    </Button>
                    {/* <Button className="ms-auto" size="sm" kind="tertiary" renderIcon={Document} iconDescription="Paper document">
                            Generate Report
                        </Button> */}
                </Header>
                <div className="m-4">
                    <AccordionWithButton
                        align="start"
                        title={
                            <div className="accordion-heading d-flex align-items-center">
                                <h4>{scenarioData?.name ?? ''}</h4>
                            </div>
                        }
                        className="accordion-item w-100"
                        open
                        button={
                            <Button
                                className="ms-auto button-with-left-image"
                                size="sm"
                                kind="ghost"
                                renderIcon={Edit}
                                iconDescription="edit pencil"
                                onClick={handleOpenEditScenario}
                            >
                                Edit Scenario
                            </Button>
                        }
                    >
                        <div className="accordion-grid">
                            <div>
                                <h5>Startegic Objective Summary</h5>
                                <p>{scenarioData?.objectiveSummary ?? ''}</p>
                            </div>
                            <div>
                                <h5>Scenario Description</h5>
                                <p>{scenarioData?.description ?? ''}</p>
                            </div>
                            <div>
                                <h5>Adversary Decision</h5>
                                <p>{scenarioData?.adversaryDecision ?? ''}</p>
                            </div>
                        </div>
                    </AccordionWithButton>
                </div>
                <section className="accordion-table-section m-4">
                    <div className="accordion-row mb-4">
                        <CalculusAccordionTable
                            tableTitle="Cost Of Action"
                            tableName="cost-of-action"
                            tableRows={costOfAction.entries}
                            onEditRow={handleEditRow}
                            onDeleteRow={handleDeleteRow}
                            onOpenAddPerception={handleOpenPerceptionModal}
                            tableHeaders={dataTableHeaders}
                            emptyRows={getEmptyRowCount(costOfAction.entries.length, benefitsOfAction.entries.length)}
                        />
                        <CalculusAccordionTable
                            tableTitle="Benefits Of Action"
                            tableName="benefit-of-action"
                            tableRows={benefitsOfAction.entries}
                            onEditRow={handleEditRow}
                            onDeleteRow={handleDeleteRow}
                            onOpenAddPerception={handleOpenPerceptionModal}
                            tableHeaders={dataTableHeaders}
                            emptyRows={getEmptyRowCount(benefitsOfAction.entries.length, costOfAction.entries.length)}
                        />
                    </div>
                    <div className="accordion-row">
                        <CalculusAccordionTable
                            tableTitle="Benefits Of Restraint"
                            tableName="benefit-of-restraint"
                            tableRows={benefitsOfRestraint.entries}
                            onEditRow={handleEditRow}
                            onDeleteRow={handleDeleteRow}
                            onOpenAddPerception={handleOpenPerceptionModal}
                            tableHeaders={dataTableHeaders}
                            emptyRows={getEmptyRowCount(benefitsOfRestraint.entries.length, costsOfRestraint.entries.length)}
                        />
                        <CalculusAccordionTable
                            tableTitle="Cost Of Restraint"
                            tableName="cost-of-restraint"
                            tableRows={costsOfRestraint.entries}
                            onEditRow={handleEditRow}
                            onDeleteRow={handleDeleteRow}
                            onOpenAddPerception={handleOpenPerceptionModal}
                            tableHeaders={dataTableHeaders}
                            emptyRows={getEmptyRowCount(costsOfRestraint.entries.length, benefitsOfRestraint.entries.length)}
                        />
                    </div>
                </section>
                <section className="m-4">
                    <AccordionWithButton
                        align="start"
                        title={
                            <div className="accordion-heading d-flex align-items-center">
                                <h4>Overall Calculus Summary</h4>
                            </div>
                        }
                        open
                        button={
                            <Button
                                className="ms-auto button-with-left-image"
                                size="sm"
                                kind="ghost"
                                renderIcon={Edit}
                                iconDescription="edit pencil"
                                onClick={toggleEditCalculusSummary}
                            >
                                Edit Calculus Summary
                            </Button>
                        }
                    >
                        <p>{typeof overallSummary === 'string' && overallSummary.length > 0 ? overallSummary : 'No Summary'}</p>
                    </AccordionWithButton>
                </section>
            </article>
            {/* MODALS */}
            <CalculusInputs
                open={isEditCalculusOpen || editCalculusSummary}
                calculus={{
                    id: calculusDetails?.calculus_id ?? '',
                    name: calculusDetails?.name ?? '',
                    entity: calculusDetails?.entity?.name ?? '',
                    scenario: calculusDetails?.scenario?.name ?? '',
                    createdBy: '',
                    behavior: calculusDetails?.behavior?.name ?? '',
                    summary: calculusDetails?.summary ?? '',
                    objective: calculusDetails?.objective?.name ?? '',
                }}
                headerText="Edit Calculus"
                submitText="Update Calculus"
                editSummaryOnly={editCalculusSummary}
                onClose={() => {
                    handleCloseEditCalculus();
                    toggleEditCalculusSummary(false);
                }}
                onSubmit={handleUpdateCalculus}
            />
            <EditCalculusElement
                open={!!editRow || !!addPerception}
                calculusName={calculusDetails?.name ?? ''}
                editRow={editRow}
                calculusId={calculusId}
                perceptionId={editRow?.perceptionId ?? ''}
                perceptionType={perceptionType}
                onClose={() => {
                    fetchCalculiPage();
                    handleClosePerceptionModal();
                    handleEditRow();
                }}
            />
            {deleteRow && deleteRow.connectionId ? (
                <Modal
                    open
                    danger
                    modalHeading={`Are you sure you want to delete ${deleteRow.name ? deleteRow.name : 'Perception from Calculus'}?`}
                    modalLabel="Delete Perception from Calculus"
                    primaryButtonText="Delete"
                    secondaryButtonText="Cancel"
                    onRequestClose={() => handleDeleteRow(null)}
                    onRequestSubmit={async () => {
                        const { isOk, error } = await deletePerceptionConnection(deleteRow.connectionId as string);

                        await fetchCalculiPage();
                        handleDeleteRow(null);
                    }}
                />
            ) : null}
            <ScenarioModal
                open={isEditScenarioOpen}
                onClose={handleCloseEditScenario}
                onSubmit={handleUpdateScenario}
                initialScenario={
                    scenarioData ?? {
                        name: '',
                        id: '',
                        updated: '',
                        description: '',
                        objectiveSummary: '',
                        adversaryDecision: '',
                        user: {
                            id: '',
                            name: '',
                        },
                    }
                }
            />
        </div>
    );
};

const CalculusAccordionTable = ({
    tableTitle,
    tableName,
    onEditRow,
    onDeleteRow,
    onOpenAddPerception,
    emptyRows,
    tableRows = [],
    tableHeaders = [],
}: CalculusAccordionTableProps) => {
    return (
        <div>
            <AccordionTable
                className="calculus-accordion-table"
                align="start"
                id={`${tableTitle}_calculus_accordion`}
                title={
                    <div className="accordion-heading d-flex align-items-center">
                        <h4>{`${tableTitle} (${tableRows.length} Perceptions)`}</h4>
                    </div>
                }
                tableHeaders={tableHeaders}
                tableRows={tableRows}
                startExpanded
                isSortable
                emptyRows={emptyRows - 1}
                emptyPlaceholder="No Perceptions"
                onEditRow={(row, rowIndex) => onEditRow({ table: tableName, row, rowIndex })}
                onDeleteRow={(row, rowIndex) => onDeleteRow({ table: tableName, row, rowIndex })}
                button={
                    <Button
                        className="ms-auto button-with-left-image"
                        size="sm"
                        kind="ghost"
                        renderIcon={Add}
                        iconDescription="plus sign"
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => onOpenAddPerception(e, { table: tableName })}
                    >
                        Add Perception
                    </Button>
                }
            ></AccordionTable>
        </div>
    );
};
