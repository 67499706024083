import { ComboBox, Button, Dropdown, Loading, Modal, TextArea, TextInput, Tile } from '@carbon/react';
import { Classification } from '@carbon/icons-react';

import { PERCEPTION_TYPE_LIST } from '../../pages/perception/utils/PerceptionModel';
import { PerceptionTypeListItem } from '../../pages/perception/utils/types';
import { PerceptionModalProps } from './types';
import { usePerception } from './utils/usePerceptionModalState';
import { CommentList } from '../../components/commentList/CommentList';
import { usePerceptionData } from '../../pages/perception/utils/usePerceptionData';
import { useToggle, useUserRole } from '../../hooks';

import { useSourceList } from '../../pages/source/utils/sourceListHooks';
import { SourceModel } from '../../pages/source/utils/sourceModel';
import { SourceModal } from '../sources/SourceModal';
import { shouldFilterItem } from '../../helpers/carbonHelpers';

import './PerceptionModal.scss';

export const PerceptionModal = ({ open, onClose, perception, onSubmit, entity }: PerceptionModalProps) => {
    const {
        callApi,
        comment,
        error,
        handlers: { updateName, updateDesc, updateType, updateComment, updateSource },
        loading,
        nameInvalid,
        descInvalid,
        sourceInvalid,
        previousComments,
        selectedPerception,
        setNameInvalid,
        setDescInvalid,
        setSourceInvalid,
    } = usePerception(perception);
    const [sourceModalOpen, toggleSourceModalOpen] = useToggle(false);

    const isEdit = perception !== undefined;

    const { userRole } = useUserRole();
    const { perceptions } = usePerceptionData();
    const { filteredSources: sources, fetchFilteredSources } = useSourceList({ entity });

    const onSave = async () => {
        const { name, description, type, source } = selectedPerception;
        const perceptionNames = perceptions.map((perception) => perception.name);
        const nameExists = isEdit
            ? perceptionNames.filter((name) => name !== perception.name).includes(selectedPerception.name.trim())
            : perceptionNames.includes(selectedPerception.name.trim());

        const checkInvalid = () => {
            let invalidFound = false;
            if (name.trim() === '' || nameExists) {
                setNameInvalid(true);
                invalidFound = true;
            }
            if (!description || description.length <= 0) {
                setDescInvalid(true);
                invalidFound = true;
            }
            if (!source) {
                setSourceInvalid(true);
                invalidFound = true;
            }
            return invalidFound;
        };

        if (checkInvalid()) {
            return;
        }

        let resp;
        // TODO: update api call with source
        if (selectedPerception.id) {
            resp = await callApi('/perceptions/' + selectedPerception.id, 'PUT', { name, description, type, comment, entity_id: entity?.id });
        } else {
            resp = await callApi('/perceptions', 'POST', { name, description, type, comment, entity_id: entity?.id });
        }

        onSubmit?.(resp);
        if (resp) {
            onClose('new');
        } else {
            console.error(error);
        }
    };

    const showFullModal = userRole === 'admin' || !isEdit;

    if (sourceModalOpen) {
        return (
            <SourceModal
                open
                entityId={entity?.id ?? ''}
                onClose={(source) => {
                    if (source && entity) {
                        fetchFilteredSources(entity.id);
                    }
                    toggleSourceModalOpen();
                }}
            />
        );
    }

    return (
        <Modal
            size="lg"
            className="edit-perception"
            modalHeading={isEdit ? 'Edit Perception' : 'New Perception'}
            primaryButtonText={isEdit ? 'Update Perception' : 'Create Perception'}
            secondaryButtonText="Cancel"
            open={open}
            onRequestClose={onClose}
            onRequestSubmit={onSave}
        >
            {loading ? <Loading /> : null}
            <div className="perception-inputs">
                {showFullModal ? (
                    <div className="inline-inputs">
                        <TextInput
                            className="perception-input"
                            id="perception-input"
                            type="text"
                            invalid={nameInvalid}
                            invalidText={selectedPerception.name === '' ? 'Enter a perception name.' : 'Perception already exists, enter a different name.'}
                            labelText="Perception Name"
                            value={selectedPerception.name}
                            onChange={updateName}
                        />
                        <Dropdown
                            titleText="Perception Type"
                            id="perception-type-dropdown"
                            type="default"
                            label=""
                            className="perception-input perception-type"
                            items={PERCEPTION_TYPE_LIST}
                            itemToString={(item: PerceptionTypeListItem) => (item ? item.text : '')}
                            initialSelectedItem={PERCEPTION_TYPE_LIST.find((t) => t.id === selectedPerception.type)}
                            onChange={updateType}
                        />
                    </div>
                ) : (
                    <Tile id="perception-name-tile" className="d-flex perception-input">
                        <div className="pe-2">
                            <strong>Perception name:&nbsp;</strong>
                            <span>{perception.name ?? ''}</span>
                        </div>
                        <div>
                            <strong>Perception type:&nbsp;</strong>
                            <span>{PERCEPTION_TYPE_LIST.find((t) => t.id === perception.type)?.text ?? ''}</span>
                        </div>
                    </Tile>
                )}
                <TextArea
                    labelText="Perception Description"
                    className="perception-input"
                    value={selectedPerception?.description ?? ''}
                    onChange={updateDesc}
                    invalid={descInvalid}
                    invalidText="Description is required"
                    maxCount={1000}
                    enableCounter
                />
                {showFullModal && (
                    <div className="comments-container">
                        <TextArea
                            labelText="Comment"
                            className="perception-input"
                            maxCount={500}
                            enableCounter
                            value={comment}
                            onChange={updateComment}
                            maxLength="1000"
                        />
                        <div className="prev-comments-container">
                            {previousComments?.length > 0 && <CommentList comments={previousComments} isLoading={loading}></CommentList>}
                        </div>
                    </div>
                )}
                <div className="row perception-input">
                    <div className="col-4">
                        <ComboBox
                            id="source-dropdown"
                            titleText={
                                <div className="d-flex align-items-center">
                                    <p>Source</p>
                                    <Button
                                        kind="ghost"
                                        size="sm"
                                        hasIconOnly
                                        renderIcon={Classification}
                                        iconDescription={'Create Source'}
                                        onClick={toggleSourceModalOpen}
                                    />
                                </div>
                            }
                            shouldFilterItem={shouldFilterItem}
                            label="Source Options"
                            direction="top"
                            items={sources}
                            // TODO: update selected source
                            itemToString={(item: SourceModel) => (item ? item.name : '')}
                            onChange={updateSource}
                            invalidText="Source is required"
                            invalid={sourceInvalid}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};
