import { parseISO, formatISO9075 } from 'date-fns';

export const mapEntities = (entities: any[]) => entities.map((e) => new EntityModel(e));

export class EntityModel {
    id = '';
    entity_id = '';
    name = '';
    createdBy = '';
    created = '';
    user_id = '';
    user = '';
    updated = '';
    used = 0;
    constructor(data: Partial<EntityModel>) {
        Object.assign(this, data);
        this.id = data.entity_id || data.id || '';
        this.createdBy = data.user || '';
        this.updated = data?.updated ? formatISO9075(parseISO(data?.updated || '')) : '';
    }
}
