import React from 'react';
import { Loading } from '@carbon/react';

import { SelectionDialog } from './SelectionDialog';
import { Mode } from './types';
import { useDecisionCalculus } from '../../pages/calculus/DecisionCalculus/utils/useDecicisionCalculusHooks';
import { CalculusData } from '../calculusInputs/types';

interface SelectCalcScenarioWrapperProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (param: CalculusData | null) => void;
}

export const SelectCalcScenarioWrapper = ({ open, onClose, onSubmit }: SelectCalcScenarioWrapperProps) => {
    const { loading: isLoading, calculi } = useDecisionCalculus();

    const handleSubmit = (selectedId: string) => {
        const selectedCalculi = calculi.find((data) => data.id === selectedId) ?? null;
        onSubmit(selectedCalculi);
    };

    if (isLoading) {
        return <Loading />;
    }

    return <SelectionDialog open={open} onClose={onClose} mode={Mode.CalcScenario} data={calculi} onSubmit={handleSubmit} />;
};
