import axios from 'axios';
import { useCallback, useState } from 'react';
import { getLoggedIn } from '../helpers/loggedIn';
import { useNavigate } from 'react-router-dom';

export const useCRUDApi = (baseUrl: string) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    const callApi = useCallback(
        async (url: string, method: string, data: any = {}) => {
            try {
                setLoading(true);
                const respData = await axios({
                    method,
                    url: baseUrl + url,
                    data,
                });

                setLoading(false);
                return respData.data;
            } catch (error: any) {
                setError(error.message);
                setLoading(false);
                const authorized = await getLoggedIn();
                if (authorized === false) {
                    navigate('/account-services');
                }
            }
        },
        [baseUrl, navigate]
    );

    return { error, loading, callApi };
};
