import React, { useEffect, useState } from 'react';
import { ButtonUtil } from '../ButtonUtil';
import { TileGridLayout } from './TileGridLayout';

export const ErrorPageLayout = ({type}) => {
    const [pageStrings, setPageStrings] = useState({title:"", message:""});

    useEffect(() => {
        switch(type){
            case "404":
                setPageStrings({
                    title:"Page Not Found",
                    message:"The page you are looking for might have been removed, renamed or does not exist in the first place."
                });
                break;
            case "403":
                setPageStrings({
                    title:"Access Forbidden",
                    message:"You do not have permission to access this page."
                });
                break;
            case "500":
                setPageStrings({
                    title:"Server Error",
                    message:"The server was unable to complete your request."
                });
                break;
        }
    },[])

    return <TileGridLayout 
        grid={{
            style:{height:'90vh'},
        }}
        column={{
            lg:{ span: 12, offset: 2 },
            md:{ span: 6, offset: 1 },
            sm:4,
            className:'h-100 d-flex align-items-center',
        }}
        tile={{
            className:'rounded w-100 text-center px-5 pb-5',
            style:{
                height:'50vh',
                // backgroundColor:''
            },
            children:<>
                <div className='d-flex justify-content-center border-bottom border-2'>
                    <div style={{maxWidth:'4em'}}>
                        <img
                            className='h-auto w-100'
                            src="/assets/Logos/guardian_logo.png"
                            alt='Logo'
                        />
                    </div>
                </div>
                <h1 className='fw-bold fs-1 mt-4'>{pageStrings.title}</h1>
                <p className='mt-4'>{pageStrings.message}</p>
                <ButtonUtil 
                    button= {{
                        children: 'Return to Home',
                        className:'mt-5 rounded',
                        href:'/'
                    }} 
                />
            </>
        }}
    />
};