import { CalculusDecisionDataTables, CalculusDetailsApiData, PerceptionConnection } from './types';

const mapDataTableEntry = (data: CalculusDetailsApiData['perceptions'][string] | undefined) => {
    const perceptionConnections: PerceptionConnection = {};
    const entries: CalculusDecisionDataTables['costOfAction']['entries'] = [];

    data?.records?.forEach((entry) => {
        perceptionConnections[entry.id] = entry.connection_id;
        entries.push({
            ...entry,
            id: entry.id,
            perceptionName: entry.name,
            value: entry.value,
            probability: entry.probability,
            weight: entry.weight,
            preparedBy: entry.created.createdBy,
        });
    });

    return {
        ranking: data?.totalRanking ?? 0,
        entries,
        perceptionConnections,
    };
};

export const mapPerceptionData = (data: CalculusDetailsApiData['perceptions']) => {
    const costOfAction = mapDataTableEntry(data['cost-of-action']);
    const benefitsOfAction = mapDataTableEntry(data['benefit-of-action']);
    const benefitsOfRestraint = mapDataTableEntry(data['benefit-of-restraint']);
    const costsOfRestraint = mapDataTableEntry(data['cost-of-restraint']);
    const result = {
        costOfAction: {
            entries: costOfAction.entries,
            ranking: costOfAction.ranking,
        },
        benefitsOfAction: {
            entries: benefitsOfAction.entries,
            ranking: benefitsOfAction.ranking,
        },
        benefitsOfRestraint: {
            entries: benefitsOfRestraint.entries,
            ranking: benefitsOfRestraint.ranking,
        },
        costsOfRestraint: {
            entries: costsOfRestraint.entries,
            ranking: costsOfRestraint.ranking,
        },
        perceptionConnections: {
            ...costOfAction.perceptionConnections,
            ...benefitsOfAction.perceptionConnections,
            ...benefitsOfRestraint.perceptionConnections,
            ...costsOfRestraint.perceptionConnections,
        },
    };

    return result;
};
