import { useMemo, useReducer, useState } from 'react';
import { useCRUDApi } from '../../../hooks/useCRUDApi';
import { ReportModel, TReportModel } from '../../../pages/reports/utils/ReportModel';
import { ReportTypeListItem } from '../../../pages/reports/utils/types';
import { CalculusData } from '../../calculusInputs/types';

export function useReport(report: ReportModel = new ReportModel({})) {
    const { callApi, error, loading } = useCRUDApi('/api/v1');
    const [titleInvalid, setTitleInvalid] = useState(false);
    const [descInvalid, setDescInvalid] = useState<boolean>(false);
    const [selectedReport, setSelectedReport] = useReducer((state: TReportModel, action: Partial<ReportModel>) => ({ ...state, ...action }), report);

    const handlers = useMemo(
        () => ({
            updateTitle: (e: React.FormEvent<HTMLInputElement>) => {
                setTitleInvalid(false);
                setSelectedReport({
                    name: e.currentTarget.value,
                });
            },
            updateType: (e: { selectedItem: ReportTypeListItem }) => {
                setSelectedReport({
                    type: e.selectedItem.id,
                });
            },
            updateSummary: (e: React.FormEvent<HTMLInputElement>) => {
                setSelectedReport({
                    description: e.currentTarget.value,
                });
            },
        }),
        []
    );

    return {
        callApi,
        error,
        handlers,
        loading,
        titleInvalid,
        descInvalid,
        selectedReport,
        setTitleInvalid,
        setDescInvalid,
    } as const;
}

export function useCalculi(initialCalculi: any) {
    const [calculi, setCalculi] = useReducer((state: any, action: any) => [...state, ...action], [...initialCalculi]);

    const handlers = useMemo(
        () => ({
            addCalculus: (calculus: CalculusData) => {
                setCalculi([calculus]);
            },
        }),
        []
    );

    return [calculi, handlers] as const;
}
