import { parseISO, formatISO9075 } from 'date-fns';

export class CalculusModel {
    id = '';
    name = '';
    summary = '';
    objective = '';
    behavior = '';
    scenario = '';
    entity = '';
    updated = '';
    updatedBy = '';
    created = '';
    createdBy = '';
    constructor(data: Partial<CalculusModel>) {
        Object.assign(this, data);
        this.updated = data?.updated ? formatISO9075(parseISO(data?.updated || '')) : '';
    }
}

export const mapCalculi = (calculi: any[]) => calculi.map((e) => new CalculusModel(e));
