import { Modal } from '@carbon/react';
import { SelectionDialogProps, Mode } from './types';
import { SelectionTable } from '../../components/selectionTable/SelectionTable';
import { useSelect } from './selectionDialogHooks';

export const SelectionDialog = ({ open, onClose, mode, data, onSubmit = null }: SelectionDialogProps) => {
    const [selected, { updateSelected }] = useSelect();

    let headers;
    let heading;
    let primaryButtonText;
    switch (mode) {
        case Mode.Perception:
            heading = 'Select Perception';
            headers = [
                { key: 'name', header: 'Perception Name' },
                { key: 'entityName', header: 'Entity' },
                { key: 'lastModified', header: 'Last Modified' },
                { key: 'createdBy', header: 'Prepared By' },
            ];
            primaryButtonText = 'Select';
            break;
        case Mode.CalcScenario:
            heading = 'Prefill Calculus Scenario';
            headers = [
                { key: 'name', header: 'Calculus Name' },
                { key: 'scenario', header: 'Scenario' },
                { key: 'entity', header: 'Entity' },
            ];
            primaryButtonText = 'Fill Scenario';
            break;
        case Mode.Calc:
            heading = 'Prefill Calculus';
            headers = [
                { key: 'name', header: 'Calculus Name' },
                { key: 'scenario', header: 'Scenario' },
                { key: 'entity', header: 'Entity' },
            ];
            primaryButtonText = 'Fill Calculus';
            break;
    }

    return (
        <div className="selection-dialog">
            <Modal
                open={open}
                onRequestClose={() => onClose(false)}
                modalHeading={heading}
                primaryButtonText={primaryButtonText}
                secondaryButtonText="Cancel"
                onRequestSubmit={() => {
                    if (onSubmit) {
                        onSubmit(selected);
                    }
                }}
            >
                <SelectionTable headers={headers} data={data} selected={selected} onSelectedChange={(newSelected: string) => updateSelected(newSelected)} />
            </Modal>
        </div>
    );
};
