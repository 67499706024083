import { useMemo, useState } from 'react';

export const useDecisionCalculusState = () => {
    const [compareModalOpen, setCompareModalOpen] = useState(false);
    const [newCalculusInputsOpen, setNewCalculusInputsOpen] = useState(false);
    const [editCalculusInputsOpen, setEditCalculusInputsOpen] = useState(false);
    const [compareCalculiOpen, setCompareCalculiOpen] = useState(false);
    const [calculusToEdit, setCalculusToEdit] = useState('');
    const [calculiSelections, setCalculiSelections] = useState<string[]>([]);
    const [rowsToDelete, setRowsToDelete] = useState<{ id: string }[]>([]);

    const handlers = useMemo(
        () => ({
            handleSelectRowsToDelete: (selectedRows: { id: string }[]) => {
                setRowsToDelete(selectedRows);
            },
            handleCancelDeleteRows: () => setRowsToDelete([]),
        }),
        []
    );

    return {
        compareModalOpen,
        calculiSelections,
        newCalculusInputsOpen,
        editCalculusInputsOpen,
        compareCalculiOpen,
        calculusToEdit,
        rowsToDelete,
        setCompareModalOpen,
        setCalculiSelections,
        setNewCalculusInputsOpen,
        setEditCalculusInputsOpen,
        setCompareCalculiOpen,
        setCalculusToEdit,
        ...handlers,
    };
};
