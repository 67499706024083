import { ListItem, UnorderedList } from '@carbon/react';
import React from 'react';

export const TermsOfService = () => (
    <div className="mx-5 mt-5">
        <h1 className='mb-3'>Rhombus Power Terms of Service and Privacy Policy</h1>
        <p>When using any services from this website:</p>
        <UnorderedList className='my-3 ms-5'>
            <ListItem>Users are accessing a U.S. Government information system.</ListItem>
            <ListItem>Information system usage may be monitored, recorded, and subject to audit.</ListItem>
            <ListItem>Unauthorized use of the information system is prohibited and subject to criminal and civil penalties.</ListItem>
            <ListItem>Use of the information system indicates consent to monitoring and recording.</ListItem>
        </UnorderedList>
        <p>By logging in, you acknowledge and agree to all of Rhombus Power's terms and conditions.</p>
    </div>
)
