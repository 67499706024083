import React, { useEffect, useState } from 'react';
import { LoginForm, RegistrationForm } from '../';
import { Login, Account } from '@carbon/react/icons';
import { TabsLayout } from '../../utils';
import './Styles/AccountServices.scss';
import { Form } from '@carbon/react';
import { Navigate } from 'react-router-dom';
import { loggedIn } from '../../helpers/jwt';

export const AccountServices = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(null);

    useEffect(() => {
        (async () => {
            setIsLoggedIn(await loggedIn());
        })();
    }, []);

    if (isLoggedIn === null) {
        return <h1>Loading</h1>;
    } else if (isLoggedIn === false) {
        return (
            <TabsLayout
                titles={[
                    <>
                        {' '}
                        <Login /> Login{' '}
                    </>,
                    <>
                        {' '}
                        <Account /> Registration{' '}
                    </>,
                ]}
                descriptions={[<Form children={<LoginForm />} />, <Form children={<RegistrationForm />} />]}
            />
        );
    } else {
        return <Navigate to="/" />;
    }
};
