import { useCallback, useEffect, useMemo, useState } from 'react';
import { atom, useAtom } from 'jotai';

import { useCRUDApi } from '../../../hooks/useCRUDApi';
import { Scenario, ScenarioDetails, ScenarioInputData } from './types';

export const scenarioDataAtom = atom<Scenario[]>([]);

export const useScenarios = () => {
    const { error, loading, callApi } = useCRUDApi('/api/v1');
    const [scenarios, setScenarios] = useAtom(scenarioDataAtom);
    const [scenarioDetails, setScenarioDetails] = useState<ScenarioDetails | undefined>();

    const fetchScenarios = useCallback(async () => {
        const data = await callApi('/scenarios', 'get');
        const scenarioRows = data.rows;
        setScenarios(scenarioRows);
        return scenarioRows;
    }, [callApi, setScenarios]);

    useEffect(() => {
        if (scenarios.length === 0) {
            fetchScenarios();
        }
    }, [fetchScenarios, scenarios.length]);

    const scenariosApiRequests = useMemo(
        () => ({
            fetchScenarioDetails: async (id: string) => {
                const data = await callApi(`/scenarios/${id}`, 'get');
                setScenarioDetails(data);
                return data;
            },
            createNewScenario: async (input: ScenarioInputData) => {
                const { name, description, objectiveSummary, adversaryDecision } = input;

                try {
                    const res = await callApi('/scenarios', 'post', {
                        name,
                        description: description ?? '',
                        objectiveSummary: objectiveSummary ?? '',
                        adversaryDecision: adversaryDecision ?? '',
                    });
                    if (res && res.scenario_id) {
                        fetchScenarios();
                        return {
                            isOk: true,
                            scenarioId: res.scenario_id as string,
                            error: null,
                        } as const;
                    }
                    throw new Error('Could not create scenario');
                } catch (error) {
                    return {
                        isOk: false,
                        scenarioId: null,
                        error,
                    } as const;
                }
            },
            updateScenario: async (id: string, input: ScenarioInputData) => {
                const { name, description, objectiveSummary, adversaryDecision } = input;

                try {
                    const res = await callApi(`/scenarios/${id}`, 'put', {
                        name,
                        description,
                        objectiveSummary,
                        adversaryDecision,
                    });
                    if (res) {
                        fetchScenarios();
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            deleteScenario: async (id: string) => {
                try {
                    const res = await callApi(`/scenarios/${id}`, 'delete');
                    if (res) {
                        fetchScenarios();
                    }
                } catch (error) {
                    console.error(error);
                }
            },
        }),
        [callApi, fetchScenarios]
    );

    return {
        scenarios,
        scenarioDetails,
        error,
        loading,
        fetchScenarios,
        ...scenariosApiRequests,
    };
};
