import React, { useEffect } from 'react';
import { Button } from '@carbon/react';
import { paths } from '../config';

export const ButtonUtil = ({ pathKey = 'empty', redirect, button }) => {
    const buttonProps = {
        children: 'Button',
        href: redirect ? paths[pathKey]['path'] : null,
        onClick: () => {},
        ...button,
    };

    return <Button {...buttonProps} />;
};
