import { Theme } from '@carbon/react';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Navigation from './navigation/Navigation';
import { CARBON_THEME } from './ThemeButton';

export const Layout = () => {
    const [carbonTheme, setCarbonTheme] = useState(localStorage.getItem('theme') || CARBON_THEME.G100);

    return (
        <Theme theme={carbonTheme} className="theme-wrapper">
            <Navigation changeTheme={setCarbonTheme} carbonTheme={carbonTheme}></Navigation>

            <div className="content-container">
                <Outlet />
            </div>
        </Theme>
    );
};
