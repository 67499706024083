import { useReducer } from 'react';

/**
 * if action is a boolean set state to that value instead of flipping the state
 */
const reducer = (state: boolean, action?: boolean | any): boolean => (typeof action === 'boolean' ? action : !state);

/**
 * Toggle hook where calling `setToggle` will switch states
 * Passing in a boolean will force toggle state to a specific value
 */
export const useToggle = (initialState?: boolean): [boolean, (newState?: boolean | any) => void] => {
    return useReducer(reducer, initialState ?? false);
};
