import { ComboBox } from '@carbon/react';
import './QuickFilter.scss';

export interface QuickFilterItem {
    id: string;
    text: string;
}

interface QuickFilterProps {
    onChange: (key: string, item: QuickFilterItem) => void;
    label: string;
    items: QuickFilterItem[];
    selectedItem?: string;
    filterKey: string;
}

export const QuickFilter = ({ onChange, items, selectedItem, label, filterKey }: QuickFilterProps) => {
    return (
        <div className="quick-filter">
            <label>{label}</label>
            <ComboBox
                size="md"
                id="filter-1"
                initialSelectedItem={selectedItem || ''}
                onChange={(e: { selectedItem: QuickFilterItem }) => onChange(filterKey, e.selectedItem)}
                items={items}
                itemToString={(item: QuickFilterItem) => (item ? item.text : '')}
                placeholder="Select..."
            />
            <span className="divider"></span>
        </div>
    );
};
