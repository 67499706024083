import React from 'react'; 
import {Checkbox} from '@carbon/react';

export const CheckboxElement = ({checkbox, setCheckedBox}) => {
    const onChange = (value, id, event) => {
        if (id.checked) { setCheckedBox(true) }
        else { setCheckedBox(false) }
    }
    const checkboxProps = { 
        id: `checkbox${Math.random().toString()}`, 
        labelText: 'Checkbox label', 
        onChange: onChange, 
        ...checkbox, 
    }
    return (
        <Checkbox {...checkboxProps}/> 
    )
}