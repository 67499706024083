import { useLocation } from 'react-router-dom';

export const useCurrentPath = () => {
    const currentPath = useLocation().pathname;
    let currentPage = 'home';
    let currentDataKey = '';
    let listItem = '';
    switch (currentPath) {
        case '/':
            currentPage = 'home';
            break;
        case '/calculi':
            currentPage = 'Decision Calculus';
            currentDataKey = 'calculus';
            listItem = 'Calculus';
            break;
        case '/perceptions':
            currentPage = 'Perceptions';
            currentDataKey = 'perceptions';
            listItem = 'Perception';
            break;
        case '/sources':
            currentPage = 'Sources';
            currentDataKey = 'sources';
            listItem = 'Source';
            break;
        case '/reports':
            currentPage = 'Reports';
            currentDataKey = 'reports';
            listItem = 'Report';
            break;
    }
    return {
        currentPage,
        currentDataKey,
        listItem,
    };
};
